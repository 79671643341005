import {Component, Input} from '@angular/core';
import {ActionSheetButton} from '@ionic/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-pop-over-menu',
  templateUrl: './pop-over-menu.component.html',
  styleUrls: ['./pop-over-menu.component.scss']
})
export class PopOverMenuComponent {
  @Input() actions: ActionSheetButton[];

  constructor(private ionicPopOverController: PopoverController) {}

  UIonBtnPressed(action: ActionSheetButton) {
    // action.handler();
    this.ionicPopOverController.dismiss(action);
  }
}
