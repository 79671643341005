import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResigningCpdPage} from './resigning-cpd.page';
import {ResigningCpdRoutingModule} from './resigning-cpd.routing';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CpdServiceModule} from '@buscoextra/services/cpd/cpd.module';
import {BeLoadingComponentModule} from '@components';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    CpdServiceModule,
    ResigningCpdRoutingModule,
    FormsModule,
    BeLoadingComponentModule,
    BeInputValueAccessorModule
  ],
  exports: [ResigningCpdPage],
  declarations: [ResigningCpdPage]
})
export class ResigningCpdPageModule {}
