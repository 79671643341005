import {NgModule} from '@angular/core';
import {ParamService} from './param.service';
import {ApiServiceModule} from '@buscoextra/core/services/api';

@NgModule({
  imports: [ApiServiceModule],
  exports: [],
  declarations: [],
  providers: [ParamService]
})
export class ParamServiceModule {}
