import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimingCardComponent} from './timing-card.component';
import {IonicModule} from '@ionic/angular';
import {BeScheduleCardComponentModule} from '../be-schedule-card/be-schedule-card-component.module';

@NgModule({
  imports: [CommonModule, IonicModule, BeScheduleCardComponentModule],
  exports: [TimingCardComponent],
  declarations: [TimingCardComponent]
})
export class TimingCardComponentModule {}
