export function exists(something: any): boolean {
  return something !== null && something !== undefined && something !== '' && something !== {} && something !== [];
}

export function haveUppercaseChar(text: string): boolean {
  return text.split('').some(char => {
    if (/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(char) || !isNaN(parseInt(char))) {
      return false;
    } else {
      return char === char.toUpperCase();
    }
  });
}

export function haveLowercaseChar(text: string): boolean {
  return text.split('').some(char => {
    if (/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(char) || !isNaN(parseInt(char))) {
      return false;
    } else {
      return char === char.toLocaleLowerCase();
    }
  });
}

export function haveANumber(text: string): boolean {
  return text.split('').some(char => {
    return !isNaN(char as any);
  });
}

export function checkIfRequirementFail(failedRequirements: any[], id: string): boolean {
  return failedRequirements.some(req => {
    return req.id === id;
  });
}
