import {Component, Input} from '@angular/core';
import {getExperienciaFromTimeStamp, WorkResume} from '@buscoextra/core';

@Component({
  selector: 'work-resume',
  templateUrl: './work-resume.component.html',
  styleUrls: ['./work-resume.component.scss']
})
export class WorkResumeComponent {
  @Input() workResume: WorkResume;

  constructor() {
    //WORK_RESUME.exampleValue
  }
  ngOnInit() {

  }

  formatedValue(value) {
    return getExperienciaFromTimeStamp(value);
  }
}
