import {Component} from '@angular/core';
import {InputType} from '~/services/dialog/models/alert';
import {ModalController, Platform} from '@ionic/angular';
import {Report, UserService} from '@services';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, StateService} from '~/services';

@Component({
  selector: 'app-rating-page',
  templateUrl: './app-rating.page.html',
  styleUrls: ['./app-rating.page.scss']
})
export class AppRatingPage extends AsyncPage {
  constructor(
    communications: CommunicationsService,
    private state: StateService,
    private ionicModal: ModalController,
    private platform: Platform,
    private userService: UserService,
    private dialog: DialogService
  ) {
    super(communications);
  }

  async UIonDislikeBtnPressed() {
    const dialog = await this.dialog.form({
      title: '¡Te queremos escuchar!',
      message: 'Cuéntanos que cambiarías para mejorar la App.',
      inputs: [
        {
          name: 'msg',
          placeholder: 'Mensaje',
          type: InputType.text
        }
      ],
      buttonText: 'Enviar',
      buttonCancelText: 'Cancelar'
    });
    if (!dialog) return;
    const newReport: Report = {
      message: dialog['msg'],
      phone: this.state.user.phoneNumber,
      email: this.state.user.email,
      userId: this.state.userId
    };
    if ((await this.send(this.userService.sendReport(newReport), 'sendinReport')) !== null) {
      this.dialog.toast('Enviando reporte');
      this.ionicModal.dismiss();
    }
  }

  get isModal(): boolean {
    return this.ionicModal['injector']['_def']['isRoot'];
  }

  async UIonCloseModalBtnPressed() {
    this.ionicModal.dismiss();
  }

  UIonLikeBtnPressed() {
    if (this.platform.is('ios')) {
      window.open('https://itunes.apple.com/es/app/buscoextra/id1262413810');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.buscoextra.app');
    }
    this.ionicModal.dismiss();
  }
}
