import {Injectable} from '@angular/core';
import {NavigationService} from '..';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router} from '@angular/router';
import {Observable, of, from} from 'rxjs';
import {AppService, AppStatus} from '../../../app.service';
import {map} from 'rxjs/operators';

@Injectable()
export class SystemStatus implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private navigationService: NavigationService, private app: AppService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(this.app.getAppStatus()).pipe(
      map((result: AppStatus) => {
        switch (result) {
          case AppStatus.Maintenance:
            return this.router.parseUrl('maintenance');
          case AppStatus.Outdated:
            return this.router.parseUrl('update');
          case AppStatus.Stable:
            return true;
          default:
            return true;
        }
      })
    );
  }
}
