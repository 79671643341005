import { Injectable, NgZone } from '@angular/core';
// import {Push, PushObject, PushOptions} from '@ionic-native/push/ngx';
import { Platform } from '@ionic/angular';
import { StateService } from '~/services';
import { NavigationService } from '../navigation/navigation.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  // options: PushOptions = {
  //   android: {
  //     forceShow: 'true',
  //     icon: 'ic_push_logo'
  //   },
  //   ios: {
  //     alert: 'true',
  //     badge: true,
  //     sound: 'true'
  //   },
  //   windows: {},
  //   browser: {
  //     pushServiceURL: 'http://push.api.phonegap.com/v1/push'
  //   }
  // };

  constructor(
    // private push: Push,
    private firebaseX: FirebaseX,
    private platform: Platform,
    private stateService: StateService,
    private navigationService: NavigationService,
    private angularFireMessaging: AngularFireMessaging,
    private ngZone: NgZone
  ) { }

  setup() {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        this.prepareNativePush();
      });
    } else {
      if (navigator.userAgent.toLowerCase().indexOf('chrome') == -1) return;
      this.angularFireMessaging.requestToken.subscribe(
        token => {
          this.stateService.newSession({
            ...this.stateService.session,
            ...{ firebaseToken: token }
          });
        },
        err => {
          console.error('Unable to get permission to notify.', err);
        }
      );

      this.angularFireMessaging.messages.subscribe(noti => {
        this.onPushNotificationRecived(noti, this.navigationService);
      });
    }
  }

  // Following code for push notification is deprecated
  // prepareNativePush() {
  //   const pushObject: PushObject = this.push.init(this.options);
  //   let that = this;
  //   this.push.hasPermission().then((res: any) => {
  //     if (res.isEnabled) {
  //       console.log('We have permission to send push notifications');
  //     } else {
  //       console.log('We do not have permission to send push notifications');
  //     }
  //   });
  //   pushObject.on('registration').subscribe((registration: any) => {
  //     this.stateService.newSession({
  //       ...this.stateService.session,
  //       ...{ firebaseToken: registration.registrationId }
  //     });
  //   });

  //   pushObject.on('notification').subscribe(noti => {
  //     this.onPushNotificationRecived(noti, that.navigationService);
  //   });
  //   pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  // }

  // Latest code for push notifications with FirebaseX
  prepareNativePush() {
    this.firebaseX.grantPermission().then((hasPermission) => {
      console.log('Permission was ' + (hasPermission ? 'granted' : 'denied'));
    });

    this.firebaseX.hasPermission().then((hasPermission) => {
      console.log('Permission is ' + (hasPermission ? 'granted' : 'denied'));
    });

    this.firebaseX.getToken().then((token) => {
      this.stateService.newSession({
        ...this.stateService.session,
        ...{ firebaseToken: token }
      });
    }, (error) => {
      console.error(error);
    });

    this.firebaseX.onTokenRefresh().subscribe((token) => {
      this.stateService.newSession({
        ...this.stateService.session,
        ...{ firebaseToken: token }
      });
    });

    this.firebaseX.onMessageReceived().subscribe((notification) => {
      console.log('Notification received', notification);
      this.onPushNotificationRecived(notification, this.navigationService);
    });
  }

  private onPushNotificationRecived(notification, navservice) {
    console.log('Received a notification', JSON.stringify(notification));
    if (!notification.additionalData || !notification.additionalData.targetView) return;
    this.ngZone.run(() => {
      navservice.goto(notification.additionalData.targetView);
    });
  }
}
