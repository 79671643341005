import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cpd, Establishment, Extra} from '@buscoextra/core';
import {GeolocationService} from '~/services/geolocation/geolocation.service';
import {calcDistance} from '@buscoextra/core';
import {StateService} from '~/services';

@Component({
  selector: 'be-cpd-card',
  templateUrl: './be-cpd-card.component.html',
  styleUrls: ['./be-cpd-card.component.css']
})
export class BeCpdCardComponent implements OnInit {
  @Input() src: Cpd;

  @Input() primaryBtn: string;
  @Input() secondBtn: string;
  @Input() extended = false;

  @Output('onSecondBtn') secondEmitter: EventEmitter<Cpd> = new EventEmitter();
  @Output('onPrimaryBtn') primaryEmitter: EventEmitter<Cpd> = new EventEmitter();
  @Output('onViewDetail') viewDetail: EventEmitter<Cpd> = new EventEmitter();
  constructor(private geoLocation: GeolocationService, private state: StateService) {}

  ngOnInit() {}

  getEstablishmentImg() {
    if (this.src.establishment.images && this.src.establishment.images.length > 0) {
      return this.src.establishment.images[0].url;
    } else {
      return 'assets/site_img.png';
    }
  }

  get establishmentLocationImg() {
    return this.geoLocation.getStaticMapImg(this.src.establishment.location);
  }
  get userType() {
    return this.state.userType;
  }

  UIonSecondaryBtnPressed(e, cpd: Cpd) {
    e.stopPropagation();
    this.secondEmitter.emit(cpd);
  }

  UIonPrimaryBtnPressed(e, cpd: Cpd) {
    e.stopPropagation();
    this.primaryEmitter.emit(cpd);
  }
  getEstablishment() {
    return this.src.establishment as Establishment;
  }

  UIOnCardPressed(cpd) {
    this.viewDetail.emit(cpd);
  }

  get distance() {
    return calcDistance(
      {
        lat: (this.state.user as Extra).latitude,
        long: (this.state.user as Extra).longitude
      },
      {
        lat: this.src.establishment.location.latitude,
        long: this.src.establishment.location.longitude
      }
    );
  }
}
