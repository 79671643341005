import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarPickerPage} from './calendar-picker.page';
import {CalendarPickerRoutingModule} from './calendar-picker.routing';
import {IonicModule} from '@ionic/angular';
import {CalendarPickerComponentModule} from '~/components/calendar-picker/calendar-picker-component.module';

@NgModule({
  imports: [CommonModule, IonicModule, CalendarPickerRoutingModule, CalendarPickerComponentModule],
  declarations: [CalendarPickerPage]
})
export class CalendarPickerPageModule {}
