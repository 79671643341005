import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeExtraCardComponent } from './be-extra-card.component';
import { IonicModule } from '@ionic/angular';
import { WorkResumeComponentModule } from '@buscoextra/components/other/work-resume/work-resume-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, WorkResumeComponentModule],
  exports: [BeExtraCardComponent],
  declarations: [BeExtraCardComponent]
})
export class BeExtraCardComponentModule { }
