import {Component, Input, OnInit} from '@angular/core';
import {Notification} from './models/notification';

@Component({
  selector: 'be-notification-card',
  templateUrl: './be-notification-card.component.html',
  styleUrls: ['./be-notification-card.component.scss']
})
export class BeNotificationCardComponent implements OnInit {
  @Input() notification: Notification = {} as Notification;

  constructor() {}

  ngOnInit() {}
}
