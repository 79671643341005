import {Requirement, Contact} from '@buscoextra/core';
import {Establishment} from '@buscoextra/core';
import {exists} from '../tools/operations';

export const establishmentCreateOfferRequirements: Requirement[] = [
  {
    id: 'name',
    title: 'No has indicado un nombre para el establecimiento',
    rule: (establishment: Establishment): boolean => {
      return exists(establishment.name);
    }
  },
  {
    id: 'location',
    title: 'No has indicado una localización para el establecimiento',
    rule: (establishment: Establishment): boolean => {
      return !!establishment.location && !!establishment.location.formattedAddress;
    }
  },
  {
    id: 'establishmentType',
    title: 'No has indicado un tipo de establecimiento',
    rule: (establishment: Establishment): boolean => {
      return exists(establishment.establishmentType);
    }
  },
  {
    id: 'agreement',
    title: 'No has indicado un convenio para el establecimiento',
    rule: (establishment: Establishment): boolean => {
      return !!establishment.agreement;
    }
  }
];
