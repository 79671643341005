import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../navigation.service';
import {DialogService} from '~/services/dialog/dialog.service';
import {StateService} from '~/services';
import {AuthService} from '@buscoextra/core/services/auth/auth.service';

@Injectable()
export class IsAuthorized implements CanActivate {
  constructor(
    private state: StateService,
    private navigationService: NavigationService,
    private auth: AuthService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isUserLogged()) {
      if (this.state.isABusinessSession() && state.url.indexOf('/business') === -1 && state.url.indexOf('/establishment-register') === -1) {
        return this.router.parseUrl('business');
      } else if (this.state.isAnExtraSession() && state.url.indexOf('/extras') === -1) {
        return this.router.parseUrl('extras');
      } else {
        return true;
      }
    }
    this.dialogService.toast('Tu sesión ha caducado');
    this.state.finishSession();
    return this.router.parseUrl('');
  }
}
