import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'be-header',
  templateUrl: './ion-form-header.component.html',
  styleUrls: ['./ion-form-header.component.scss']
})
export class BeHeaderComponent implements OnInit {
  @Input() isFormEdited = false;
  @Input() title: string;
  @Input() titleEditable: string;
  @Input() isUpdating: boolean;
  @Input() isModal: boolean = false;
  @Input() disableActionButton: boolean = false;
  @Input() actionButtonTitle = 'Guardar';
  @Input() contactUsEnabled = false;
  @Input() disableBack = false;

  @Output('onAction') actionEmitter: EventEmitter<any> = new EventEmitter();
  @Output('onDismiss') dismissEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  UIACtionButtonClicked() {
    this.actionEmitter.emit();
  }
  UIonDimiss() {
    this.dismissEmitter.emit();
  }
}
