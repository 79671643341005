import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocationPickerComponent} from './location-picker.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {BeLoadingComponentModule} from '@components';
import {GeolocationServiceModule} from '~/services/geolocation/geolocation-service.module';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  imports: [CommonModule, FormsModule, BeLoadingComponentModule, IonicModule, GeolocationServiceModule, BeInputValueAccessorModule],
  exports: [LocationPickerComponent],
  declarations: [LocationPickerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LocationPickerComponentModule {}
