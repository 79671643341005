import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '~environment';
import {BeLocation} from '@buscoextra/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsApiService {
  private apiEndPoint = 'https://maps.googleapis.com/maps/api/geocode/json?language=es&region=ES';

  constructor(private httpClient: HttpClient) {}

  getStaticMapImage(location: BeLocation): string {
    if (!location) return;
    let url = undefined;
    if (location.latitude && location.longitude) {
      url =
        'https://maps.googleapis.com/maps/api/staticmap?center=' +
        location.latitude +
        ',' +
        location.longitude +
        '&zoom=16&size=600x300&maptype=roadmap&markers=color:red%7Clabel:%7C' +
        location.latitude +
        ',' +
        location.longitude +
        `&key=${environment.gMapsApiKey}`;
    }
    return url;
  }

  getLocationFromLatLng(lat: number, lng: number): Observable<BeLocation> {
    return this.httpClient
      .get(`${this.apiEndPoint}&latlng=${lat},${lng}&result_type=political|locality&key=${environment.gMapsApiKey}`)
      .pipe(
        map((location: any) => {
          if (location.status == 'ZERO_RESULTS') {
            return undefined;
          } else {
            return this.getLocationFromgMapLocation(location.results[0]);
          }
        })
      );
  }

  public getLocationFromgMapLocation(place: any) {
    let resultLocation: BeLocation = {} as BeLocation;

    if (place.address_components) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (place.geometry.location.lat instanceof Function) {
          resultLocation.latitude = place.geometry.location.lat();
          resultLocation.longitude = place.geometry.location.lng();
        } else {
          resultLocation.latitude = place.geometry.location.lat;
          resultLocation.longitude = place.geometry.location.lng;
        }

        if (GoogleApiAddressForm[addressType]) {
          const val = place.address_components[i][GoogleApiAddressForm[addressType]];
          switch (addressType) {
            case 'route':
              resultLocation.addressLine1 = val || null;
              break;
            case 'locality':
              resultLocation.city = val || null;
              if (val == 'Ceuta' || val == 'Melilla') {
                resultLocation.province = val;
              }
              break;
            case 'administrative_area_level_2':
              resultLocation.province = val || null;
              break;
            case 'country':
              resultLocation.country = val || null;
              break;
            case 'postal_code':
              resultLocation.postalCode = val || null;
              break;
            case 'street_number':
              resultLocation.addressLine2 = val || null;
              break;
          }
        }
      }
      resultLocation.formattedAddress = place.formatted_address || null;
    }

    return resultLocation as BeLocation;
  }
}

export enum GoogleApiAddressForm {
  street_number = 'short_name',
  route = 'long_name',
  locality = 'long_name',
  administrative_area_level_2 = 'long_name',
  country = 'long_name',
  postal_code = 'short_name'
}
