import {Injectable} from '@angular/core';
import {environment} from '~environment';

@Injectable()
export class EncryptService {
  private crypto;
  private encrypted: string;
  private iv: string;
  private salt: string;
  private decrypted: any;

  constructor() {
    this.crypto = (window as any).CryptoJS;
  }

  public decript(data, bits = 256) {
    if (environment.addressName !== 'PROD' || !data) {
      return data;
    }

    this.getParamsEncrypt(data, bits);
    return JSON.parse(this.decrypted.toString(this.crypto.enc.Utf8));
  }

  public encript(data) {
    if (environment.addressName !== 'PROD' || !data) {
      return data;
    }

    return this.getEncryptedData(data);
  }

  private getEncryptedData(data) {
    let iv = this.crypto.lib.WordArray.random(128 / 8);
    let salt = this.crypto.lib.WordArray.random(512 / 8);
    let key = this.crypto.PBKDF2(environment.encryptionKey, salt, {
      keySize: 64 / 8,
      hasher: this.crypto.algo.SHA256,
      iterations: 999
    });

    let bodyEncrypted = this.crypto.AES.encrypt(JSON.stringify(data), key, {
      iv: iv
    });
    let response = [bodyEncrypted.toString(), iv.toString(), salt.toString()];
    return response;
  }

  private getParamsEncrypt(response, bits = 256) {
    this.encrypted = response[Object.keys(response)[0]];
    this.iv = this.crypto.enc.Hex.parse(response[Object.keys(response)[1]]);

    this.salt = this.crypto.enc.Hex.parse(response[Object.keys(response)[2]]);

    let key = this.crypto.PBKDF2(environment.encryptionKey, this.salt, {
      hasher: bits == 256 ? this.crypto.algo.SHA256 : this.crypto.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999
    });

    this.decrypted = this.crypto.AES.decrypt(this.encrypted, key, {
      iv: this.iv
    });
  }
}
