import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EstablishmentContactModalComponent} from './establishment-contact-modal.component';
import {IonicModule} from '@ionic/angular';
import {ApiServiceModule} from '@buscoextra/core/services/api';

@NgModule({
  imports: [CommonModule, IonicModule, ApiServiceModule],
  exports: [EstablishmentContactModalComponent],
  declarations: [EstablishmentContactModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EstablishmentContactModalComponentModule {}
