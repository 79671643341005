import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeCpdCardComponent } from './be-cpd-card.component';
import { IonicModule } from '@ionic/angular';
import { TimingCardComponentModule } from '../../../../@buscoextra/components/cards/timing-card/timing-card-component.module';
import { ContractResumeCardComponentModule } from '../../../../@buscoextra/components/cards/contract-resume-card/contract-resume-card-component.module';
import { ContractStatusComponentModule } from '../../../../@buscoextra/components/other/contract-status/contract-status-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, TimingCardComponentModule, ContractResumeCardComponentModule, ContractStatusComponentModule],
  exports: [BeCpdCardComponent],
  declarations: [BeCpdCardComponent]
})
export class BeCpdCardComponentModule { }
