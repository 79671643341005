import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Session, Entities, AuthApi} from '@buscoextra/core';
import {StateService} from '~/services/state';
import {ApiService} from '../api';
import {environment} from '~environment';
import {OAuthType} from '@buscoextra/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private api: ApiService, private state: StateService) {}

  isUserLogged(): boolean {
    return this.state.isUserLogged() && this.state.userExist();
  }

  refreshSession() {
    return this.api
      .apiRequestV2(
        Entities.Auth,
        AuthApi.refreshToken({
          client_id: environment.clientID,
          client_secret: environment.clientSecret,
          grant_type: 'refresh_token',
          refresh_token: this.state.refreshToken
        })
      )
      .pipe(
        map((session: Session) => {
          this.state.newSession({...this.state.session, ...session});
          return session;
        })
      );
  }

  authByEmail(userName: string, password: string): Observable<Session> {
    return this.api
      .apiRequestV2(
        Entities.Auth,
        AuthApi.login({
          client_id: environment.clientID,
          client_secret: environment.clientSecret,
          grant_type: 'password',
          password: password,
          username: userName
        }),
        'login'
      )
      .pipe(
        map((session: Session) => {
          this.state.newSession({...this.state.session, ...session});
          return session;
        })
      );
  }
}
