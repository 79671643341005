export function WorkResumeForServer(client_workResume) {
  return client_workResume;
}

export function WorkResumeClient(serve_workResume) {
  return {
    ...serve_workResume,
    // ...{
    //   formatTsAccNonVerified: getExperienciaFromTimeStamp(serve_workResume.formatTsAccNonVerified || 0),
    //   formatTsAccVerified: getExperienciaFromTimeStamp(serve_workResume.formatTsAccVerified || 0),
    //   formatTsTotal: getExperienciaFromTimeStamp(serve_workResume.formatTsTotal || 0)
    // }
  };
}

function getExperienciaFromTimeStamp(formatTsAcc: string) {
  let string;
  if (formatTsAcc == '0') {
    string = 'No indicada';
  } else {
    let formatArray = formatTsAcc.split(';');
    string =
      (formatArray[0] != '0' ? formatArray[0] + '' + (formatArray[0] == '1' ? ' año  ' : ' años  ') : '') +
      (formatArray[1] != '0' ? formatArray[1] + '' + (formatArray[1] == '1' ? ' mes  ' : ' meses  ') : '') +
      (formatArray[2] != '0' && formatArray[1] == '0'
        ? formatArray[2] + '' + (formatArray[2] == '1' ? ' día ' : ' días  ')
        : '') +
      (formatArray[3] != '0' && formatArray[2] == '0'
        ? formatArray[3] + '' + (formatArray[3] == '1' ? '  hora  ' : ' horas')
        : '') +
      (formatArray[4] != '0' && formatArray[3] == '0'
        ? formatArray[4] + '' + (formatArray[4] == '1' ? '  minuto  ' : ' minutos')
        : '');
  }

  return string;
}
