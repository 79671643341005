import {Injectable} from '@angular/core';
import {Cpd, ReviewApi} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {map, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Mark} from '@buscoextra/core';
import {CpdService} from '../cpd/cpd.service';
import {PsReview} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api/api.service';
import {PsReviewForServer} from '@buscoextra/core/services/api/mappers/psReview.mapper';

@Injectable()
export class ReviewService {
  constructor(private api: ApiService, private cpdService: CpdService) {}

  getBusinessAttributes(): Observable<{name: string; id: number}[]> {
    return this.api.apiRequestV2(Entities.Attribute, ReviewApi.getBusinessAttributes());
  }

  getExtrasAttributes(): Observable<{name: string; id: number}[]> {
    return this.api.apiRequestV2(Entities.Attribute, ReviewApi.getExtrasAttributes());
  }

  getBusinessMarks(): Observable<Mark[]> {
    return this.getBusinessAttributes().pipe(
      map(res => {
        if (res && res.length > 0) {
          return res.map(r => {
            return {
              attribute: r.id,
              name: r.name,
              rate: 0,
            } as Mark;
          });
        } else {
          return [];
        }
      })
    );
  }

  getExtraMarks() {
    return this.getExtrasAttributes().pipe(
      map(res => {
        if (res && res.length > 0) {
          return res.map(r => {
            return {
              attribute: r.id,
              name: r.name,
              rate: 0,
            } as Mark;
          });
        } else {
          return [];
        }
      })
    );
  }

  reviewBusiness(marks, cpdId): Observable<boolean> {
    return this.api.apiRequestV2(Entities.Review, ReviewApi.createReview({cpd: cpdId, marks: marks} as any)).pipe(
      mergeMap(res => {
        return this.cpdService.extraReviewCpd(cpdId);
      })
    );
  }

  reviewExtra(psReview: PsReview, cpdId: number): Observable<boolean> {
    if (psReview.id) {
      return this.api.apiRequestV2(Entities.PsReview, ReviewApi.updatePsReview(PsReviewForServer(psReview))).pipe(
        mergeMap(res => {
          return this.cpdService.businessReviewCpd(cpdId);
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.PsReview, ReviewApi.createPsReview(PsReviewForServer(psReview))).pipe(
        mergeMap(res => {
          return this.cpdService.businessReviewCpd(cpdId);
        })
      );
    }
  }

  getExtraPSReview(userId: number, cpd: Cpd): Observable<PsReview> {
    return this.api.apiRequestV2(Entities.PsReview, ReviewApi.getExtraPsReviewFromUserId(userId, cpd)).pipe(
      map((re: PsReview[]) => {
        if (re && re.length > 0) {
          return re[0];
        } else {
          return undefined;
        }
      })
    );
  }
}
