import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StarsComponent} from './stars.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [StarsComponent],
  declarations: [StarsComponent]
})
export class StarsComponentModule {}
