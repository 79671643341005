import {Injectable} from '@angular/core';
import {
  Business,
  Contact,
  Cpd,
  Establishment,
  Extra,
  Offer,
  Resource,
  BusinessApi,
  OfferApi,
  CpdApi,
  ContactApi,
  ExtraApi,
  EstablishmentApi,
} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {EstablishmentService} from '@buscoextra/services/establishment/establishment.service';
import {CpdService} from '@buscoextra/services/cpd/cpd.service';
import {OfferService} from '@buscoextra/services/offer/offer.service';
import {isValidIBAN, isValidNif, isValidNie, isValidCCC} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api';
import {ResourceService, ResourceTags} from '@buscoextra/services/resource/resource.service';

@Injectable()
export class BusinessService {
  constructor(
    private api: ApiService,
    private establishment: EstablishmentService,
    private cpd: CpdService,
    private offerService: OfferService,
    private resourceService: ResourceService
  ) {}

  getBusinessById(id: number): Observable<Business> {
    return this.api.apiRequestV2(Entities.Business, BusinessApi.getBusinessById(id));
  }

  getBusinessContractsHistory(businessId: number, page: number): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.getCpds(page, 25, '&business=' + businessId + '&status=AND,lk/OR/succeed;finished'));
  }

  isReadyToHire(currentEstablishmentId: number): Observable<boolean> {
    let establishment;
    return this.api.apiRequestV2(Entities.Establishment, EstablishmentApi.getEstablishmentById(currentEstablishmentId)).pipe(
      mergeMap((resEst: Establishment) => {
        establishment = resEst;
        return this.hasPRLDocs(establishment.owner.id, establishment.id);
      }),
      map((hasPrl: boolean) => {
        let company = establishment.company;
        return (
          company != undefined &&
          hasPrl &&
          company.name != undefined &&
          company.billLocation != undefined &&
          company.billLocation.formattedAddress != undefined &&
          establishment.cpdSignatoryLastName != undefined &&
          establishment.cpdSignatoryName != undefined &&
          establishment.cpdSignatoryCharge != undefined &&
          establishment.cpdSignatoryNIF != undefined &&
          establishment.cpdSignatoryPhoneNumber != undefined &&
          establishment.CCC != undefined &&
          isValidCCC(establishment.CCC) &&
          (isValidNif(establishment.cpdSignatoryNIF) || isValidNie(establishment.cpdSignatoryNIF))
        );
      })
    );
  }

  hasPRLDocs(businessID, establishmentId) {
    return this.resourceService.getEstablishmentPrLDocument(businessID, establishmentId).pipe(
      map((res: Resource[]) => {
        return res && res.length > 0;
      })
    );
  }
  cpdSignatureContact(establishmentId) {
    return this.establishment.getEstablishmentCpdSignature(establishmentId);
  }

  isReadyToCreateOffer(businessId: number, establishmentId: number): Observable<boolean> {
    return forkJoin([this.getBusinessById(businessId), this.establishment.getById(establishmentId)]).pipe(
      map((res: any[]) => {
        const business = res[0] as Business;
        const establishment = res[1] as Establishment;
        if (
          business.email &&
          business.phoneNumber &&
          establishment.name &&
          establishment.location &&
          establishment.location.formattedAddress &&
          establishment.agreement
        ) {
          return true;
        }
        return false;
      })
    );
  }

  saveDNI(businessId: number, dni: string): Observable<any> {
    return this.api.apiRequestV2(Entities.Business, BusinessApi.updateBusiness({id: businessId, dni: dni} as any));
  }

  getFavoritesExtras(userId): Observable<Extra[]> {
    return this.api.apiRequestV2(Entities.Business, ContactApi.getFvoritesContactsByOwner(userId)).pipe(
      map((res: Contact[]) => {
        return res.map(res => {
          return res.contact as Extra;
        });
      })
    );
  }

  getContactByExtraId(userId, extraId): Observable<Contact[]> {
    return this.api.apiRequestV2(Entities.Business, ContactApi.getContactByOwnerEntityAndEntityId(userId, 'user', extraId));
  }

  isExtraFavorite(userId: number, extraId: number): Observable<boolean> {
    return this.getContactByExtraId(userId, extraId).pipe(
      map((res: Contact[]) => {
        if (!res || res.length == 0) return false;
        return res[0].favourite as any;
      })
    );
  }

  deleteFavoriteExtra(userId: number, extraId: number): Observable<any> {
    return this.getContactByExtraId(userId, extraId).pipe(
      mergeMap((res: Contact[]) => {
        if (!res || res.length == 0) return of(false);
        return this.api.apiRequestV2(Entities.Contact, ContactApi.deleteContact(res[0].id));
      })
    );
  }

  addFavoriteExtra(userId: number, extraId: number) {
    let data = ContactApi.createContact({
      favourite: true,
      entityName: 'user',
      entityId: extraId,
      contact: {id: extraId},
      owner: userId,
    } as any);

    return this.api.apiRequestV2(Entities.Contact, data);
  }

  getExtraInfo(extraId): Observable<Extra[]> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.getExtraById(extraId));
  }
}
