import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent {
  @Input() value: number;
  @Input() nStars = 5;
  @Input() editable = false;
  @Input() size: number = 16;
  @Output('onChange') valueEmitter: EventEmitter<any> = new EventEmitter();

  starsArray = [];
  constructor() {
    //STARS.exampleValue
  }

  ngOnInit() {
    this.starsArray = new Array(this.nStars).fill(0);
  }

  change() {
    this.valueEmitter.emit(this.value);
  }
}
