import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocationModalPage} from './location-modal.page';
import {LocationModalRoutingModule} from './location-modal.routing';
import {IonicModule} from '@ionic/angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, LocationModalRoutingModule],
  exports: [LocationModalPage],
  declarations: [LocationModalPage]
})
export class LocationModalPageModule {}
