import {NgModule} from '@angular/core';
import {FileManagerService} from './file-manager.service';
import {HeadersService} from '@buscoextra/core/services/api/services/headers.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [FileManagerService, HeadersService]
})
export class FileManagerModule {}
