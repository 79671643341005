import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkType} from '@buscoextra/core';

@Component({
  selector: 'be-worktype-card',
  templateUrl: './be-worktype-card.component.html',
  styleUrls: ['./be-worktype-card.component.scss'],
})
export class BeWorktypeCardComponent implements OnInit {
  @Input() src: WorkType = {} as WorkType;
  @Input() active = false;

  @Output('onChange') workTypeChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    console.log('be-worktype-card', this.src);
  }

  onWtChange() {
    this.workTypeChange.emit(this.src);
  }
}
