import {mappers} from '../mappers';
import {deleteProps, reduceToSomeFields} from '../mappers/tools/mappers-tools';

type Direction = 'server' | 'client';

export function forServer(entityName: string, entityValue: any, selectedFields?: string[]) {
  entityValue = deleteProps(entityValue, ['id', 'createdAt', 'updatedAt']);
  if (!mappers.server[entityName]) return entityValue;
  return mapperEntity('server', entityName, entityValue, selectedFields);
}

export function forClient(entityName: string, entityValue: any) {
  if (!mappers.client[entityName]) return entityValue;
  return mapperEntity('client', entityName, entityValue);
}

function mapperEntity(direction: Direction, entityName: string, entityValue: any, selectedFields?: string[]) {
  if (entityValue instanceof Array) {
    return [
      ...entityValue.map(itemEntityValue => {
        return doMapper(direction, entityName, itemEntityValue, selectedFields);
      })
    ];
  } else {
    return doMapper(direction, entityName, entityValue, selectedFields);
  }
}

function doMapper(direction: Direction, entityName: string, entityValue: any, selectedFields: string[] = []) {
  let value = reduceToSomeFields(entityValue, selectedFields);
  return mappers[direction][entityName](value);
}
