import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarDay } from './models/calendar-day';
import { Schedule, TimingSlot } from '@buscoextra/core';
import { IonDatetime } from '@ionic/angular';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss']
})
export class CalendarPickerComponent implements OnInit {
  @Input() selectedDaysDate: Schedule[] = [];
  @Input() maxSelectionDays: number = 30;
  @Output() onScheduleChange: EventEmitter<Schedule[]> = new EventEmitter();
  @Output() onError: EventEmitter<string> = new EventEmitter();

  date;
  currentYear;
  currentMonth;
  monthLength;
  firstDayOfCurrentMonth;
  lastDayOfCurrentMonth;
  lastMonthLength;
  daysArray: any[] = [];
  dayRowWidth = window.innerWidth * 0.14;
  selectedDays = 0;
  daysWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  daysWeekExtended = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  scheduleIncomplete: boolean = false;

  constructor() { }

  ngOnInit() {
    this.date = new Date();
    this.currentYear = this.date.getFullYear();
    this.currentMonth = this.date.getMonth();
    this.getStartAndFinishDayOfCurrentMonth();
    this.selectedDays = this.selectedDaysDate.length;

    let that = this;
    window.addEventListener('resize', function () {
      that.dayRowWidth = window.innerWidth * 0.14;
    });
  }

  checkTimeForm(day, slot, type, time: IonDatetime) {
    this.selectedDaysDate[day].timing[slot][type] =
      (new Date(time.value).getHours() <= 9 ? '0' + new Date(time.value).getHours() : new Date(time.value).getHours()) +
      ':' +
      (new Date(time.value).getMinutes() <= 9 ? '0' + new Date(time.value).getMinutes() : new Date(time.value).getMinutes());

    if (this.selectedDaysDate[day].timing[slot]['finishTime'] === this.selectedDaysDate[day].timing[slot]['startTime']) {
      this.onError.emit('La hora de inicio y fin no pueden coincidir');
      this.selectedDaysDate[day].timing[slot][type] = undefined;
    }
  }

  checkDateError(time1: IonDatetime, time2: IonDatetime) {
    if (time1.value && time2.value && time1.value === time2.value && time1.value.length > 0 && time2.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  getStartAndFinishDayOfCurrentMonth(): any {
    this.daysArray = [];
    this.firstDayOfCurrentMonth = new Date(this.currentYear, this.currentMonth, 1).getDay() - 1;
    this.lastDayOfCurrentMonth = new Date(this.currentYear, this.currentMonth + 1, 1).getDay() - 2;

    // en este caso el mes empieza en 1:S
    this.lastMonthLength = new Date(this.currentYear, this.currentMonth, 0).getDate();
    this.monthLength = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();

    if (this.firstDayOfCurrentMonth == -1) this.firstDayOfCurrentMonth = 6;
    if (this.lastDayOfCurrentMonth == -1) this.lastDayOfCurrentMonth = 6;

    for (let i = 1; i <= this.firstDayOfCurrentMonth; i++) {
      let day = {} as CalendarDay;
      day.day = this.lastMonthLength - (this.firstDayOfCurrentMonth - i);
      day.selected = false;
      day.notCurrent = true;
      day.currentDate = new Date(this.currentYear, this.currentMonth - 1, day.day);
      this.daysArray.push(day);
    }

    for (let e = 1; e < this.monthLength + 1; e++) {
      let day = {} as CalendarDay;
      day.day = e;
      day.currentDate = new Date(this.currentYear, this.currentMonth, e);
      day.selected = false;
      day.notCurrent = false;
      this.daysArray.push(day);
    }

    for (let j = 0; j < 6 - this.lastDayOfCurrentMonth; j++) {
      let day = {} as CalendarDay;
      day.day = j + 1;
      day.currentDate = new Date(this.currentYear, this.currentMonth + 1, j + 1);
      day.selected = false;
      day.notCurrent = true;
      this.daysArray.push(day);
    }
    this.checkSelected();
  }

  increaseMonth() {
    this.currentMonth += 1;
    if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear += 1;
    }
    this.getStartAndFinishDayOfCurrentMonth();
  }
  decreaseMonth() {
    this.currentMonth -= 1;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear -= 1;
    }
    this.getStartAndFinishDayOfCurrentMonth();
  }
  increaseYear() {
    this.currentYear += 1;
    this.getStartAndFinishDayOfCurrentMonth();
  }
  decreaseYear() {
    this.currentYear -= 1;
    this.getStartAndFinishDayOfCurrentMonth();
  }

  getDayContainerStyle() {
    return {
      width: this.dayRowWidth - 10 + 'px',
      height: this.dayRowWidth - 10 + 'px'
    };
  }

  selectDay(day) {
    if (this.selectedDays + 1 <= this.maxSelectionDays && !this.oldDay(day.currentDate)) {
      day.selected = true;
      this.selectedDays++;
      let sDay = {} as Schedule;
      sDay.date = day.currentDate;
      sDay.timing = [{ startTime: undefined, finishTime: undefined }] as TimingSlot[];
      this.selectedDaysDate.push(sDay);

      this.selectedDaysDate = this.selectedDaysDate.sort((a: any, b: any) => {
        if (a.date.getTime() > b.date.getTime()) {
          return 1;
        } else {
          return -1;
        }
      });
    } else if (this.selectedDays + 1 > this.maxSelectionDays) {
      this.onError.emit(
        'Este servicio no permite contratar más de  ' +
        this.maxSelectionDays +
        ' días, seleccione otro servicio o ponte en contacto con nosotros en hola@buscoextra.es'
      );
    } else {
      this.onError.emit('No se pueden seleccionar días pasados');
    }
  }

  unSelectDay(day) {
    this.selectedDays--;
    day.selected = false;
    let day2Remove = this.isDateOnSelectedDays(day);
    if (day2Remove != undefined && day2Remove == 0 && this.selectedDaysDate.length == 1) {
      this.selectedDaysDate.splice(-1, 1);
    } else if (day2Remove != undefined) {
      this.selectedDaysDate.splice(day2Remove, 1);
    }
  }

  isDateOnSelectedDays(day) {
    let findIndex;
    this.selectedDaysDate.forEach((date, index) => {
      if (
        date.date.getDate() == day.currentDate.getDate() &&
        date.date.getMonth() == day.currentDate.getMonth() &&
        date.date.getFullYear() == day.currentDate.getFullYear()
      ) {
        findIndex = index;
      }
    });
    return findIndex;
  }

  checkSelected() {
    this.selectedDaysDate.forEach(any => {
      this.daysArray.forEach(day => {
        if (
          day.currentDate.getDate() == any.date.getDate() &&
          day.currentDate.getMonth() == any.date.getMonth() &&
          day.currentDate.getFullYear() == any.date.getFullYear()
        ) {
          day.selected = true;
        }
      });
    });
  }

  addJobSchedule(time) {
    time.push({} as any);
  }

  removeJobSchedule(time, i) {
    if (i == 0 && time.length == 1) {
      time.splice(-1, 1);
    } else {
      time.splice(i, 1);
    }
  }

  save() {
    if (this.isScheduleComplete()) {
      this.updateDayStartDate();
      this.dismiss(this.selectedDaysDate);
    } else {
      this.scheduleIncomplete = true;
    }
  }

  getMainButtonText(scheduleIncomplete) {
    if (scheduleIncomplete) {
      return 'Faltan horas por completar';
    } else {
      return 'Guardar';
    }
  }

  dismiss(days: Schedule[]) {
    if (this.isScheduleComplete()) {
      this.onScheduleChange.emit(days);
    } else {
      this.onScheduleChange.emit(undefined);
    }
  }

  sameSchedule() {
    if (this.selectedDaysDate[0].timing.length == 2) {
      let sT = this.selectedDaysDate[0].timing[0].startTime;
      let fT = this.selectedDaysDate[0].timing[0].finishTime;
      let sT2 = this.selectedDaysDate[0].timing[1].startTime;
      let fT2 = this.selectedDaysDate[0].timing[1].finishTime;

      this.selectedDaysDate.forEach(any => {
        if (any.timing.length == 1) {
          any.timing.push({} as any);
        }
        any.timing.forEach((timing, index) => {
          if (index == 0) {
            timing.startTime = sT;
            timing.finishTime = fT;
          } else if (index == 1) {
            timing.startTime = sT2;
            timing.finishTime = fT2;
          }
        });
      });
    } else if (this.selectedDaysDate[0].timing.length == 1) {
      let sT = this.selectedDaysDate[0].timing[0].startTime;
      let fT = this.selectedDaysDate[0].timing[0].finishTime;

      this.selectedDaysDate.forEach(any => {
        any.timing.forEach(timing => {
          timing.startTime = sT;
          timing.finishTime = fT;
        });
      });
    }
  }

  getNextDay(date) {
    let newDate = new Date();
    newDate.setDate(date.getDate() + 1);
    return newDate.getDate() + ' ' + this.months[newDate.getMonth()].substring(0, 3);
  }

  isScheduleComplete() {
    let complete = true;
    this.selectedDaysDate.forEach(any => {
      any.timing.forEach(timing => {
        if (!timing.startTime || !timing.finishTime) complete = false;
      });
    });
    return complete;
  }

  updateDayStartDate() {
    this.selectedDaysDate.forEach(day => {
      day.date.setHours(+day.timing[0].startTime.split(':')[0]);
      day.date.setMinutes(+day.timing[0].startTime.split(':')[1]);
    });
  }

  isToday(currentDate) {
    let today = new Date();

    if (
      currentDate.getDate() == today.getDate() &&
      currentDate.getMonth() == today.getMonth() &&
      currentDate.getFullYear() == today.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  }
  oldDay(currentDate) {
    let today = new Date();
    if (currentDate.getTime() < today.getTime() && !this.isToday(currentDate)) {
      return true;
    } else {
      return false;
    }
  }

  isNextDayTime(timeSlot) {
    let result = false;
    if (timeSlot.finishTime.split(':')[0] < timeSlot.startTime.split(':')[0]) {
      result = true;
    } else if (timeSlot.finishTime.split(':')[0] == timeSlot.startTime.split(':')[0]) {
      if (timeSlot.finishTime.split(':')[1] < timeSlot.startTime.split(':')[1]) {
        result = true;
      }
    }
    return result;
  }
}
