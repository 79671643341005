import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RateExtraPage} from './rate-extra.page';
import {RateExtraRoutingModule} from './rate-extra.routing';
import {IonicModule} from '@ionic/angular';
import {RateComponentModule} from '@buscoextra/components/other/rate/rate-component.module';
import {ReviewServiceModule} from '@buscoextra/services/review/review.module';
import {NavBackButtonComponentModule} from '~/components/nav-back-button/nav-back-button-component.module';
import {BeLoadingComponentModule} from '@components';
import {BeBusinessCpdCardComponentModule} from '@buscoextra/components/cards/be-business-cpd-card/be-business-cpd-card-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    RateExtraRoutingModule,
    RateComponentModule,
    ReviewServiceModule,
    BeLoadingComponentModule,
    BeBusinessCpdCardComponentModule,
    NavBackButtonComponentModule
  ],
  declarations: [RateExtraPage]
})
export class RateExtraPageModule {}
