import {Injectable} from '@angular/core';
import {ActionSheetController, AlertController, ToastController, PopoverController} from '@ionic/angular';
import {Form} from './models/alert';
import {ActionSheetButton, AlertInput} from '@ionic/core';
import {PopOverMenuComponent} from './components/pop-over-menu/pop-over-menu.component';

@Injectable()
export class DialogService {
  constructor(
    private ionicToast: ToastController,
    private popoverController: PopoverController,
    private ionicAlert: AlertController,
    private ionicActionSheet: ActionSheetController
  ) {}

  async toast(msg: string, _duration?: number) {
    const toast = await this.ionicToast.create({
      message: msg,
      duration: _duration || this.getDurationAsMsg(msg),
      position: 'bottom'
    });
    toast.present();
  }

  private getDurationAsMsg(msg: string): number {
    const offset = 48;
    const minDuration = 800;
    return minDuration + msg.length * offset;
  }

  async alert(
    title: string,
    subtitle?: string,
    message?: string,
    buttons = [
      {
        text: 'OK',
        role: 'ok'
      }
    ],
    inputs?: AlertInput[]
  ): Promise<any> {
    return new Promise(async resolve => {
      if (!alert) resolve({});
      const ionicAlert = await this.ionicAlert.create({
        header: title,
        inputs: inputs,
        subHeader: subtitle,
        message: message,
        buttons: buttons
      });
      await ionicAlert.present();
      resolve(await ionicAlert.onDidDismiss());
    });
  }

  async presentActionSheet(title: string, actions: ActionSheetButton[] = []) {
    const actionSheet = await this.ionicActionSheet.create({
      header: title,
      buttons: actions
    });
    await actionSheet.present();
  }

  async presentPopOverMenu(actions: ActionSheetButton[], ev: Event): Promise<any> {
    return new Promise(async resolve => {
      if (!alert) resolve({});
      const popover = await this.popoverController.create({
        component: PopOverMenuComponent,
        componentProps: {
          actions: actions
        },
        event: ev,
        translucent: true
      });
      await popover.present();
      resolve(await popover.onDidDismiss());
    });
  }

  async confirm(title: string, subtitle?: string, message?: string, buttonText = 'OK', cancelBtnText = 'Cancelar'): Promise<any> {
    return new Promise(async resolve => {
      if (!alert) return;
      const ionicAlert = await this.ionicAlert.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: [
          {
            text: cancelBtnText,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: buttonText,
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      await ionicAlert.present();
    });
  }

  async form(form: Form = {} as any): Promise<any> {
    return new Promise(async resolve => {
      if (!form) return;
      let buttonsArray = [];
      if (form.buttonCancelText)
        buttonsArray.push({
          text: form.buttonCancelText,
          role: 'cancel',
          handler: () => {
            resolve(undefined);
          }
        });
      buttonsArray.push({
        text: form.buttonText || 'OK',
        role: 'ok',
        handler: res => {
          resolve(res);
        }
      });
      const ionicAlert = await this.ionicAlert.create({
        header: form.title,
        subHeader: form.subtitle || '',
        message: form.message || '',
        inputs: form.inputs,
        buttons: buttonsArray
      });

      await ionicAlert.present();
    });
  }
}
