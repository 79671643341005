import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeBusinessCpdCardComponent} from './be-business-cpd-card.component';
import {IonicModule} from '@ionic/angular';
import {BeExtraCardComponentModule} from '@components';
import {ContractResumeCardComponentModule} from '../contract-resume-card/contract-resume-card-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, BeExtraCardComponentModule, ContractResumeCardComponentModule],
  exports: [BeBusinessCpdCardComponent],
  declarations: [BeBusinessCpdCardComponent]
})
export class BeBusinessCpdCardComponentModule {}
