export function transformPropsObjectArraysToIDArrays(object: object, props: string[]): object {
  props.forEach(prop => (object[prop] = transformObjectArraysToIDArrays(object[prop])));
  return object;
}

export function transformObjectArraysToIDArrays(array: object[]): number[] {
  if (!array || !array.length) return [];
  return array.map(object => {
    if (typeof object == 'object') return object['id'];
    return object;
  });
}

export function deleteProps(object: object, props: string[] = []): object {
  props = [...props];
  props.forEach(prop => delete object[prop]);
  return object;
}

export function deleteEmptyArrays(object: object): object {
  Object.keys(object).map((prop: any) => {
    if (object[prop] instanceof Array && !object[prop].length) {
      delete object[prop];
    }
  });
  return object;
}

export function reduceToSomeFields(object: object, fields: string[]) {
  if (!fields.length) return object;
  let newObject = {};
  Object.keys(object).forEach(objectKey => {
    if (fields.indexOf(objectKey) !== -1) {
      newObject[objectKey] = object[objectKey];
    }
  });
  return newObject;
}
