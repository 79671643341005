import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeScheduleCardComponent} from './be-schedule-card.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [BeScheduleCardComponent],
  declarations: [BeScheduleCardComponent]
})
export class BeScheduleCardComponentModule {}
