import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {isValidCCC} from '@buscoextra/core';

@Directive({
  selector: '[cccValidator]'
})
export class CCCValidator {
  constructor() {}

  @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  ccc = '';
  @Output() cccNumberChange = new EventEmitter();
  @Input()
  get cccNumber() {
    return this.ccc;
  }

  set cccNumber(val) {
    this.isValid.emit(isValidCCC(val));
    this.cccNumberChange.emit(this.ccc);
  }
}
