import {NgModule} from '@angular/core';
import {NgRedux, NgReduxModule} from '@angular-redux/store';
import {ReduxActionsServiceModule} from './actions/redux-actions-service.module';
import {BEState} from './models/state';
import {BE_INITIAL_STATE, rootReducer} from './app.store';
import {StateService} from './state.service';
import {StorageServiceModule} from '../storage/storage-service.module';
import {StorageService} from '../storage/storage.service';

@NgModule({
  imports: [NgReduxModule, StorageServiceModule, ReduxActionsServiceModule],
  providers: [StateService]
})
export class StateServiceModule {
  constructor(ngRedux: NgRedux<BEState>, storage: StorageService) {
    const initialState = storage.getSavedAppState() || BE_INITIAL_STATE;
    ngRedux.configureStore(rootReducer, initialState);
  }
}
