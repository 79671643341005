import {Component, ViewEncapsulation} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {DeeplinkService} from './services/deeplink/deeplink.service';
import {UserService} from '@services';
import {UserType} from '@buscoextra/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {NavigationService} from './services';
import {StateService} from '~/services';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {AnalyticsService} from './services/analytics/analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {ReviewService} from '@buscoextra/services/review/review.service';
import {ReviewsService} from './pages/home/shell-business-page/pages/reviews/reviews.service';
import {ReviewsPage} from './pages/home/shell-business-page/pages/reviews/reviews.page';
import {AppService} from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends AsyncPage {
  private modal;
  public globalLoading: boolean = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private deepLinkService: DeeplinkService,
    private navigation: NavigationService,
    private state: StateService,
    private user: UserService,
    private keyboard: Keyboard,
    private comunication: CommunicationsService,
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private reviewService: ReviewsService,
    private modalCtl: ModalController,
    private appService: AppService
  ) {
    super(comunication);
    translate.setDefaultLang('es');
    translate.use('es');
    this.initializeApp();
  }

  async initializeApp() {
    this.appService.globalLoading.subscribe(res => {
      this.globalLoading = res;
      setTimeout(() => {
        this.globalLoading = false; //Hide global loading after 8 seconds, just in case
      }, 8000);
    });
    if (this.state.isUserLogged() && this.state.isABusinessSession()) {
      this.reviewService.arePendingReviews(this.state.userId).subscribe(async res => {
        if (res) {
          if (!this.modal) {
            this.modal = await this.modalCtl.create({
              component: ReviewsPage,
            });
          }
          this.modal.present();
          return;
        }
      });
    }

    document.addEventListener(
      'resume',
      () => {
        if (
          (!this.state.isUserLogged() || !this.state.user || !this.state.user.id) &&
          (this.navigation.isActualRouteIn('/extras') || this.navigation.isActualRouteIn('/business'))
        ) {
          this.state.finishSession();
          this.navigation.jumpTo('');
        }
      },
      false
    );

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        this.keyboard.hideFormAccessoryBar(false);
      }
      this.deepLinkService.initDeepLinkListener();
      this.tryToNotifyUserAgent();
    });
  }

  async tryToNotifyUserAgent() {
    let userAgentWatcher = setInterval(async () => {
      let status = await this.notifyUserStatus();
      if (status) {
        clearInterval(userAgentWatcher);
      }
    }, 5000);
  }

  async notifyUserStatus() {
    if (window.localStorage.getItem('impersonate')) return true;
    if (!this.state.user || !this.state.user.id || !this.state.isUserLogged() || !this.state.session.firebaseToken) return false;
    const user =
      this.state.userType == UserType.Business
        ? await this.send(this.user.notifyUserAgentBusiness(this.state.userId, {firebaseToken: this.state.session.firebaseToken}), 'notify')
        : await this.send(this.user.notifyUserAgentExtra(this.state.userId, {firebaseToken: this.state.session.firebaseToken}), 'notify');
    if (user) {
      this.state.updateUser(user);
      return user.firebaseToken != undefined;
    } else {
      if (this.errors['notify']) return true;
      return false;
    }
  }
}
