import {Injectable} from '@angular/core';
import {Extra, Offer} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {Observable} from 'rxjs';
import {OfferStatus} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api';
import {OfferApi, ExtraApi} from '@buscoextra/core';

@Injectable()
export class OfferService {
  constructor(private api: ApiService) {}

  createOffer(offer: Offer): Observable<Offer> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.createOffer(offer as any));
  }

  updateOffer(offer: Offer): Observable<Offer> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.updateOffer(offer as any));
  }

  getHistoryOffers(businessId, page): Observable<Offer[]> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getHistoryOffer(businessId));
  }

  getEstablishmentsOffers(userId: number, establishmentId: number, page?: number, pageSize?: number): Observable<Offer[]> {
    return this.api.apiRequestV2(
      Entities.Offer,
      OfferApi.getEstablishmentsOffer(
        userId,
        establishmentId,
        page,
        pageSize,
        `&status=AND,clk/OR/${OfferStatus.PENDING};${OfferStatus.PRIVATE};${OfferStatus.PUBLIC};${OfferStatus.COMPLETED};${OfferStatus.CANCELLED};${OfferStatus.FINISHED};${OfferStatus.SUCCEED}`
      )
    );
  }
  getEstablishmentsActiveOffers(userId: number, establishmentId: number, page?: number, pageSize?: number): Observable<Offer[]> {
    return this.api.apiRequestV2(
      Entities.Offer,
      OfferApi.getEstablishmentsOffer(
        userId,
        establishmentId,
        page,
        pageSize,
        `&status=AND,clk/OR/${OfferStatus.PENDING};${OfferStatus.PRIVATE};${OfferStatus.PUBLIC};${OfferStatus.COMPLETED}`
      )
    );
  }

  getOfferById(offerId: number): Observable<Offer> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getOfferById(offerId));
  }

  getPosibleOfferExtras(offerId: number, page): Observable<Extra[]> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getOfferExtrasBusinessView(offerId, page, undefined, 'tsTotal=DESC'));
  }

  updateIsPublic(offerId: number, isPublic: boolean): Observable<boolean> {
    const data = {id: offerId, status: isPublic ? OfferStatus.PUBLIC : OfferStatus.PRIVATE};
    return this.api.apiRequestV2(Entities.Offer, OfferApi.updateOffer(data));
  }

  remove(offerId: number): Observable<Offer> {
    const data = {id: offerId, status: OfferStatus.CANCELLED};
    return this.api.apiRequestV2(Entities.Offer, OfferApi.updateOffer(data));
  }

  archive(offerId: number): Observable<Offer> {
    const data = {id: offerId, status: OfferStatus.SUCCEED};
    return this.api.apiRequestV2(Entities.Offer, OfferApi.updateOffer(data));
  }

  getOffersByExtraId(extraId: number, page?: number, pageSize?: number): Observable<Offer[]> {
    return this.api.apiRequestV2(Entities.Offer, ExtraApi.getExtraOffers(extraId));
  }

  getAllOffers(page?: number, pageSize?: number): Observable<Offer[]> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getAllOffers(page, pageSize));
  }
}
