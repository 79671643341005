import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { Cpd, Service, ShortTermTiming } from '@buscoextra/core';

@Component({
  selector: 'app-extra-cpd-confirmation',
  templateUrl: './extra-cpd-confirmation.component.html',
  styleUrls: ['./extra-cpd-confirmation.component.scss']
})
export class ExtraCpdConfirmationComponent implements OnInit {
  public cpds: Cpd[] = [];
  public confirmationArray: boolean[] = [];
  selectedCpd = 0;
  @ViewChild('slider', { static: true }) slider: IonSlides;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.confirmationArray = new Array(this.cpds.length).fill(undefined);
  }

  ionViewDidEnter() {
    if (this.slider) this.slider.update();
  }

  getPriceTime(cpd: Cpd) {
    if (!cpd || !cpd['cpdPriceTime']) return;
    return cpd.cpdPriceTime;
  }

  getPriceTimeType(cpd: Cpd) {
    if (!cpd || !cpd['cpdPriceTime']) return;
    return (cpd.cpdPriceTime.service as Service).timingFormatType;
  }

  getSchedule(cpd: Cpd) {
    if (!cpd || !cpd['cpdPriceTime']) return [];
    return (cpd.cpdPriceTime.timingFormat as ShortTermTiming).schedule;
  }

  async setConfirmationStatus(cpd, status) {
    this.confirmationArray[await this.slider.getActiveIndex()] = status;
    if (await this.slider.isEnd()) {
      this.dismiss();
      return;
    }
    this.slider.slideNext();
  }

  dismiss() {
    this.modalCtrl.dismiss(this.confirmationArray);
  }
}
