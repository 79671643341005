import {NgModule} from '@angular/core';
import {LoginErrorsService} from './errors/login.errors.service';
import {ExtraErrorsService} from './errors/offers.errors.service';
import {ErrorsService} from './errors.service';
import {CompanyErrorsService} from './errors/companies.errors.service';
import {SentryServiceModule} from './lib/sentry/sentry-service.module';

@NgModule({
  imports: [SentryServiceModule],
  providers: [ErrorsService, LoginErrorsService, ExtraErrorsService, CompanyErrorsService]
})
export class ErrorsServiceModule {}
