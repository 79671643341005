import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-offer-card-menu',
  templateUrl: './offer-card-menu.component.html',
  styleUrls: ['./offer-card-menu.component.scss']
})
export class OfferCardMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
