import {CVItem} from '@buscoextra/core';

export function CvItemsForServer(client_cv_items: CVItem) {
  client_cv_items.startDate = new Date(client_cv_items.startDate);
  client_cv_items.startDate.setSeconds(0, 0);
  client_cv_items.finishDate = new Date(client_cv_items.finishDate);
  client_cv_items.finishDate.setSeconds(0, 0);
  delete client_cv_items.owner;
  delete client_cv_items.verified;
  delete client_cv_items.timestamp;
  delete client_cv_items.formatTimestamp;
  delete client_cv_items.cpd;
  return client_cv_items;
}

export function CvItemsForClient(server_cv_items) {
  return server_cv_items;
}
