import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Extra} from '@buscoextra/core';
import {NavigationService} from '~/services';

@Component({
  selector: 'be-extras-list',
  templateUrl: './be-extras-list.component.html',
  styleUrls: ['./be-extras-list.component.scss']
})
export class BeExtrasListComponent {
  constructor(private navigation: NavigationService) {}

  @Input() extras: Extra[];
  @Output('loaderMoreItems') loadMoreItemsEmitter: EventEmitter<any> = new EventEmitter();

  @Input() primaryBtn: string;
  @Input() secondBtn: string;

  @Output('onSecondBtn') secondEmitter: EventEmitter<Extra> = new EventEmitter();
  @Output('onPrimaryBtn') primaryEmitter: EventEmitter<Extra> = new EventEmitter();

  onScrollEnd(e) {
    this.loadMoreItemsEmitter.emit(e);
  }

  UIonPrimaryBtnPressed(extra: Extra) {
    this.primaryEmitter.emit(extra);
  }

  UIonSecondaryBtnPressed(extra: Extra) {
    this.secondEmitter.emit(extra);
  }

  UIonExtraCardPressed(extra: Extra) {
    this.navigation.goto('business/extra/' + extra.id);
  }
}
