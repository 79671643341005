import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable()
export class ModalsService {
  constructor(private ionicModal: ModalController) { }

  async launchPage(page: any, props: object = {}, cssClass = ''): Promise<any> {
    return new Promise(async resolve => {
      const modal = await this.ionicModal.create({
        component: page,
        componentProps: props,
        cssClass: cssClass
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      resolve(data);
    });
  }

  dismiss() {
    this.ionicModal.dismiss({
      'dismissed': true
    });
  }
}
