import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {BeLoadingComponentModule} from '@components';
import {BusinessServiceModule} from '@buscoextra/services/user/business/business.module';
import {BeHeaderComponentModule} from '~/components/ion-form-header/ion-form-header-component.module';
import {ReviewsPage} from './reviews.page';
import {ReviewsRoutingModule} from './reviews.routing';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    BeLoadingComponentModule,
    BusinessServiceModule,
    BeHeaderComponentModule,
    ReviewsRoutingModule,
    TranslateModule,
  ],
  declarations: [ReviewsPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReviewsPageModule {}
