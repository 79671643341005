import {NgModule} from '@angular/core';
import {GeolocationService} from './geolocation.service';
import {AgmCoreModule} from '@agm/core';
import {DeviceServiceModule} from '../device/device-service.module';
import {GoogleMapsApiServiceModule} from './lib/googleMapsApi/googleMapsApi-service.module';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYPSu40D7zp7mPNh04e_0vW2oxT-ql7l4',
      libraries: ['places'],
      region: 'ES',
      language: 'es'
    }),
    DeviceServiceModule,
    GoogleMapsApiServiceModule
  ],
  exports: [],
  declarations: [],
  providers: [GeolocationService]
})
export class GeolocationServiceModule {}
