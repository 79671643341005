import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorsService} from './services/errors.service';
import {Observable, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CustomError} from './models/custom-error';

@Injectable()
export class CommunicationsService {
  constructor(private errors: ErrorsService) {}

  getValue(observable: Observable<any>, key: string): Promise<any> {
    return new Promise(resolve => {
      this.extractErrors(observable, key).subscribe(res => {
        resolve(res);
      });
    });
  }

  getObservable(observable: Observable<any>, key: string): Observable<any> {
    return this.extractErrors(observable, key);
  }

  private extractErrors(observable: Observable<any>, key: string): Observable<any> {
    return observable.pipe(
      map(res => {
        return this.onGetServerSuccessResponse(res);
      }),
      catchError((err: CustomError, cuaght: Observable<any>) => {
        return this.onGetServerErrorResponse(err, key);
      })
    );
  }

  private onGetServerSuccessResponse(response: Response, key?: string): Response {
    return response;
  }

  private onGetServerErrorResponse(error: CustomError, key?: string): Observable<HttpErrorResponse> {
    return this.errors.manageErrorsByKey(error, key);
  }
}
