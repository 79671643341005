import {Injectable} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {BEState} from './models/state';
import {BEEstablishmentActions} from './actions/redux-establishment.actions';
import {BESessionActions} from './actions/redux-session.actions';
import {Establishment, Session} from '@buscoextra/core';
import {User, UserType} from '@buscoextra/core';
import {BEUserActions} from './actions/redux-user.actions';
import {Entities} from '@buscoextra/core';
import {LocalStorageKeys, StorageService} from '../storage';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(
    private ngRedux: NgRedux<BEState>,
    private usersAction: BEUserActions,
    private establishmentsAction: BEEstablishmentActions,
    private storage: StorageService,
    private sessionsAction: BESessionActions
  ) {}

  get session(): Session {
    return this.getEntity(Entities.Session);
  }
  get user(): User {
    return this.getEntity(Entities.User);
  }

  get currentEstablishment(): Establishment {
    return this.getEntity(Entities.Establishment) || {};
  }

  get authToken(): string {
    if (!this.session) return undefined;
    return this.session.accessToken;
  }

  get refreshToken(): string {
    if (!this.session) return undefined;
    return this.session.refreshToken;
  }

  get userId(): number {
    return this.session.userId;
  }

  set currentEstablishment(establishment: Establishment) {
    this.updateEntity(Entities.Establishment, establishment);
  }

  get currentEstablishmentId(): number {
    return this.currentEstablishment.id;
  }

  get hasCurrentEstablishment(): boolean {
    return this.currentEstablishment && this.currentEstablishment.id !== undefined;
  }

  isAnExtraSession(): boolean {
    if (!this.session) return false;
    return this.session.userType === UserType.Extra;
  }

  isABusinessSession(): boolean {
    if (!this.session) return false;
    return this.session.userType === UserType.Business;
  }

  get userType(): UserType {
    return this.session.userType;
  }

  isUserLogged(): boolean {
    return this.authToken !== undefined;
  }

  userExist() {
    return this.user && this.user.id != undefined;
  }

  newSession(session: Session) {
    this.updateEntity(Entities.Session, session);
    return session;
  }

  finishSession() {
    this.doEntityAction(Entities.Session, 'finishSession', {});
    this.storage.removeImpersonateMail(LocalStorageKeys.Impersonate);
  }

  updateUser(user: User) {
    this.updateEntity(Entities.User, user);
  }

  initalizeExtraSession() {
    this.updateEntity(Entities.Session, {
      userType: UserType.Extra
    });
  }

  initalizeBusinessSession() {
    this.updateEntity(Entities.Session, {
      userType: UserType.Business
    });
  }

  private getEntity(entity: Entities) {
    return this.ngRedux.getState()[entity];
  }

  private updateEntity(entityName: Entities, newState: any) {
    this.doEntityAction(entityName, 'update', newState);
  }

  private doEntityAction(entityName: Entities, actionName: string, propAction: any) {
    this.ngRedux.dispatch(this[`${entityName}Action`][actionName](propAction));
  }
}
