import {Injectable} from '@angular/core';
import {Cpd, NoteApi, ExtraApi} from '@buscoextra/core';
import {CustomEntities, Entities} from '@buscoextra/core';
import {Observable, of, forkJoin} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {CpdStatus} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api';
import {CpdApi, OfferApi} from '@buscoextra/core';

@Injectable()
export class CpdService {
  constructor(private api: ApiService) {}

  getById(cpdId: number): Observable<Cpd> {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.getCpdById(cpdId));
  }

  getExtrasProfileResources(offerId: number, page?: number, pageSize?: number): Observable<any> {
    let countValue;
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getNumNotFailedCpdsInOffer(offerId)).pipe(
      mergeMap(value => {
        countValue = value;
        return this.api.apiRequestV2(Entities.Offer, OfferApi.getResourceProfileNotFailedCpdsInOffer(offerId));
      }),
      map(res => {
        if (res && res.length < countValue) {
          return [...res, ...new Array(countValue - res.length).fill({profileResource: {url: 'assets/default-user.svg'}})];
        }
        return res;
      })
    );
  }

  getOfferCpdsByStatus(offerId: number, status?: CpdStatus | ''): Observable<any> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getOfferCpdsByStatus(offerId, status));
  }

  requestExtra(extraId: number, offerId: number, businessId: number): Observable<Cpd> {
    const cpdPostData = {
      extra: extraId,
      business: businessId,
      offer: offerId,
      status: CpdStatus.PENDING,
    };
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.createCpd(cpdPostData));
  }

  hire(cpd: Cpd): Observable<Cpd> {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: CpdStatus.HIRED}));
  }

  confirm(cpd: Cpd): Observable<Cpd> {
    cpd.status = CpdStatus.CONFIRMED;
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status, extraLastConfirmationDate: new Date()}));
  }
  rejectPendingCpd(cpd: Cpd): Observable<Cpd> {
    cpd.status = CpdStatus.REJECTED;
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status}));
  }

  cancelCpdByExtraId(cpd: Cpd): Observable<Cpd> {
    cpd.status = CpdStatus.CANCELLED;
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status}));
  }

  updateCpdCancelInfo(cpdId, reason, userId) {
    let data = {
      entity: 'Cpd',
      entityId: cpdId,
      creatorId: userId,
      comment: reason,
    };
    return this.api.apiRequestV2(Entities.Cpd, NoteApi.createNote(data as any));
  }

  resignedCpdByExtraId(cpd: Cpd, extraId: number, lastDay: Date, reason?: string): Observable<Cpd> {
    cpd.status = CpdStatus.RESIGNED;
    return forkJoin([
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, ...(cpd.finishDate && {finishDate: lastDay})})),
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status})),
    ]).pipe(
      mergeMap((res: Cpd[]) => {
        let cpd = res[1];
        return this.updateCpdCancelInfo(cpd.id, reason, extraId);
      }),
      map(() => {
        return cpd;
      })
    );
  }

  firedCpdByExtraId(cpd: Cpd, extraId: number, lastDay: Date, reason?: string): Observable<Cpd> {
    cpd.status = CpdStatus.FIRED;
    return forkJoin([
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, ...(cpd.finishDate && {finishDate: lastDay})})),
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status})),
    ]).pipe(
      mergeMap((res: Cpd[]) => {
        let cpd = res[1];
        return this.updateCpdCancelInfo(cpd.id, reason, extraId);
      }),
      map(() => {
        return cpd;
      })
    );
  }

  finishedReducedCpdByExtraId(cpd: Cpd, extraId: number, lastDay: Date, reason?: string): Observable<Cpd> {
    cpd.status = CpdStatus.FINISHED_REDUCED;
    return forkJoin([
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, ...(cpd.finishDate && {finishDate: lastDay})})),
      this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status})),
    ]).pipe(
      mergeMap((res: Cpd[]) => {
        let cpd = res[1];
        return this.updateCpdCancelInfo(cpd.id, reason, extraId);
      }),
      map(() => {
        return cpd;
      })
    );
  }

  revokeExtraCpdByExtraId(cpd: Cpd, extraId: number, reason?: string): Observable<Cpd> {
    cpd.status = CpdStatus.REVOKE_EXTRA;
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status})).pipe(
      mergeMap((res: Cpd) => {
        let cpd = res;
        return this.updateCpdCancelInfo(cpd.id, reason, extraId);
      }),
      map(() => {
        return cpd;
      })
    );
  }

  revokeBusinessCpdByExtraId(cpd: Cpd, extraId: number, reason?: string): Observable<Cpd> {
    cpd.status = CpdStatus.REVOKE_BUSINESS;
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpd.id, status: cpd.status})).pipe(
      mergeMap((res: Cpd) => {
        let cpd = res;
        return this.updateCpdCancelInfo(cpd.id, reason, extraId);
      }),
      map(() => {
        return cpd;
      })
    );
  }

  getFinishCpdsByExtraId(extraId: number, page?: number, pageSize?: number): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, ExtraApi.getExtraFinishedCpds(extraId, page, pageSize));
  }

  getInProgressCpdsByExtraId(extraId: number, page?: number, pageSize?: number): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, ExtraApi.getExtraInProgressCpds(extraId, page, pageSize));
  }
  getInProgressCpdsByExtraIdCount(extraId: number, page?: number, pageSize?: number): Observable<number> {
    return this.api.apiRequestV2(Entities.Cpd, ExtraApi.countExtraInProgressCpds(extraId, page, pageSize));
  }

  getCpdsReadyToConfirm(extraId, page?: number, pageSize?: number): Observable<Cpd> {
    let date: Date = new Date();
    let date3: Date = new Date();
    date3.setDate(date.getDate() + 3);
    return this.api.apiRequestV2(Entities.Cpd, ExtraApi.getExtraCpdsReadyToConfirm(extraId, date, date3, page, pageSize));
  }

  updateConfirmationHour(cpdId, date?) {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpdId, extraLastConfirmationDate: new Date()}));
  }

  getHiredCpdsByOfferId(
    offerId: number,
    establishmentId?: number,
    page?: number,
    pageSize?: number,
    forceAll?: boolean
  ): Observable<Cpd[]> {
    if (forceAll) {
      return this.api.apiRequestV2(Entities.Cpd, OfferApi.getOnlyHiredCpdsByOfferId(offerId, establishmentId ?? null));
    }

    return this.api.apiRequestV2(Entities.Cpd, OfferApi.getOnlyHiredCpdsByOfferId(offerId, establishmentId ?? null, page, pageSize));
  }
  getAllActiveCpdsByOfferId(
    offerId: number,
    establishmentId?: number,
    page?: number,
    pageSize?: number,
    forceAll?: boolean
  ): Observable<Cpd[]> {
    if (forceAll) {
      return this.api.apiRequestV2(Entities.Cpd, OfferApi.getHiredCpdsByOfferId(offerId, establishmentId ?? null));
    }

    return this.api.apiRequestV2(Entities.Cpd, OfferApi.getHiredCpdsByOfferId(offerId, establishmentId ?? null, page, pageSize));
  }
  getHiredCpdsByEstablishmetnId(establishmentId: number, page?: number, pageSize?: number, forceAll?: boolean): Observable<Cpd[]> {
    if (forceAll) {
      return this.api.apiRequestV2(Entities.Cpd, CpdApi.getHiredCpdsByEstablishmentId(establishmentId));
    }

    return this.api.apiRequestV2(Entities.Cpd, CpdApi.getHiredCpdsByEstablishmentId(establishmentId, page, pageSize));
  }
  getAllACtiveCpdsByEstablishmentId(establishmentId: number, page?: number, pageSize?: number, forceAll?: boolean): Observable<Cpd[]> {
    if (forceAll) {
      return this.api.apiRequestV2(Entities.Cpd, CpdApi.getActiveCpdsByEstablishmentId(establishmentId));
    }

    return this.api.apiRequestV2(Entities.Cpd, CpdApi.getActiveCpdsByEstablishmentId(establishmentId, page, pageSize));
  }

  //-- FIN TODO

  getHiredCpdsByOfferIdCount(offerId: number, page?: number, pageSize?: number): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, OfferApi.countHiredCpdsByOfferId(offerId, page, pageSize));
  }

  getInProgressCpdsByOfferIdCount(offerId: number, page?: number, pageSize?: number, customFiltersArray: string[] = []): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, OfferApi.countInProgressCpdsByOfferId(offerId, page, pageSize));
  }

  getInProgressCpdsByOfferId(offerId: number, page?: number, pageSize?: number, customFiltersArray: string[] = []): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, OfferApi.getInProgressCpdsByOfferId(offerId, page, pageSize));
  }

  getPedingCpds(userId, page?: number, pageSize?: number): Observable<Cpd[]> {
    return this.api.apiRequestV2(Entities.Cpd, ExtraApi.getExtraPendingCpds(userId, page, pageSize));
  }

  succeedCpd(cpdId) {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpdId, status: CpdStatus.SUCCEED}));
  }

  extraReviewCpd(cpdId) {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpdId, hasExtraReview: true})).pipe(
      mergeMap((res: Cpd) => {
        if (res.hasBusinessReview && res.hasExtraReview) {
          return this.succeedCpd(res.id);
        } else {
          return of(true);
        }
      })
    );
  }

  businessReviewCpd(cpdId) {
    return this.api.apiRequestV2(Entities.Cpd, CpdApi.updateCpd({id: cpdId, hasBusinessReview: true})).pipe(
      mergeMap((res: Cpd) => {
        if (res.hasBusinessReview && res.hasExtraReview) {
          return this.succeedCpd(res.id);
        } else {
          return of(true);
        }
      })
    );
  }
}
