import {worktypeRepartidorMotoRequirement} from './worktypeRepartidor.requirement';
import {worktypeCocineroRequirement} from './worktypeCocinero.requirement';
import {worktypeCamareroRequirement} from './worktypeCamarero.requirement';
import {worktypeAyudanteCocinaRequirement} from './worktypeAyudanteCocina.requirement';

export const WORKTYPE_REQUIREMENTS = {
  1: worktypeCamareroRequirement,
  2: worktypeAyudanteCocinaRequirement,
  3: worktypeRepartidorMotoRequirement,
  6: worktypeCocineroRequirement
};
