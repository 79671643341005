import {Component} from '@angular/core';
import {DialogService, NavigationService, StateService} from '~/services';
import {ParamService, UserService} from '@services';
import {UserType} from '@buscoextra/core';
import {Business} from '@buscoextra/core';
import {ModalController} from '@ionic/angular';
import {FormPage} from '@buscoextra/core/services/communications/wrappers/form-page.wrapper';
import {CommunicationsService} from '@buscoextra/core/services';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';

@Component({
  selector: 'my-account-page',
  templateUrl: './my-account.page.html',
  styleUrls: ['./my-account.page.scss'],
})
export class MyAccountPage extends FormPage {
  isDniValid: boolean;
  onlyPhone = false;
  onlyEmail = false;
  public form: FormGroup;
  public isChangePasswordActive = false;

  constructor(
    private userService: UserService,
    private state: StateService,
    private dialog: DialogService,
    private communications: CommunicationsService,
    private navigation: NavigationService,
    private ionicModal: ModalController,
    private formBuilder: FormBuilder,
    private paramService: ParamService
  ) {
    super(communications);
  }

  ngOnInit() {
    this.paramService.isChangePasswordActivated().subscribe(res => {
      this.isChangePasswordActive = res == 'true';
    });

    this.loadMyAccount();
  }

  async loadMyAccount() {
    this.formValue = await this.send(this.userService.getUserById(this.state.userId), 'gettingMyAccount');
    this.createForm(this.formValue);
  }

  async UIonSaveBtnPressed(business: Business) {
    let user = await this.send(this.userService.updateUser(this.state.userId, business, UserType.Business), 'savingProfile');
    if (user !== null) {
      this.state.updateUser(user);
      this.navigation.goback();
      this.dialog.toast('Datos actualizados correctamente');
    }
  }

  dismiss() {
    this.ionicModal.dismiss();
  }

  deleteAccount() {
    this.navigation.goto('business/my-profile/legal/delete-account');
  }

  async UIonChangePasswordButtonClick() {
    const values = await this.dialog.alert(
      'Cambiar contraseña',
      null,
      `Te mandaremos un correo electrónico en ${this.state.user.email} con las instrucciones para cambiar la contraseña actual`,
      [
        {text: 'Cancelar', role: 'cancel'},
        {text: 'Confirmar', role: 'submit'},
      ]
    );

    if (values.role == 'submit') {
      let ressetPassword = await this.send(this.userService.resetPassWord(this.state.user.email, 'changePassword'), 'changePassword');
      if (ressetPassword != null) {
        this.dialog.toast(`Te hemos enviado un email a: ${this.state.user.email}`);
      } else {
        this.dialog.toast(`Ha ocurrido un problema al enviar el email de recuperación de contraseña`);
      }
    }
  }

  createForm(user: Business) {
    this.form = this.formBuilder.group({
      name: new FormControl(user.name, {
        validators: [Validators.minLength(1)],
      }),
      lastName: new FormControl(user.lastName, {
        validators: [Validators.minLength(1)],
      }),
      email: new FormControl(user.email, {
        validators: [Validators.email],
      }),
      phoneNumber: new FormControl(user.phoneNumber, {
        validators: [Validators.pattern('^\\d{9}$')],
      }),
    });
  }
}
