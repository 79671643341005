import {UserType} from '@buscoextra/core';
import {CpdStatus} from '@buscoextra/core';
import {OfferStatus} from '@buscoextra/core';
import {Status} from './models/status';

export function GetStatus(status: string, userType: UserType, isOffer = false): Status {
  switch (status) {
    case CpdStatus.PENDING:
      if (userType === UserType.Business) {
        return !isOffer
          ? {
              msg: 'Esperando confirmación',
              color: 'tertiary'
            }
          : {
              msg: 'En revisión',
              color: 'tertiary'
            };
      }
      return {
        msg: 'Has sido solicitado',
        color: 'basic'
      };
    case CpdStatus.REVOKE_BUSINESS:
      if (userType === UserType.Business) {
        return {
          msg: 'Has cancelado contrato',
          color: 'secondary'
        };
      }
      return {
        msg: 'Contrato cancelado',
        color: 'secondary'
      };
    case CpdStatus.REVOKE_EXTRA:
      if (userType === UserType.Business) {
        return {
          msg: 'Contrato cancelado',
          color: 'secondary'
        };
      }
      return {
        msg: 'Has cancelado contrato',
        color: 'secondary'
      };
    case CpdStatus.CONFIRMED:
      if (userType === UserType.Business) {
        return {
          msg: 'Extra disponible',
          color: 'tertiary'
        };
      }
      return {
        msg: 'Esperando confirmación',
        color: 'tertiary'
      };
    case CpdStatus.PRE_SELECTED:
      if (userType === UserType.Business) {
        return {
          msg: 'Extra disponible',
          color: 'tertiary'
        };
      }
      return {
        msg: 'Esperando confirmación',
        color: 'tertiary'
      };
    case CpdStatus.CANCELLED:
      if (userType === UserType.Business) {
        return {
          msg: 'Pedido cancelado',
          color: 'secondary'
        };
      }
      return {
        msg: 'Oferta retirada',
        color: 'basic'
      };
    case CpdStatus.HIRED:
      if (userType === UserType.Business) {
        return {
          msg: 'Contratado',
          color: 'primary'
        };
      }
      return {
        msg: 'Has sido contratado',
        color: 'primary'
      };
    case CpdStatus.SUCCEED:
      if (userType === UserType.Business) {
        return {
          msg: 'Finalizado',
          color: 'primary'
        };
      }
      return {
        msg: 'Finalizado',
        color: 'primary'
      };
    case CpdStatus.FINISHED:
      if (userType === UserType.Business) {
        return {
          msg: 'Finalizado',
          color: 'primary'
        };
      }
      return {
        msg: 'Finalizado',
        color: 'primary'
      };
    case CpdStatus.FINISHED_REDUCED:
      if (userType === UserType.Business) {
        return {
          msg: 'Contrato finalizado',
          color: 'primary'
        };
      }
      return {
        msg: 'Contrato finalizado',
        color: 'primary'
      };
    case CpdStatus.APPLIED:
      if (userType === UserType.Business) {
        return {
          msg: 'Inscrito',
          color: 'primary'
        };
      }
      return {
        msg: 'Oferta solicitada',
        color: 'primary'
      };
    case CpdStatus.FIRED:
      if (userType === UserType.Business) {
        return {
          msg: 'Has cancelado contrato',
          color: 'secondary'
        };
      }
      return {
        msg: 'Contrato cancelado',
        color: 'secondary'
      };
    case CpdStatus.RESIGNED:
      if (userType === UserType.Business) {
        return {
          msg: 'Contrato cancelado',
          color: 'secondary'
        };
      }
      return {
        msg: 'Has cancelado contrato',
        color: 'secondary'
      };
    case CpdStatus.REJECTED:
      if (userType === UserType.Business) {
        return {
          msg: 'No disponible',
          color: 'secondary'
        };
      }
      return {
        msg: 'Cancelado',
        color: 'secondary'
      };
    case OfferStatus.PUBLIC:
      if (userType === UserType.Business) {
        return {
          msg: 'En proceso',
          color: 'primary'
        };
      }
      return {
        msg: 'Oferta púlbica',
        color: 'primary'
      };
    case OfferStatus.PRIVATE:
      if (userType === UserType.Business) {
        return {
          msg: 'Oferta privada',
          color: 'basic'
        };
      }
      return {
        msg: 'Oferta privada',
        color: 'basic'
      };
    case OfferStatus.COMPLETED:
      if (userType === UserType.Business) {
        return {
          msg: 'Oferta completada',
          color: 'basic'
        };
      }
      return {
        msg: 'Oferta completa',
        color: 'basic'
      };
    default:
      return {
        msg: status,
        color: 'grey'
      };
  }
}
