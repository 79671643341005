import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExtraApplySetupPage} from './extra-apply-setup.page';
import {ExtraApplySetupRoutingModule} from './extra-apply-setup.routing';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExtraServiceModule} from '@buscoextra/services/user/extra/extra.module';
import {BeLoadingComponentModule} from '@components';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    BeLoadingComponentModule,
    ExtraServiceModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ExtraApplySetupRoutingModule,

    BeInputValueAccessorModule
  ],
  exports: [ExtraApplySetupPage],
  declarations: [ExtraApplySetupPage]
})
export class ExtraApplySetupPageModule {}
