import {NgModule} from '@angular/core';
import {NavigationServiceModule} from './navigation/navigation-service.module';
import {DialogServiceModule} from './dialog/dialog-service.module';
import {NotificationServiceModule} from './notification/nontification-service.module';
import {AnalyticsServiceModule} from './analytics/analytics-service.module';
import {TranslationServiceModule} from './translation/translation-service.module';
import {DeviceServiceModule} from './device/device-service.module';
import {GeolocationServiceModule} from './geolocation/geolocation-service.module';
import {DeepLinkServiceModule} from './deeplink/deeplink-service.module';
import {ModalsServiceModule} from './modals/modals.module';
import {AuthServiceModule} from '@buscoextra/core/services/auth/auth-service.module';
import {CommunicationsServiceModule} from '@buscoextra/core/services/communications/communications-service.module';
import {StateServiceModule} from './state';
import {LocationModalPageModule} from '~/pages/location-modal-page/location-modal-page.module';
import {FileManagerModule} from './fileManager/file-manager.module';

@NgModule({
  imports: [
    NavigationServiceModule,
    CommunicationsServiceModule,
    DialogServiceModule,
    AuthServiceModule,
    ModalsServiceModule,
    StateServiceModule,
    NotificationServiceModule,
    AnalyticsServiceModule,
    TranslationServiceModule,
    DeviceServiceModule,
    GeolocationServiceModule,
    DeepLinkServiceModule,
    LocationModalPageModule,
    FileManagerModule
  ]
})
export class AppServicesModule {}
