import {Component, OnInit, Input} from '@angular/core';
import {Cpd, Contact, ContactApi, Entities} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-establishment-contact-modal',
  templateUrl: './establishment-contact-modal.component.html',
  styleUrls: ['./establishment-contact-modal.component.scss']
})
export class EstablishmentContactModalComponent extends AsyncPage implements OnInit {
  @Input() cpd: Cpd;
  contacts: Contact[];
  constructor(private apiClient: ApiService, comunication: CommunicationsService, private ionModal: ModalController) {
    super(comunication);
  }

  ngOnInit() {
    if (this.cpd && this.cpd.id) {
      this.getContacts();
    }
  }

  async getContacts() {
    this.contacts = await this.send(
      this.apiClient.apiRequestV2(
        Entities.Contact,
        ContactApi.getContactByEntityAndEntityId('establishment', this.cpd.establishment.id, 0, 25, '&type=extraEstablishmentContact')
      )
    );
  }

  close() {
    this.ionModal.dismiss();
  }
}
