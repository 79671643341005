export const CompanyForServer = (clientCompany: any, seletedFields?: string[]) => {
  delete clientCompany.id;
  if (clientCompany.billLocation) {
    delete clientCompany.billLocation.id;
  }
  delete clientCompany.owner;
  delete clientCompany.verified;

  if (seletedFields && seletedFields.length > 0) {
    let resClientCompany = {};
    seletedFields.forEach(field => {
      resClientCompany[field] = clientCompany[field];
    });
    return resClientCompany;
  }

  return clientCompany;
};

export const CompanyForClient = serverCompany => {
  delete serverCompany.createdAt;
  return serverCompany;
};
