import {isValidIBAN, isValidNif, isValidSS, isValidNie, isValidCif, isValidCCC} from '@buscoextra/core';
import {FormControl} from '@angular/forms';

export function validatorIban(control: FormControl): {[s: string]: boolean} {
  if (control.value === null || undefined || '' || control.value.length === 0 || isValidIBAN(control.value)) {
    return null;
  } else {
    return {invalidIban: true};
  }
}
export function validatorNif(control: FormControl): {[s: string]: boolean} {
  if (control.value === null || undefined || '' || control.value.length === 0 || isValidNif(control.value) || isValidNie(control.value)) {
    return null;
  } else {
    return {invalidNif: true};
  }
}
export function validatorSS(control: FormControl): {[s: string]: boolean} {
  if (control.value === null || undefined || '' || control.value.length === 0 || isValidSS(control.value)) {
    return null;
  } else {
    return {invalidSS: true};
  }
}
export function validatorCCC(control: FormControl): {[s: string]: boolean} {
  if (control.value === null || undefined || '' || control.value.length === 0 || isValidCCC(control.value)) {
    return null;
  } else {
    return {invalidCCC: true};
  }
}
export function validatorCif(control: FormControl): {[s: string]: boolean} {
  if (control.value === null || undefined || '' || control.value.length === 0 || isValidCif(control.value)) {
    return null;
  } else {
    return {invalidSS: true};
  }
}
export function validatorCifORDni(control: FormControl): {[s: string]: boolean} {
  if (
    control.value === null ||
    undefined ||
    '' ||
    control.value.length === 0 ||
    isValidCif(control.value) ||
    isValidNif(control.value) ||
    isValidNie(control.value)
  ) {
    return null;
  } else {
    return {invalidSS: true};
  }
}
export function validatorLocation(control: FormControl): {[s: string]: boolean} {
  if (control.value && control.value.formattedAddress) {
    return null;
  } else {
    return {invalidLocation: true};
  }
}
