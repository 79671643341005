import {Requirement} from '@buscoextra/core';
import {Business} from '@buscoextra/core';
import {exists} from '../tools/operations';

export const businessCreateOfferRequirements: Requirement[] = [
  {
    id: 'email',
    title: 'No has indicado un email válido',
    rule: (business: Business): boolean => {
      if (!business.email) return true;
      return exists(business.email) && business.fbId !== business.email.split('@')[0];
    }
  },
  {
    id: 'business_name',
    title: 'No has indicado un nombre',
    rule: (business: Business): boolean => {
      return exists(business.name);
    }
  },
  {
    id: 'business_lastName',
    title: 'No has indicado un apellid',
    rule: (business: Business): boolean => {
      return exists(business.lastName);
    }
  },
  {
    id: 'phone',
    title: 'No has indicado un número de teléfono',
    rule: (business: Business): boolean => {
      return exists(business.phoneNumber);
    }
  }
];
