import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CalendarPickerComponent } from './calendar-picker.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule],
  exports: [CalendarPickerComponent],
  declarations: [CalendarPickerComponent],
  providers: []
})
export class CalendarPickerComponentModule { }
