import {Injectable} from '@angular/core';
import {Extra, BeLocation, Offer, Resource, WorkTypeChosen, ExtraApi, WorkTypeChosenApi, OfferApi, CpdApi} from '@buscoextra/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {User} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {WorktypeService} from '@services';
import {CpdStatus} from '@buscoextra/core';
import {ResourceService, ResourceTags} from '@buscoextra/services/resource/resource.service';
import {ExtraApplyOfferRequirementInputs} from '@buscoextra/services/requirement/requiriments/extraApplyOffer.requirement';
import {Skill} from '@buscoextra/core';
import {ApiService} from '@buscoextra/core/services/api';

export interface IRPFPersonalData {
  civilStatus: number;
  hasChildren: number;
  finishedStudies: number;
}

export const StudyLevel = [
  {id: 0, name: 'Sin estudios / estudios primarios'},
  {id: 1, name: 'Estudios secundarios'},
  {id: 2, name: 'Estudios superiores'}
];

export const CivilStatus = [
  {id: 0, name: 'Soltero/a'},
  {id: 1, name: 'Comprometido/a'},
  {id: 2, name: 'Casado/a'},
  {id: 3, name: 'Divorciado/a'},
  {id: 4, name: 'Viudo/a'}
];

@Injectable()
export class ExtraService {
  constructor(private api: ApiService, private workTypeService: WorktypeService, private resourceService: ResourceService) {}

  getById(extraId: number): Observable<Extra> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.getExtraById(extraId));
  }

  isReadyToApply(extra: Extra): Observable<boolean> {
    return of(
      extra
        ? !(
            !extra.iban ||
            !extra.civilStatus ||
            !extra.finishedStudies ||
            extra.hasChildren === undefined ||
            !extra.socialSecurity ||
            !extra.verifiedProfile
          )
        : false
    );
  }

  isReadyToApplyByOffer(extra: Extra, offer: Offer): Observable<boolean> {
    return this.workTypeService.getChoosenByExtraId(extra.id).pipe(
      map((res: WorkTypeChosen[]) => {
        return res.some(wtC => {
          return wtC.workType.id === offer.workType.id;
        });
      })
    );
  }

  saveNumberSeguridadSocial(extraId: number, numSeguridadSocial: string): Observable<string> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: extraId, socialSecurity: numSeguridadSocial} as any));
  }

  saveNumberCuentaBancaria(extraId: number, numCuentaBancaria: string): Observable<string> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: extraId, iban: numCuentaBancaria} as any));
  }

  saveIRPFPersonalData(extraId: number, IRPFPersonalData: IRPFPersonalData): Observable<Extra> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: extraId, ...IRPFPersonalData} as any));
  }

  saveExtraLocation(extraId: number, location: BeLocation, workingRadius: number): Observable<User> {
    let data = {
      latitude: location.latitude,
      longitude: location.longitude,
      residence: location,
      workingRadius: workingRadius
    };

    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: extraId, ...data} as any));
  }

  saveExtraWorktypes(extraId: number, worktypesChosen: WorkTypeChosen[] = []): Observable<boolean> {
    let auxRequest = [];
    worktypesChosen.forEach(wTc => {
      auxRequest.push(
        wTc.id
          ? this.api.apiRequestV2(Entities.WorkTypeChosen, WorkTypeChosenApi.updateWorkTypeChosen(wTc as any))
          : this.api.apiRequestV2(Entities.WorkTypeChosen, WorkTypeChosenApi.createWorkTypeChosen(wTc as any))
      );
    });
    return forkJoin(auxRequest).pipe(
      map(res => {
        return true;
      })
    );
  }

  saveSkills(extraId, selectedSkills: Skill[]): Observable<User> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: extraId, skills: selectedSkills} as any));
  }

  isLocationSettedByExtraId(extra: Extra): Observable<boolean> {
    return this.getById(extra.id).pipe(
      map((extra: Extra) => {
        return (
          extra.latitude !== undefined &&
          extra.longitude !== undefined &&
          extra.workingRadius !== undefined &&
          extra.residence.formattedAddress !== undefined
        );
      })
    );
  }

  isWorktypesSettedByExtraId(extra: Extra): Observable<boolean> {
    return this.workTypeService.getChoosenByExtraId(extra.id).pipe(
      map((wtC: WorkTypeChosen[]) => {
        return wtC && wtC.length > 0;
      })
    );
  }

  confirmOfferApplyment(offerId: number, extraId: number): Observable<boolean> {
    return this.api.apiRequestV2(Entities.Offer, OfferApi.getOfferById(offerId)).pipe(
      mergeMap((offer: Offer) => {
        const entityData = {
          extra: extraId,
          business: offer.business.id,
          offer: offer.id,
          status: CpdStatus.APPLIED,
          extraLastConfirmationDate: new Date()
        };
        return this.api.apiRequestV2(Entities.Cpd, CpdApi.createCpd(entityData));
      }),
      map(() => {
        return true;
      })
    );
  }

  getFrontDriveLicense(requirementInput: ExtraApplyOfferRequirementInputs): Observable<Resource> {
    return this.resourceService.getUserResourceUrlByTag(requirementInput.extra.id, ResourceTags.DRIVER_LICENSE_FRONT);
  }

  getBackDriveLicense(requirementInput: ExtraApplyOfferRequirementInputs): Observable<Resource> {
    return this.resourceService.getUserResourceUrlByTag(requirementInput.extra.id, ResourceTags.DRIVER_LICENSE_BACK);
  }
}
