import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CivilStatus, ExtraService, IRPFPersonalData, StudyLevel} from '@buscoextra/services/user/extra/extra.service';
import {isValidIBAN, isValidSS, stringNormalizer} from '@buscoextra/core';
import {Extra, Company} from '@buscoextra/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, NavigationService, StateService} from '~/services';
import {Stepper} from '~/models/stepper';
import {Step} from '~/models/step';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {validatorSS, validatorIban} from '~/validators/validators.validator';
import {AnalyticsService} from '~/services/analytics/analytics.service';

@Component({
  selector: 'extra-apply-setup-page',
  templateUrl: './extra-apply-setup.page.html',
  styleUrls: ['./extra-apply-setup.page.scss']
})
export class ExtraApplySetupPage extends AsyncPage {
  IRPFPersonalData: IRPFPersonalData = {
    civilStatus: +(this.state.user as Extra).civilStatus,
    hasChildren: (this.state.user as Extra).hasChildren ? 1 : undefined,
    finishedStudies: +(this.state.user as Extra).finishedStudies
  } as IRPFPersonalData;

  civilStatusOptions = [
    {id: 0, name: 'Soltero/a'},
    {id: 1, name: 'Comprometido/a'},
    {id: 2, name: 'Casado/a'},
    {id: 3, name: 'Divorciado/a'},
    {id: 4, name: 'Viudo/a'}
  ];
  hasChildrenOptions = [
    {
      id: 0,
      name: 'No'
    },
    {
      id: 1,
      name: 'Sí'
    }
  ];
  studiesOptions = StudyLevel;

  civilStatusSelected;
  hasChildrenSelected;
  finishedStudiesSelected;
  formValue = {} as any;

  public formsKeys = ['SSForm', 'IBANForm', 'IRPFForm'];
  public SSForm: FormGroup;
  public IBANForm: FormGroup;
  public IRPFForm: FormGroup;

  sectionsTitles = ['Alta Seguridad Social', 'Cuenta Bancaria', 'Retención IRPF'];

  activeIndex = 0;
  cantStepNext = false;
  isFirstStep: boolean;
  isLastStep: boolean;

  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private ionicModal: ModalController,
    private extraService: ExtraService,
    private state: StateService,
    private dialog: DialogService,
    private formBuilder: FormBuilder
  ) {
    super(communications);
  }

  async ionViewWillEnter() {
    this.activeIndex = 0;
    this.cantStepNext = false;
    this.onSlideChange();
    this.initSSForm();
    this.initIBANForm();
    this.initIRPFForm();
  }

  getProgressValue() {
    return this.activeIndex / 3 + 1 / 3;
  }

  async onSlideChange() {
    this.isFirstStep = this.activeIndex == 0;
  }

  async onCuentaBancaria() {
    let alertResult = await this.dialog.alert('Para poder aceptar este IBAN', 'Confirma que eres el titular de esta cuenta', undefined, [
      {text: 'Cancelar', role: 'cancel'},
      {text: 'Confirmo', role: 'confirm'}
    ]);

    if (alertResult.role != 'confirm') {
      this.formValue.numCuentaBancaria = undefined;
    } else {
      this.formValue.numCuentaBancaria = stringNormalizer(this.formValue.numCuentaBancaria);
      let extraUpdated = await this.send(
        this.extraService.saveNumberCuentaBancaria(this.state.userId, this.formValue.numCuentaBancaria),
        'savingCuentaBancaria'
      );
      this.cantStepNext = false;
      if (extraUpdated !== null) {
        this.state.updateUser(extraUpdated);
      }
    }
  }

  async UIonFinishBtnPressed() {
    let extraUpdated = await this.send(
      this.extraService.saveIRPFPersonalData(this.state.userId, this.formValue.IRPFPersonalData),
      'savingIRPFdata'
    );
    if (extraUpdated !== null) {
      this.state.updateUser(extraUpdated);
      this.ionicModal.dismiss(true);
    }
  }

  isValidPersonalData(value: IRPFPersonalData = this.formValue.IRPFPersonalData) {
    return value.civilStatus != undefined && value.hasChildren != undefined && value.finishedStudies != undefined;
  }

  UIonStepBtnPressed(index: number) {
    this.slideTo(index);
  }

  UIonNextBtnPressed() {
    this.nextStep();
  }

  UIonLaterBtnPressed() {
    this.ionicModal.dismiss(false);
  }

  UIonBackBtnPressed() {
    this.previusStep();
  }

  nextStep() {
    if (this.activeIndex + 1) {
      this.activeIndex += 1;
      this.onSlideChange();
    }
  }
  previusStep() {
    if (this.activeIndex - 1 >= 0) {
      this.activeIndex -= 1;
      this.onSlideChange();
    }
  }

  slideTo(index) {
    if (this.cantStepNext) return;
    this.activeIndex = index;
    this.onSlideChange();
  }

  initSSForm() {
    this.SSForm = this.formBuilder.group({
      SS: new FormControl((this.state.user as Extra).socialSecurity, {
        validators: [Validators.required, validatorSS]
      })
    });
  }
  initIBANForm() {
    this.IBANForm = this.formBuilder.group({
      IBAN: new FormControl((this.state.user as Extra).iban, {
        validators: [Validators.required, validatorIban]
      })
    });
  }
  initIRPFForm() {
    this.IRPFForm = this.formBuilder.group({
      civilStatus: new FormControl(this.IRPFPersonalData.civilStatus, {
        validators: [Validators.required]
      }),
      hasChildren: new FormControl(this.IRPFPersonalData.hasChildren, {
        validators: [Validators.required]
      }),
      finishedStudies: new FormControl(this.IRPFPersonalData.finishedStudies, {
        validators: [Validators.required]
      })
    });

    this.civilStatusSelected = this.getSelected(this.civilStatusOptions, this.IRPFForm.get('civilStatus').value, 'id');
    this.hasChildrenSelected = this.getSelected(this.hasChildrenOptions, this.IRPFForm.get('hasChildren').value, 'id');
    this.finishedStudiesSelected = this.getSelected(this.studiesOptions, this.IRPFForm.get('finishedStudies').value, 'id');
  }

  ibanBlurred() {
    if (this.IBANForm.get('IBAN').value && this.IBANForm.get('IBAN').valid && this.IBANForm.get('IBAN').value.length > 0) {
      this.dialog
        .alert(
          'Titular número de cuenta',
          '¿Confirmas que eres el titular de este número de cuenta?',
          'Para poder actualizar tu número de cuenta bancaria necesitamos que confirmes que eres el titular de la cuenta ' +
            this.IBANForm.get('IBAN').value,
          [
            {text: 'Soy el titular', role: 'accept'},
            {text: 'Cancelar', role: 'cancel'}
          ]
        )
        .then(res => {
          if (res.role != 'accept') {
            this.IBANForm.get('IBAN').setValue(undefined);
          }
        });
    }
  }

  getOptions(arr) {
    if (arr && arr.length > 0) return arr.map(res => res.name);
    return [];
  }

  getSelected(arr, value, key?) {
    if (!value) return;
    if (key) {
      const index = arr.findIndex(res => res[key] === value);
      return index != -1 ? index : undefined;
    }
    return arr.findIndex(res => res === value);
  }

  setSelected(ev, arr, formKey, key?) {
    if (key) this.IRPFForm.controls[formKey].setValue(arr[ev.detail][key]);
    else this.IRPFForm.controls[formKey].setValue(arr[ev.detail]);

    this.unPristineForm();
  }

  unPristineForm() {
    if (!this.IRPFForm) return;
    this.IRPFForm.markAsDirty();
  }

  saveSSForm() {
    return this.send(this.extraService.saveNumberSeguridadSocial(this.state.user.id, this.SSForm.value.SS), 'savingSS');
  }
  saveIBANForm() {
    return this.send(this.extraService.saveNumberCuentaBancaria(this.state.user.id, this.IBANForm.value.IBAN), 'savingIBAN');
  }
  saveIRPFForm() {
    return this.send(this.extraService.saveIRPFPersonalData(this.state.user.id, this.IRPFForm.value), 'savingIRPF');
  }

  async UIonSaveBtnPressed() {
    switch (this.activeIndex) {
      case 0:
        if (!this.SSForm.touched) {
          this.nextStep();
          break;
        }
        let result = await this.saveSSForm();
        if (result) {
          this.state.updateUser(result);
          this.nextStep();
        }

        break;
      case 1:
        if (!this.IBANForm.touched) {
          this.nextStep();
          break;
        }
        let result2 = await this.saveIBANForm();
        if (result2) {
          this.state.updateUser(result2);
          this.nextStep();
        }
        break;
      case 2:
        if (!this.IRPFForm.touched) {
          this.ionicModal.dismiss(true);
          break;
        }
        let result3 = await this.saveIRPFForm();
        if (result3) {
          this.state.updateUser(result3);
          this.ionicModal.dismiss(true);
        }
        break;
    }
  }
}
