import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {SignatureService} from '@buscoextra/services/signature/signature.service';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, FileManagerService} from '~/services';
import {Signature, Resource} from '@buscoextra/core';
import {DeviceService} from '~/services/device/device.service';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {ResourceService} from '@buscoextra/services/resource/resource.service';
import {environment} from '~environment';
import {of} from 'rxjs';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent extends AsyncPage implements OnInit {
  @Input() phone = 'XXXXXXXXX';
  @Input() documentId;
  @Input() documentEntities;
  @Output('onChange') changeEmitter: EventEmitter<string> = new EventEmitter();

  @Input() documentSigned = false;
  @Input() documentSignedSucced = true;
  code;
  MAX_OTP_TRIES = 4;
  codeSended = false;
  signatureStatus: Signature;
  constructor(
    private modalCtrl: ModalController,
    private signature: SignatureService,
    private dialog: DialogService,
    comunicationService: CommunicationsService,
    private fileManager: FileManagerService,
    private device: DeviceService,
    private fileOpener: FileOpener,
    private resourceService: ResourceService
  ) {
    super(comunicationService);
  }

  ngOnInit() {}

  otpValueChange(event) {
    this.code = event.detail;
  }

  async getCode(ev) {
    ev.stopPropagation();
    const status = await this.send(this.signature.requestOTPSign(this.documentId), 'requestingCode');
    if (status || environment.addressName != 'PROD') {
      this.codeSended = true;
      return;
    }
    this.exitWithError();
  }

  async sign(ev) {
    ev.stopPropagation();
    if (this.code) {
      const signature: Signature[] = await this.send(this.signature.getSignatureResource(this.documentId, this.documentEntities));
      if (!signature || signature.length == 0) {
        this.exitWithError();
        return;
      }
      this.signatureStatus = signature[0];
      const signStatus = await this.send(this.signature.signOTP(signature[0].id, {otp: this.code}), 'singin');
      if (signStatus) {
        const traceStatus = await this.send(this.signature.finishOTP(signature[0].id), 'singin');
        if (traceStatus) {
          this.documentSigned = true;
          this.documentSignedSucced = signStatus;
          return;
        }
        this.exitWithError();
        return;
      }
      this.documentSigned = true;
      this.documentSignedSucced = signStatus;
    }
  }

  exitWithError() {
    this.dialog.alert('Se ha producido un error', 'Intente firmar el documento de nuevo más tarde');
    this.modalCtrl.dismiss();
  }
  exit(ev) {
    if (this.documentSignedSucced && this.documentSigned) {
      this.modalCtrl.dismiss(this.documentSignedSucced);
      return;
    }

    if (this.MAX_OTP_TRIES - (this.signatureStatus.otpTries + 1) > 0 && new Date(this.signatureStatus.otpExpiredAt) > new Date()) {
      this.documentSigned = false;
      this.code = undefined;
      return;
    }
    if (this.MAX_OTP_TRIES - (this.signatureStatus.otpTries + 1) <= 0) {
      this.dialog.alert('Has superado el número maximo de intentos', 'Puedes volver a solicitar un nuevo código');
    } else if (new Date(this.signatureStatus.otpExpiredAt) < new Date()) {
      this.dialog.alert('Tu tiempo de firma ha expirado', 'Puedes volver a solicitar un nuevo código');
    }

    ev.stopPropagation();
    this.modalCtrl.dismiss(this.documentSignedSucced);
  }
  dismiss(ev) {
    if (ev.target.className.indexOf('main-container') == -1) return;
    ev.stopPropagation();
    this.modalCtrl.dismiss();
  }

  didExpire() {
    return this.signatureStatus && new Date(this.signatureStatus.otpExpiredAt) < new Date();
  }

  async downloadResource() {
    const resource: Resource = await this.send(this.resourceService.getResourceById(this.documentId));
    if (!resource) return;
    if (this.device.isCordova()) {
      this.isLoading[`download - ${resource.id}`] = true;
      this.fileManager
        .download(resource.url, resource.alias || resource.name, resource.extension)
        .then(res => {
          this.isLoading[`download - ${resource.id}`] = false;
          this.fileOpener.open(res.toURL(), 'application/pdf');
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      await this.send(this.resourceService.downloadResource(resource), `download-${resource.id}`);
    }
  }
}
