import {Observable} from 'rxjs';
import {CommunicationsService} from '../communications.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SubSink} from 'subsink';
import {Component} from '@angular/core';

@Component({
  template: '',
})
export class AsyncPage {
  constructor(private communication: CommunicationsService) {}

  isLoading: {[key: string]: boolean} = {};
  errors: {[key: string]: HttpErrorResponse} = {};

  private subs = new SubSink();

  isAnyLoading(): boolean {
    return Object.keys(this.isLoading).some(loadingKey => {
      return this.isLoading[loadingKey] === true;
    });
  }

  async send(observable: Observable<any>, key?: string): Promise<any | null> {
    this.isLoading[key] = true;
    return new Promise(async resolve => {
      this.subs.add(
        this.communication.getObservable(observable, key).subscribe(
          res => {
            this.isLoading[key] = false;
            resolve(res);
          },
          err => {
            console.log('Async page wrapper err', JSON.stringify(err), key);
            this.isLoading[key] = false;
            this.errors[key] = err;
            resolve(null);
          }
        )
      );
    });
  }

  sendPromise(promise: Promise<any>, key?: string): Promise<any | null> {
    this.isLoading[key] = true;
    return new Promise(async resolve => {
      promise.then(res => {
        resolve(res);
        this.isLoading[key] = false;
      });
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
