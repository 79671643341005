import {Component, Input} from '@angular/core';
import {ModalsService} from '~/services/modals/modals.service';
import {IdCardPage} from '../my-profile-page/pages/extra-work-profile-page/pages/work-types-requirements-page/pages/work-types-requirements-detail-page/pages/id-card-page/id-card.page';
import {LoadingController, ModalController} from '@ionic/angular';
import {UserService} from '@services';
import {RequirementResponse, RequirementState} from '@buscoextra/core';
import {RequirementService} from '@buscoextra/services/requirement/requirement.service';
import {UserType} from '@buscoextra/core';
import {DNIBackScrapper, Extra} from '@buscoextra/core';
import {ResourceService} from '@buscoextra/services/resource/resource.service';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, NavigationService, StateService} from '~/services';
import {DeviceService} from '~/services/device/device.service';
import {AnalyticsService} from '~/services/analytics/analytics.service';
import {ExtraLifeCycleEvents} from '~/services/analytics/events/extraLifeCycleEvents';

@Component({
  selector: 'extra-setup-identity-page',
  templateUrl: './extra-setup-identity.page.html',
  styleUrls: ['./extra-setup-identity.page.scss']
})
export class ExtraSetupIdentityPage extends AsyncPage {
  currentExtra: Extra = {} as Extra;

  userPhone: string = this.state.user.phoneNumber;
  userEmail: string;
  userVerifiedResponse: RequirementResponse;

  isdniFrontValid = true;
  isdniBackValid = true;
  isphoneValid = true;
  isemailValid = true;

  dniFront;
  dniBack;

  isCordova = false;
  @Input() isModal = false;

  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private userService: UserService,
    private ionicModal: ModalController,
    private modalService: ModalsService,
    private requirement: RequirementService,
    private dialog: DialogService,
    public loadingController: LoadingController,
    private resource: ResourceService,
    private device: DeviceService,
    private analytics: AnalyticsService
  ) {
    super(communications);
  }

  ngOnInit() {
    this.isCordova = this.device.isCordova();
    this.currentExtra = {id: this.state.user.id, email: this.state.user.email, phoneNumber: this.state.user.phoneNumber} as Extra;
    if (!this.currentExtra.phoneNumber) this.isphoneValid = false;
    this.userEmail = this.currentExtra.fbId !== this.currentExtra.email.split('@')[0] ? this.currentExtra.email : '';
    this.isUserVerified();
  }

  async isUserVerified() {
    this.isLoading['checking'] = true;
    this.onUserChecked(await this.requirement.isUserIdentityVerified(this.currentExtra));
    this.isLoading['checking'] = false;
  }

  private onUserChecked(response: RequirementResponse) {
    if (response && response.status === RequirementState.Success) {
      this.dialog.toast('Estás listo!');
      this.UIonCloseModalBtnPressed();
    } else {
      response.failedRequirements.forEach(requirement => {
        this[`is${requirement.id}Valid`] = false;
        this.dialog.toast(requirement.title);
      });
    }
  }

  async UIonCloseModalBtnPressed() {
    this.ionicModal.dismiss();
  }

  async UIonDniFrontBtnPressed() {
    this.dniFront = await this.modalService.launchPage(IdCardPage);
  }
  async UIonDniBackBtnPressed() {
    this.dniBack = await this.modalService.launchPage(IdCardPage);
  }

  onDniFrontChange(imageValue) {
    this.dniFront = imageValue;
  }
  onDniBackChange(imageValue) {
    this.dniBack = imageValue;
  }

  async UIonUpdateBtnPressed() {
    let updatedUser = await this.send(
      this.userService.updateUser(this.currentExtra.id, {...this.currentExtra}, UserType.Extra),
      'updatingUser'
    );

    if (this.dniFront) {
      await this.send(this.userService.uploadDniFrontResource(this.dniFront, this.currentExtra.id, undefined), 'uploadingDniFront');
    }

    if (this.dniBack) {
      let loading = await this.loadingController.create({
        message: 'Procesando documento'
      });
      loading.present();
      let dataReceived: DNIBackScrapper = await this.send(this.resource.processDNIBack(this.dniBack));
      if (dataReceived && dataReceived.validateDni) {
        this.state.updateUser(
          await this.send(this.userService.updateUserFromDNIScrapper(this.state.userId, dataReceived, this.state.userType))
        );
      }
      loading.dismiss();
      await this.send(this.userService.uploadDniBackResource(this.dniBack, this.currentExtra.id, undefined), 'uploadingDniBack');
    }

    if (updatedUser) {
      this.state.updateUser(updatedUser);

      if (
        (await this.sendPromise(this.requirement.isUserIdentityVerified(this.state.user), 'checkIfIsReady')).status ===
        RequirementState.Success
      ) {
        this.state.updateUser(await this.send(this.userService.extraIsReadyToVerify(this.state.userId), 'readyToVerify'));
        this.analytics.trackEvent(ExtraLifeCycleEvents.ReadyToVerify());

        this.dialog.alert('Datos enviados con éxito estamos verificando tu perfil');
        this.ionicModal.dismiss();
      } else {
        this.dialog.toast('No has subido todos los datos.');
        this.ionicModal.dismiss();
      }
    }
    this.ionicModal.dismiss();
  }
}
