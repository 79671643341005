import {NgModule} from '@angular/core';
import {WorktypeService} from './worktype.service';
import {ResourceServiceModule} from '../resource/resource-service.module';
import {UserServiceModule} from '../user/user-service.module';

@NgModule({
  imports: [ResourceServiceModule, UserServiceModule],
  providers: [WorktypeService]
})
export class WorktypeServiceModule {}
