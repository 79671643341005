import {Injectable} from '@angular/core';
import {CustomError} from '../../models/custom-error';
import {DialogService} from '~/services';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentErrorsService {
  constructor(private dialog: DialogService) {}

  onGetError(error: CustomError) {
    if (error.httpError.status === 403) {
      this.dialog.toast('No tienes permiso para ver los establecimientos');
    }
  }
}
