import {Component, Input} from '@angular/core';
import {InputType} from '~/services/dialog/models/alert';
import {Report, UserService, ParamService} from '@services';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, StateService} from '~/services';
import {AnalyticsService} from '~/services/analytics/analytics.service';
import {DEFAULT_SUPPORT_PHONE} from './contact-us.setting';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends AsyncPage {
  @Input() contentView: string;
  supportPhone = DEFAULT_SUPPORT_PHONE;

  constructor(
    private dialog: DialogService,
    private state: StateService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private paramService: ParamService,
    communication: CommunicationsService
  ) {
    super(communication);
  }
  userPhone: string;

  async ngOnInit() {
    this.userPhone = this.getUserPhone();
    this.supportPhone = (await this.send(this.paramService.getSupportPhone())) || DEFAULT_SUPPORT_PHONE;
  }

  async UIonDialogBtnPressed() {
    const option = await this.dialog.alert(
      '¿Necesitas ayuda?',
      'En BuscoExtra te acompañamos durante el proceso',
      'Llámanos o envíanos un email y te atenderemos encantados',
      [
        {text: 'Escríbenos', role: 'message'},
        {text: 'Llámanos (' + this.supportPhone + ')', role: 'phone'},
        {text: 'Cancelar', role: 'cancel'}
      ]
    );

    this.onSelectDialog(option.role);
  }

  private async onSelectDialog(method: string) {
    switch (method) {
      case 'phone':
        {
          window.location.href = `tel:${this.supportPhone}`;
        }
        break;
      case 'message':
        const userMessage = await this.UIonSendReportMessageBtnPressed();
        if (!userMessage) return;
        this.DOsendReport({message: userMessage});
        break;

      default:
        break;
    }
  }

  async UIonCalleMeBtnPressed(): Promise<any> {
    return new Promise(async resolve => {
      const dialog = await this.dialog.form({
        title: '¿Cuál es tu teléfono?',
        inputs: [
          {
            name: 'phone',
            placeholder: 'Teléfono',
            type: InputType.text
          }
        ]
      });
      resolve(dialog['phone']);
    });
  }

  async UIonSendReportMessageBtnPressed(): Promise<any> {
    return new Promise(async resolve => {
      const dialog = await this.dialog.form({
        title: 'Envíanos un mensaje',
        inputs: [
          {
            name: 'message',
            placeholder: 'Mensaje',
            type: InputType.text
          }
        ]
      });
      if (!dialog) resolve(undefined);
      resolve(dialog['message']);
    });
  }

  private async DOsendReport(report: Report) {
    report.email = this.getUserEmail();
    report.userId = this.getUserID();
    if ((await this.send(this.userService.sendReport(report), 'sendingReport')) !== null) {
      this.dialog.toast('¡Muchas gracias! Nos pondremos en contacto contigo');
    }
  }

  private getUserPhone(): string {
    return this.state.user.phoneNumber;
  }

  private getUserEmail(): string {
    return this.state.user.email;
  }

  private getUserID(): number {
    return this.state.userId;
  }
}
