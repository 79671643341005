export interface Alert {
  title: string;
  subtitle?: string;
  message?: string;
  buttonText?: string;
  buttonCancelText?: string;
}

export interface Form extends Alert {
  inputs: {
    name: string;
    placeholder: string;
    type: 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'checkbox' | 'radio';
  }[];
}

export enum InputType {
  date = 'date',
  email = 'email',
  number = 'number',
  password = 'password',
  search = 'search',
  tel = 'tel',
  text = 'text',
  url = 'url',
  time = 'time',
  checkbox = 'checkbox',
  radio = 'radio'
}
