import {NgModule} from '@angular/core';
import {DeeplinkService} from './deeplink.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [DeeplinkService]
})
export class DeepLinkServiceModule {}
