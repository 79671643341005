import { NgModule, Injectable } from '@angular/core';
import {EstablishmentService} from './establishment.service';
import {ResourceServiceModule} from '../resource/resource-service.module';

@Injectable()
@NgModule({
  imports: [ResourceServiceModule],
  providers: [EstablishmentService]
})
export class EstablishmentServiceModule {}
