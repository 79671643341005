import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeEstablishmentsListComponent} from './be-establishments-list.component';
import {BeEstablishmentCardComponentModule} from '../../cards/be-establishment-card/be-establishment-card-component.module';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule, BeEstablishmentCardComponentModule],
  exports: [BeEstablishmentsListComponent],
  declarations: [BeEstablishmentsListComponent]
})
export class BeEstablishmentsListComponentModule {}
