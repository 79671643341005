import {Component} from '@angular/core';
import {DialogService} from '~/services';
import {ModalController, NavParams} from '@ionic/angular';
import {Schedule} from '@buscoextra/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';

@Component({
  selector: 'calendar-picker-page',
  templateUrl: './calendar-picker.page.html',
  styleUrls: ['./calendar-picker.page.scss']
})
export class CalendarPickerPage extends AsyncPage {
  schedule = [];
  constructor(
    communications: CommunicationsService,
    private ionicModal: ModalController,
    navParams: NavParams,
    private dialogService: DialogService
  ) {
    super(communications);
    if (navParams['schedule']) {
      this.schedule = navParams['schedule'];
    }
  }

  async ngOnInit() {
    // this.caledarPicker = await this.send(this.caledarPickerService.getAllCaledarPicker(),'caledarPicker');
  }

  scheduleChange(value: Schedule) {
    this.ionicModal.dismiss(value);
  }

  dismiss() {
    this.ionicModal.dismiss(undefined);
  }

  onError(error) {
    this.dialogService.toast(error);
  }
}
