import {Injectable} from '@angular/core';
import {ApiService} from '@buscoextra/core/services/api';
import {Observable, of} from 'rxjs';
import {Entities} from '@buscoextra/core';import {environment} from '~environment';
import {map} from 'rxjs/operators';
import {LocationApi} from '@buscoextra/core';

@Injectable()
export class LocationService {
  constructor(private api: ApiService) {}

  getLocation(lat: number, long: number, rad?: number): Observable<boolean> {
    return this.api.apiRequestV2(
      Entities.Geolocation,
      LocationApi.isLocationContained(lat, long, rad, environment.addressName.toLocaleLowerCase())
    );
  }
}
