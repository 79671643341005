import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonBackButton, IonRouterOutlet, NavController} from '@ionic/angular';
import {Location} from '@angular/common';

@Component({
  selector: 'app-nav-back-button',
  templateUrl: './nav-back-button.component.html',
  styleUrls: ['./nav-back-button.component.scss'],
})
export class NavBackButtonComponent implements OnInit {
  @Input('route') defaultBackRoute: string;
  @Input() color = 'primary';
  @ViewChild('BackButton', {static: true}) back: IonBackButton;

  constructor(private routerOutlet: IonRouterOutlet, private navCtrl: NavController, private location: Location) {}

  ngOnInit() {}

  UIonBackBtnPressed(ev) {
    if (this.routerOutlet && this.routerOutlet.canGoBack()) {
      this.routerOutlet.pop();
      ev.preventDefault();
    } else if (this.defaultBackRoute != null) {
      this.navCtrl.navigateBack(this.defaultBackRoute);
      ev.preventDefault();
    } else {
      this.location.back();
    }
  }
}
