import {Component} from '@angular/core';
import {Cpd} from '@buscoextra/core';
import {Mark} from '@buscoextra/core';
import {ReviewService} from '@buscoextra/services/review/review.service';
import {ModalController} from '@ionic/angular';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, NavigationService, StateService} from '~/services';

@Component({
  selector: 'rate-business-page',
  templateUrl: './rate-business.page.html',
  styleUrls: ['./rate-business.page.scss']
})
export class RateBusinessPage extends AsyncPage {
  public cpd: Cpd;
  public marks: Mark[];

  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private dialog: DialogService,
    private reviewService: ReviewService,
    private modalCtrl: ModalController
  ) {
    super(communications);
  }

  async ngOnInit() {
    this.marks = await this.send(this.reviewService.getBusinessMarks(), 'ratings');
  }

  async reviewBusiness() {
    if (this.isMarksReady()) {
      await this.send(
        this.reviewService.reviewBusiness(
          this.marks.map(mark => {
            return {attribute: mark.attribute, rate: mark.rate};
          }),
          this.cpd.id
        )
      );
      this.modalCtrl.dismiss();
    } else {
      this.dialog.alert('Es necesario valorar todos los campos');
    }
  }

  isMarksReady() {
    return this.marks.some(mark => {
      return mark.rate > 0;
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
