import {Injectable} from '@angular/core';
import {BEState} from '../state/models/state';
import {Cpd} from '@buscoextra/core';

export enum LocalStorageKeys {
  AppState = 'app-state',
  NameLogin = 'name-login',
  Impersonate = 'impersonate'
}

@Injectable()
export class StorageService {
  saveNameLogin(nameLogin: string) {
    this.setByKey(LocalStorageKeys.NameLogin, nameLogin);
  }

  getNameLogin(): string {
    return this.getByKey(LocalStorageKeys.NameLogin);
  }

  saveAppState(appState: BEState) {
    this.setObjectByKey(LocalStorageKeys.AppState, appState);
  }

  getSavedAppState(): BEState {
    return this.getObjectByKey(LocalStorageKeys.AppState) as BEState;
  }

  getImpersonateMail(): string {
    return this.getByKey(LocalStorageKeys.Impersonate) as string;
  }
  saveImpersonateMail(email) {
    this.setObjectByKey(LocalStorageKeys.Impersonate, email);
  }
  removeImpersonateMail(email) {
    this.deleteByKey(LocalStorageKeys.Impersonate);
  }

  saveCurrentCpd(cpd: Cpd) {
    this.setObjectByKey('currentCpd', cpd);
  }

  getCurrentCpd(): Cpd {
    return this.getObjectByKey('currentCpd') as Cpd;
  }
  getObjectByKey(key: string): object {
    return JSON.parse(this.getByKey(key));
  }
  setObjectByKey(key: string, object: object) {
    this.setByKey(key, JSON.stringify(object));
  }

  private getByKey(key: string): any {
    return localStorage.getItem(key);
  }

  private setByKey(key: string, value: any) {
    localStorage.setItem(key, value);
  }
  private deleteByKey(key: string) {
    localStorage.removeItem(key);
  }
  clearStorage() {
    localStorage.clear();
  }
}
