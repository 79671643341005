import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AreaPickerComponent} from './area-picker.component';
import {IonicModule} from '@ionic/angular';
import {AgmCoreModule} from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYPSu40D7zp7mPNh04e_0vW2oxT-ql7l4',
      libraries: ['places'],
      region: 'ES',
      language: 'es'
    })
  ],
  exports: [AreaPickerComponent],
  declarations: [AreaPickerComponent]
})
export class AreaPickerComponentModule {}
