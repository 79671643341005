import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  Business,
  DNIBackScrapper,
  Extra,
  Item,
  Resource,
  Session,
  AuthApi,
  AccountApi,
  UserApi,
  ExtraApi,
  BusinessApi,
  LanguageApi,
  Lead,
  LeadApi,
} from '@buscoextra/core';
import {User, UserType} from '@buscoextra/core';
import {CustomEntities, Entities} from '@buscoextra/core';
import {UserSignupRequest} from '@buscoextra/core';
import {map, mergeMap} from 'rxjs/operators';
import {ResourceService, ResourceTags} from '../resource/resource.service';
import {ApiService} from '@buscoextra/core/services/api/api.service';
import {environment} from '~environment';

@Injectable()
export class UserService {
  constructor(private api: ApiService, private resourceService: ResourceService) {}

  login(userName: string, password: string): Observable<Session> {
    return this.api.apiRequestV2(
      Entities.Auth,
      AuthApi.login({
        client_id: environment.clientID,
        client_secret: environment.clientSecret,
        grant_type: 'password',
        username: userName,
        password: password,
      })
    );
  }

  reSendEmailValidation(userEmail: string): Observable<boolean> {
    return this.api.apiRequestV2(Entities.User, UserApi.recoveryEmailVerification(userEmail));
  }

  resetPassWord(userEmail: string, type?: string): Observable<boolean> {
    return this.api.apiRequestV2(Entities.User, UserApi.resetPassword(userEmail, type ?? null));
  }

  sendReport(report: Report): Observable<boolean> {
    return this.api.apiRequestV2(Entities.User, AccountApi.sendReport(report));
  }

  getFrontDniDelUser(user: User): Observable<Resource> {
    return this.resourceService.getUserResourceUrlByTag(user.id, ResourceTags.DNI_FRONT);
  }

  getBackDniDelUser(user: User): Observable<Resource> {
    return this.resourceService.getUserResourceUrlByTag(user.id, ResourceTags.DNI_BACK);
  }

  deleteUser(user: User, deletingReason: string): Observable<User> {
    return this.api.apiRequestV2(Entities.User, UserApi.updateUser({id: user.id, deleteAccountReason: deletingReason} as any)).pipe(
      mergeMap(() => {
        return this.api.apiRequestV2(Entities.User, UserApi.deleteAccount(user.id, true));
      })
    );
  }

  isUserEmailVerification(userId: number): Observable<boolean> {
    return this.getUserVariableValue(userId, 'verifiedEmail');
  }

  isUserIdentityVerified(userId: number): Observable<boolean> {
    return this.getUserVariableValue(userId, 'verifiedProfile');
  }

  getUserVariableValue(userId: number, value: string): Observable<any> {
    return this.api.apiRequestV2(Entities.User, UserApi.getUserById(userId)).pipe(
      map((user: User) => {
        return user[value];
      })
    );
  }

  getExtraDeletingReason(): Observable<String[]> {
    return of(['He encontrado trabajo por otra vía', 'No salen ofertas suficientes en mi zona', 'He dejado de buscar empleo']);
  }

  getBusinessDeletingReasons(): Observable<String[]> {
    return of(['He contratado extras por otra vía', 'No hay extras en mi zona', 'He dejado de buscar extras']);
  }

  createUser(user: UserSignupRequest, userType: UserType): Observable<Extra | Business> {
    return this.api.apiRequestV2(Entities.User, UserApi.createUser({roles: [userType], ...user}));
  }

  updateUser(userId: number, user: Extra | Business, userType: UserType, selectedFields?: string[]): Observable<User> {
    if (userType === UserType.Extra) {
      return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: userId, ...user} as any));
    }
    if (userType === UserType.Business) {
      return this.api.apiRequestV2(Entities.Business, BusinessApi.updateBusiness({id: userId, ...user} as any));
    }
  }

  updateUserByKey(userId: number, key, value, userType: UserType): Observable<User> {
    let data = {};
    data[key] = value;
    if (userType === UserType.Extra) {
      return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: userId, ...data} as any));
    }
    if (userType === UserType.Business) {
      return this.api.apiRequestV2(Entities.Business, BusinessApi.updateBusiness({id: userId, ...data} as any));
    }
  }

  updateUserFromDNIScrapper(userId, data: DNIBackScrapper, userType: UserType): Observable<User> {
    let dataFormated: any = {
      name: data.name,
      surname: data.surname1 + ' ' + data.surname2,
      dni: data.dni,
      age: data.age,
      dniExpirationDate: data.expirateAt,
    };
    return this.updateUser(userId, dataFormated, userType);
  }

  getUserById(userId: number): Observable<User> {
    return this.api.apiRequestV2(Entities.Business, UserApi.getUserById(userId), 'getUser');
  }

  getAllLanguages(): Observable<Item[]> {
    return this.api.apiRequestV2(Entities.Languages, LanguageApi.getLanguages());
  }

  uploadProfileResource(baseImage, userId, previousId): Observable<boolean> {
    return this.resourceService.uploadProfilePicture(baseImage, userId, previousId);
  }
  uploadDniFrontResource(baseImage, userId, previousId): Observable<boolean> {
    return this.resourceService.uploadDniFront(baseImage, userId, previousId);
  }
  uploadDniBackResource(baseImage, userId, previousId): Observable<boolean> {
    return this.resourceService.uploadDniBack(baseImage, userId, previousId);
  }

  notifyUserAgentExtra(userId, userAgent): Observable<User> {
    return this.api.apiRequestV2(Entities.Extra, UserApi.updateUser({id: userId, ...userAgent} as any));
  }
  notifyUserAgentBusiness(userId, userAgent): Observable<User> {
    return this.api.apiRequestV2(Entities.Business, UserApi.updateUser({id: userId, ...userAgent} as any));
  }

  extraIsReadyToVerify(userId): Observable<User> {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: userId, readyToVerify: true} as any));
  }

  termsAccepted(userId) {
    return this.api.apiRequestV2(Entities.Extra, ExtraApi.updateExtra({id: userId, legalTermsAccepted: true} as any));
  }
  businessTermsAccepted(userId) {
    return this.api.apiRequestV2(Entities.Business, BusinessApi.updateBusiness({id: userId, legalTermsAccepted: true} as any));
  }
  createLead(data: Lead) {
    return this.api.apiRequestV2(Entities.Lead, LeadApi.createLead(data));
  }
}

export interface Report {
  userId?: number;
  email?: string;
  phone?: string;
  message?: string;
}
