import {WorkResumeClient} from './workResume.mapper';

export function UserForServer(client_user) {
  delete client_user.id;
  return client_user;
}

export function UserForClient(server_user) {
  if (server_user.workResumes) {
    server_user.workResumes = server_user.workResumes.map(workResume => {
      return WorkResumeClient(workResume);
    });
  }

  return server_user;
}
