import {Injectable} from '@angular/core';
import {NavigationService} from '..';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate} from '@angular/router';
import {Observable, of, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {CommunicationsService} from '@buscoextra/core/services';
import {StateService} from '~/services/state';
import {Extra, Business} from '@buscoextra/core';
import {RequirementService} from '@buscoextra/services/requirement/requirement.service';
import {RequirementState} from '@buscoextra/core';

@Injectable()
export class BusinessReadyToCreateOffer implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(
    private communications: CommunicationsService,
    private stateService: StateService,
    private requirementService: RequirementService,
    private navigation: NavigationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.communications
      .getObservable(
        from(
          this.requirementService.isBusinessReadyToCreateOffer(this.stateService.user as Business, this.stateService.currentEstablishment)
        ),
        'isReadyToCreateOffer'
      )
      .pipe(
        map(response => {
          if (response.status !== RequirementState.Success) {
            this.navigation.goto('/business/new-offer/setup');
            return false;
          } else {
            return true;
          }
        })
      );
  }
}
