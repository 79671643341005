import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeNotificationCardComponent} from './be-notification-card.component';

@NgModule({
  imports: [CommonModule],
  exports: [BeNotificationCardComponent],
  declarations: [BeNotificationCardComponent]
})
export class BeNotificationCardComponentModule {}
