import {Component, Input, OnInit} from '@angular/core';
import {PriceTime} from '@buscoextra/core';
import {Contract, TimingFormatType} from '@buscoextra/core';
import {ShortTermTiming} from '@buscoextra/core';
import {LongTermTiming} from '@buscoextra/core';
import {UnlimitedTiming} from '@buscoextra/core';

@Component({
  selector: 'contract-resume-card',
  templateUrl: './contract-resume-card.component.html',
  styleUrls: ['./contract-resume-card.component.scss'],
})
export class ContractResumeCardComponent implements OnInit {
  @Input() contract: Contract;
  @Input() priceTime: PriceTime;
  @Input() priceTimeType: TimingFormatType;
  @Input() hiddenSalary = false;
  @Input() hiddenStartTime = false;
  @Input() workType;

  constructor() {}

  ngOnInit() {}
  get nDays() {
    if (this.priceTime.timingFormat && (this.priceTime.timingFormat as ShortTermTiming).schedule) {
      return (this.priceTime.timingFormat as ShortTermTiming).schedule.length;
    }
    return (new Date(this.contract.finishDate).getTime() - new Date(this.contract.startDate).getTime()) / (1000 * 60 * 60 * 24);
  }

  isFinishAndStartDateSameMonth(): boolean {
    return new Date(this.contract.finishDate).getMonth() === new Date(this.contract.startDate).getMonth();
  }

  isFinishAndStartDateSameDay(): boolean {
    return new Date(this.contract.finishDate).getDate() === new Date(this.contract.startDate).getDate();
  }

  timingFormat(): any {
    if (this.priceTimeType == 0) {
      return this.priceTime.timingFormat as ShortTermTiming;
    } else if (this.priceTimeType == 1) {
      return this.priceTime.timingFormat as LongTermTiming;
    } else if (this.priceTimeType == 2) {
      return this.priceTime.timingFormat as UnlimitedTiming;
    } else {
      return {};
    }
  }
}
