import {Requirement} from '@buscoextra/core';
import {User} from '@buscoextra/core';
import {Resource} from '@buscoextra/core';
import {exists} from '../tools/operations';

export const userVerifiedRequirement: Requirement[] = [
  {
    id: 'phone',
    title: 'Necesita un teléfono',
    rule: (user: User): boolean => {
      return exists(user.phoneNumber);
    }
  },
  {
    id: 'email',
    title: 'Necesita un email',
    rule: (user: User): boolean => {
      return exists(user.email) && user.fbId !== user.email.split('@')[0];
    }
  },
  {
    id: 'dniFront',
    service: 'userService',
    method: 'getFrontDniDelUser',
    title: 'Necesita un dni por delante',
    rule: (resource: Resource): boolean => {
      return exists(resource);
    }
  },
  {
    id: 'dniBack',
    service: 'userService',
    method: 'getBackDniDelUser',
    title: 'Necesita un dni por detrás',
    rule: (resource: Resource): boolean => {
      return exists(resource);
    }
  }
];
