import {LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {AppServicesModule} from './services/app-services.module';
import {AppService} from './app.service';
import localeEs from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
import {UserServiceModule} from '@services';
import {Camera} from '@ionic-native/camera/ngx';
import {CameraPreview} from '@ionic-native/camera-preview/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Mixpanel, MixpanelPeople} from '@ionic-native/mixpanel/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
// import {Push} from '@ionic-native/push/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Device} from '@ionic-native/device/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '~environment';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {LottieAnimationModule} from './services/lottie/lottie.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ReviewsPage} from './pages/home/shell-business-page/pages/reviews/reviews.page';
import {ReviewsPageModule} from './pages/home/shell-business-page/pages/reviews/reviews-page.module';
import {BeLoadingComponentModule} from '@components';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';

registerLocaleData(localeEs, 'es');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // ModalComponent,
    IonicModule.forRoot(),
    AppRoutingModule,
    AppServicesModule,
    UserServiceModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}), //
    BeInputValueAccessorModule,
    ReviewsPageModule,
    BeLoadingComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'es-ES'},
    AppService,
    Camera,
    CameraPreview,
    Facebook,
    GooglePlus,
    // Push,
    Mixpanel,
    MixpanelPeople,
    Deeplinks,
    Geolocation,
    Device,
    InAppBrowser,
    Keyboard,
    File,
    FileTransfer,
    FileOpener,
    FirebaseX
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
