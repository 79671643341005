import {Injectable} from '@angular/core';
import {DeviceService} from './services/device/device.service';
import {ParamService} from '@buscoextra/services/param/param.service';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';

export enum AppStatus {
  Maintenance = 'maintenance',
  Outdated = 'outdated',
  Stable = 'stable',
}

@Injectable({
  providedIn: 'root',
})
export class AppService extends AsyncPage {
  public globalLoading = new Subject<boolean>();

  constructor(
    private paramsService: ParamService,
    communication: CommunicationsService,
    private deviceService: DeviceService,
    private device: DeviceService
  ) {
    super(communication);
  }
  async getAppStatus() {
    return new Promise(async resolve => {
      const statusOK = await this.send(this.paramsService.getStatus());
      if (!statusOK) {
        resolve(AppStatus.Maintenance);
        return;
      }
      const appVersion = await this.send(this.deviceService.getAppVersion());

      let minVersion;
      switch (this.device.getOS()) {
        case 'web':
          minVersion = 1;
          break;
        case 'Android':
          minVersion = await this.send(this.paramsService.getAndroidAppMinVersion(), 'androidAppMinVersion');
          break;
        case 'iOS':
          minVersion = await this.send(this.paramsService.getIosAppMinVersion(), 'iosAppMinVersion');
          break;
      }

      if (statusOK && minVersion && appVersion) {
        if (+minVersion.toString().replace(/\./g, '') > appVersion) {
          resolve(AppStatus.Outdated);
        }
        resolve(AppStatus.Stable);
      } else {
        resolve(AppStatus.Maintenance);
      }
    });
  }
}
