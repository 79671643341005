import {Component, Input} from '@angular/core';
import {GetStatus} from './contract-status.setting';
import {UserType} from '@buscoextra/core';

@Component({
  selector: 'contract-status',
  templateUrl: './contract-status.component.html',
  styleUrls: ['./contract-status.component.scss']
})
export class ContractStatusComponent {
  @Input() status: string;
  @Input() userType: UserType;

  constructor() {}

  ngOnInit() {}

  get statusText() {
    return GetStatus(this.status, this.userType);
  }
}
