export const EstablishmentForServer = (clientEstablishment: any, seletedFields?: string[]) => {
  delete clientEstablishment.id;
  if (clientEstablishment.location) {
    delete clientEstablishment.location.id;
  }
  delete clientEstablishment.owner;

  if (clientEstablishment.company && clientEstablishment.company.id) {
    clientEstablishment.company = clientEstablishment.company.id;
  }
  if (clientEstablishment.agreement && clientEstablishment.agreement.id) {
    clientEstablishment.agreement = clientEstablishment.agreement.id;
  }
  if (clientEstablishment.establishmentType && clientEstablishment.establishmentType.id) {
    clientEstablishment.establishmentType = clientEstablishment.establishmentType.id;
  }

  delete clientEstablishment.images;
  delete clientEstablishment.services;

  if (seletedFields && seletedFields.length > 0) {
    let resClientEstablishment = {};
    seletedFields.forEach(field => {
      resClientEstablishment[field] = clientEstablishment[field];
    });
    return resClientEstablishment;
  }

  return clientEstablishment;
};

export const EstablishmentForClient = serverEstablishment => {
  delete serverEstablishment.createdAt;
  delete serverEstablishment.cgc;
  return serverEstablishment;
};
