import {BEState} from './models/state';
import {BEAction} from './models/action';
import {BEEstablishmentActions} from './actions/redux-establishment.actions';
import {BESessionActions} from './actions/redux-session.actions';
import {StorageService} from '../storage/storage.service';
import {BEUserActions} from './actions/redux-user.actions';
import {Establishment, User, Session} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
export const BE_INITIAL_STATE: BEState = {
  establishments: {} as Establishment,
  sessions: {} as Session,
  users: {} as User
};

export function rootReducer(lastState: BEState, action: BEAction): BEState {
  const nextState = {...lastState};
  let finalState: BEState;
  switch (action.type) {
    case BEUserActions.UPDATE_USER:
      nextState[Entities.User] = action.payload;
      finalState = nextState;
      break;
    case BEEstablishmentActions.UPDATE_ESTABLISHMENT:
      nextState[Entities.Establishment] = action.payload;
      finalState = nextState;
      break;
    case BESessionActions.UPDATE_SESSION:
      Object.assign(nextState[Entities.Session], action.payload);
      finalState = nextState;
      break;
    case BESessionActions.FINISH_SESSION:
      finalState = {
        establishments: {} as Establishment,
        sessions: {} as Session,
        users: {} as User
      };
      break;
    default:
      finalState = lastState;
      break;
  }
  new StorageService().saveAppState(finalState);
  return finalState;
}
