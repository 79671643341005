import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadModalComponent} from './lead-modal.component';
import {IonicModule} from '@ionic/angular';
import {LocationPickerComponentModule} from '~/components/be-components/location-picker/location-picker-component.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, LocationPickerComponentModule, FormsModule, ReactiveFormsModule, BeInputValueAccessorModule],
  exports: [LeadModalComponent],
  declarations: [LeadModalComponent]
})
export class LeadModalComponentModule {}
