import {Component} from '@angular/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {NavigationService, StateService, DialogService} from '~/services';
import {CpdService} from '@buscoextra/services/cpd/cpd.service';
import {Cpd, UserType, ShortTermTiming, Schedule} from '@buscoextra/core';
import {ModalController} from '@ionic/angular';
import {isDateAfterDate, calculateDaysBetween} from '@buscoextra/core';

@Component({
  selector: 'resigning-cpd-page',
  templateUrl: './resigning-cpd.page.html',
  styleUrls: ['./resigning-cpd.page.scss']
})
export class ResigningCpdPage extends AsyncPage {
  wasExtraToWork: boolean;
  lastDayExtraWork: Date;
  reason: string;
  userType: UserType;
  currentCpd: Cpd;
  extraName: string;
  isNotAbleDate = false;
  hideCheckBox = false;

  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private cpdService: CpdService,
    private modal: ModalController,
    private dialog: DialogService
  ) {
    super(communications);
  }

  async ngOnInit() {
    this.extraName = this.currentCpd.extra.name;
    if (new Date().getTime() < new Date(this.currentCpd.startDate).getTime()) {
      this.hideCheckBox = true;
      this.wasExtraToWork = false;
    }
    // this.resigningCpd = await this.send(this.resigningCpdService.getAllResigningCpd(),'resigningCpd');
  }

  isABusiness(): boolean {
    return this.userType === UserType.Business;
  }

  UIonCancelContract() {
    if (this.isAbleToCancel()) this.DOcancelContract(this.currentCpd);
  }

  isAbleToCancel(): boolean {
    if (this.wasExtraToWork && !this.lastDayExtraWork) {
      this.dialog.toast('Necesitamos saber cual fue el último día');
      return false;
    }
    if (this.wasExtraToWork && this.lastDayExtraWork && !this.isAbleDate()) {
      return false;
    }
    if (!this.reason || this.reason.length < 2) {
      this.dialog.toast('Necesitamos saber el motivo');
      return false;
    }
    return true;
  }

  isAbleDate(): boolean {
    const timing: any = this.currentCpd.cpdPriceTime.timingFormat;
    if (isDateAfterDate(timing.startDate, new Date(this.lastDayExtraWork))) {
      this.dialog.toast('Su último día no puede ser anterior al inicio del contrato');
      this.isNotAbleDate = true;
      return false;
    }
    if (timing.finishDate && isDateAfterDate(new Date(this.lastDayExtraWork), timing.finishDate)) {
      this.dialog.toast('Su último día no puede ser posterior al fin del contrato');
      this.isNotAbleDate = true;
      return false;
    }
    return true;
  }

  async UIonCloseModalBtnPressed() {
    this.modal.dismiss();
  }

  private async DOcancelContract(cpd: Cpd) {
    let operationResponse: any;

    if (this.userType === UserType.Business && !this.wasExtraToWork) {
      operationResponse = await this.send(
        this.cpdService.revokeBusinessCpdByExtraId(cpd, this.state.userId, this.reason),
        'cancelingOffer'
      );
    }
    if (this.userType === UserType.Extra && !this.wasExtraToWork) {
      operationResponse = await this.send(this.cpdService.revokeExtraCpdByExtraId(cpd, this.state.userId, this.reason), 'cancelingOffer');
    }

    if (this.wasExtraToWork) {
      let probationaryPeriod = this.currentCpd.cpdPriceTime.agreementPrice.probationaryPeriodDays;
      const nDays = this.currentCpd.totalDays;
      const daysWorked = calculateDaysBetween(new Date(this.lastDayExtraWork), new Date(this.currentCpd.startDate));
      if (nDays < probationaryPeriod) {
        probationaryPeriod = nDays / 2;
      }

      if (this.userType === UserType.Business) {
        if (daysWorked < probationaryPeriod) {
          operationResponse = await this.send(
            this.cpdService.firedCpdByExtraId(cpd, this.state.userId, this.lastDayExtraWork, this.reason),
            'cancelingOffer'
          );
        } else {
          operationResponse = await this.send(
            this.cpdService.finishedReducedCpdByExtraId(cpd, this.state.userId, this.lastDayExtraWork, this.reason),
            'cancelingOffer'
          );
        }
      }
      if (this.userType === UserType.Extra) {
        operationResponse = await this.send(
          this.cpdService.resignedCpdByExtraId(cpd, this.state.userId, this.lastDayExtraWork, this.reason),
          'cancelingOffer'
        );
      }
    }

    if (operationResponse !== null) {
      this.dialog.toast('Contrato cancelado');
      this.modal.dismiss(true);
    } else {
      this.modal.dismiss(false);
    }
  }

  isShortTermCpd(): boolean {
    return this.currentCpd.cpdPriceTime.service.timingFormatType == 0;
  }

  getSchedule(): Schedule[] {
    return (this.currentCpd.cpdPriceTime.timingFormat as ShortTermTiming).schedule;
  }
}
