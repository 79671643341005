import {Component} from '@angular/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {ReviewFeedback, ReviewsService} from './reviews.service';
import {NavigationService} from '~/services';
import {environment} from '~environment';
import * as _ from 'lodash';
import {ModalController} from '@ionic/angular';
import {Entities, ParamApi} from '@buscoextra/core';

@Component({
  selector: 'reviews-page',
  templateUrl: './reviews.page.html',
  styleUrls: ['./reviews.page.scss'],
})
export class ReviewsPage extends AsyncPage {
  public establishments = [];
  reviewStatus: ReviewStatus[] = [];
  public baseUrl = environment.urlBase;
  public isOmitButtonActivated: boolean = false;

  constructor(communications: CommunicationsService, public reviewService: ReviewsService, private modalCtl: ModalController) {
    super(communications);
  }

  async ngOnInit() {
    this.isOmitButtonActivated = await this.reviewService.isOmitButtonActivated().toPromise();
    this.loadEstablishments();
  }

  loadEstablishments() {
    this.reviewService.getReviewData().subscribe(
      res => {
        this.establishments = res;
        this.establishments.forEach(establishment => {
          establishment.offers.forEach(offer => {
            offer.cpds.forEach(cpd => {
              this.reviewStatus.push({
                id: establishment.id + '_' + offer.id + '_' + cpd.id,
                value: undefined,
                feedback: {unpunctual: false, unprofessional: false, poorHygiene: false},
              });
            });
          });
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  checkExistingRow(id) {
    return this.reviewStatus.find(el => el.id == id);
  }

  checkRowHasItems(id) {
    return this.reviewStatus.find(el => el.id.split('_')[0] == id.split('_')[0] && el.id.split('_')[1] == id.split('_')[1]);
  }

  checkReviewAll() {
    return this.reviewStatus.find(el => el.value != undefined);
  }

  formatDate(dateString) {
    return new Date(dateString).toDateString();
  }

  onTumbsUpIconClick(id: string) {
    let elem = this.reviewStatus.find(el => el.id == id);
    elem.value = elem.value == 'positive' ? undefined : 'positive';

    this.checkIconsStatus();
  }

  onTumbsDownIconClick(id: string) {
    let elem = this.reviewStatus.find(el => el.id == id);
    elem.value = elem.value == 'negative' ? undefined : 'negative';

    this.checkIconsStatus();
  }

  checkIconsStatus() {
    Array.from(document.querySelectorAll('.icon-thumbs')).forEach(el => el.classList.remove('active'));
    Array.from(document.querySelectorAll('.icon-send-container')).forEach(el => el.classList.add('disabled'));
    Array.from(document.querySelectorAll('.extra-feedback')).forEach(el => el.classList.add('hidden'));

    this.reviewStatus.forEach(el => {
      if (el.value != undefined) {
        document.getElementById(el.id + '_' + el.value).classList.add('active');
        document.getElementById(el.id + '_send').classList.remove('disabled');
        if (el.value === 'negative') document.getElementById(el.id + '_feedback').classList.remove('hidden');
      }
    });
    // Array.from(document.querySelectorAll('.icon-thumbs')).forEach(el => el.classList.add('active'));
  }

  onFeedbackPillClicked(id, value) {
    let elem = this.reviewStatus.find(el => el.id == id);
    let pill1 = document.getElementById(id + '_feedback_1');
    let pill2 = document.getElementById(id + '_feedback_2');
    let pill3 = document.getElementById(id + '_feedback_3');

    if (value == 1) {
      elem.feedback.unpunctual = !elem.feedback.unpunctual;
      elem.feedback.unpunctual ? pill1.classList.add('active') : pill1.classList.remove('active');
    }
    if (value == 2) {
      elem.feedback.unprofessional = !elem.feedback.unprofessional;
      elem.feedback.unprofessional ? pill2.classList.add('active') : pill2.classList.remove('active');
    }
    if (value == 3) {
      elem.feedback.poorHygiene = !elem.feedback.poorHygiene;
      elem.feedback.poorHygiene ? pill3.classList.add('active') : pill3.classList.remove('active');
    }
  }

  onSend(id?) {
    let request = id ? [this.reviewStatus.find(el => el.id == id)] : this.reviewStatus.filter(el => el.value != undefined);
    this.reviewService.setExtraRate(request).subscribe(() => {
      request.forEach(req => {
        this.reviewStatus = this.reviewStatus.filter(el => el.id != req.id);
        if (this.reviewStatus.length == 0) {
          this.modalCtl.dismiss();
        }
      });
    });
  }
  onOmit() {
    this.modalCtl.dismiss();
  }
}

export interface ReviewStatus {
  id: string;
  value: undefined | 'positive' | 'negative';
  feedback?: ReviewFeedback; //-1 sin eleccion, 1 impuntual, 2 poco profesional, 3 mala higiene
}
