import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Cpd, Extra, WorkType, Service} from '@buscoextra/core';
import {NavigationService, StateService} from '~/services';
import {GetStatus} from '@buscoextra/components/other/contract-status/contract-status.setting';
import {calcDistance, Point} from '@buscoextra/core';
import {Status} from '@buscoextra/components/other/contract-status/models/status';
import {Skill} from '@buscoextra/core';
import {DeviceService} from '~/services/device/device.service';
import {BusinessPreviewDateOfferPage} from '~/pages/home/shell-business-page/pages/my-offers-page/pages/business-preview-date-offer-page/business-preview-date-offer.page';
import {ModalsService} from '~/services/modals/modals.service';
import {environment} from '~environment';

@Component({
  selector: 'be-business-cpd-card',
  templateUrl: './be-business-cpd-card.component.html',
  styleUrls: ['./be-business-cpd-card.component.scss'],
})
export class BeBusinessCpdCardComponent {
  public baseUrl = environment.urlBase;
  constructor(
    private navigation: NavigationService,
    private state: StateService,
    public device: DeviceService,
    private modal: ModalsService
  ) {}

  @Input() src: Cpd;

  @Input() primaryBtn: string;
  @Input() secondBtn: string;
  @Input() recomended: boolean = false;
  @Input() checked: boolean;

  @Output('onSecondBtn') secondEmitter: EventEmitter<Cpd> = new EventEmitter();
  @Output('onPrimaryBtn') primaryEmitter: EventEmitter<Cpd> = new EventEmitter();
  @Output('extraDetail') extraDetailEmitter: EventEmitter<number> = new EventEmitter();
  @Output('onCpdDetail') onCpdDetail: EventEmitter<Cpd> = new EventEmitter();
  @Output('onChecked') onChecked: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
    if (this.src && this.src.extra && this.src.extra.skills && this.src.extra.skills.length > 0) {
      this.src.extra.skills = this.filterSkillsByWorktype(this.src.extra.skills, this.src.offer.workType);
    }
  }

  private filterSkillsByWorktype(skills: Skill[], worktype: WorkType): Skill[] {
    return skills.filter(skill => {
      return skill.workType && skill.workType.id === worktype.id;
    });
  }

  cpdDetail(cpd: Cpd) {
    this.onCpdDetail.emit(cpd);
  }

  onCheckChecked(ev) {
    this.onChecked.emit(ev.detail.checked);
  }

  UIonSecondaryBtnPressed(cpd: Cpd) {
    this.secondEmitter.emit(cpd);
  }

  UIonPrimaryBtnPressed(cpd: Cpd) {
    this.primaryEmitter.emit(cpd);
  }

  UIonExtraCardPressed(extra: Extra) {
    this.extraDetailEmitter.emit(extra.id);
  }

  get status(): Status {
    return GetStatus(this.src.status, this.state.userType);
  }

  get distance() {
    let extraCoord: Point = {
      lat: this.src.extra.latitude,
      long: this.src.extra.longitude,
    };
    let establishmentCoord: Point = {
      lat: this.src.establishment.location.latitude,
      long: this.src.establishment.location.longitude,
    };

    return calcDistance(extraCoord, establishmentCoord);
  }

  getCpdService() {
    return this.src.cpdPriceTime && (this.src.cpdPriceTime.service as Service);
  }
  getCpdSchedule() {
    return this.src.cpdPriceTime && (this.src.cpdPriceTime.timingFormat as any).schedule;
  }
}
