import {AEvent} from '../models/event';

export class ExtraLifeCycleEvents {
  static BasicProfile(): AEvent {
    return {
      title: 'BasicProfile'
    } as AEvent;
  }
  static ReadyToVerify(): AEvent {
    return {
      title: 'ReadyToVerify'
    } as AEvent;
  }
  static Apply(): AEvent {
    return {
      title: 'Apply'
    } as AEvent;
  }
}
