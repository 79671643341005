import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../navigation.service';
import {StateService} from '~/services/state';

@Injectable()
export class HasUserType implements CanActivate {
  constructor(private router: Router, private navigationService: NavigationService, private stateService: StateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.stateService.userType == undefined) {
      return this.router.parseUrl('welcome');
    } else {
      return true;
    }
  }
}
