import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@buscoextra/core/services/api';
import {Entities} from '@buscoextra/core';import {Signature, SignatureApi} from '@buscoextra/core';

export const SIGNATURE_TAGS = {
  coordPRL: 'SignatureOtp-CoordPrl',
  cpd: 'SignatureOtp-Cpd'
};
@Injectable()
export class SignatureService {
  constructor(private api: ApiService) {}

  requestOTPSign(resourceId): Observable<boolean> {
    return this.api.apiRequestV2(Entities.Signature, SignatureApi.requestOtpSign(resourceId));
  }

  getSignatureResource(resourceId, entityName): Observable<Signature[]> {
    return this.api.apiRequestV2(Entities.Signature, SignatureApi.getSignatureResourceByEntityIdAndEntityName(resourceId, entityName));
  }

  signOTP(
    signatureId: number,
    data: {
      otp: number;
    }
  ): Observable<boolean> {
    return this.api.apiRequestV2(Entities.Signature, SignatureApi.signOtp(signatureId, data));
  }

  finishOTP(signatureId: number): Observable<boolean> {
    return this.api.apiRequestV2(Entities.Signature, SignatureApi.finishOTP(signatureId));
  }

  fillDocument(signatureOtpId: number) {
    return this.api.apiRequestV2(Entities.Signature, SignatureApi.fillDocument(signatureOtpId));
  }
}
