import {NgModule} from '@angular/core';
import {IdCardCaptureComponent} from './id-card-capture.component';
import {CameraServiceModule} from '~/services/camera/camera-service.module';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule, CameraServiceModule],
  exports: [IdCardCaptureComponent],
  declarations: [IdCardCaptureComponent],
  providers: []
})
export class IdCardCaptureModule {}
