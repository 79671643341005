import {Extra} from '@buscoextra/core';
import {deleteEmptyArrays, deleteProps, transformPropsObjectArraysToIDArrays} from './tools/mappers-tools';

export function ExtraForServer(client_business: Extra) {
  let server_business: any = { ...client_business };
  server_business = transformPropsObjectArraysToIDArrays(server_business, ['languages', 'skills', 'workTypeChosens']);
  server_business = deleteProps(server_business, [
    'profileResource',
    'rol',
    'roles',
    'workTypeChosens',
    'views',
    'lastLogin',
    'fbId'
  ]);
  server_business = deleteEmptyArrays(server_business);
  if (server_business.residence) delete server_business.residence.id;
  if (server_business.birthDateLocation) delete server_business.birthDateLocation.id;
  return server_business;
}

export function ExtraForClient(server_business) {
  let client_business: any = server_business;
  return client_business;
}
