import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessPreviewDateOfferPage} from './business-preview-date-offer.page';
import {BusinessPreviewDateOfferRoutingModule} from './business-preview-date-offer.routing';
import {IonicModule} from '@ionic/angular';
import {ContractResumeCardComponentModule} from '@buscoextra/components/cards/contract-resume-card/contract-resume-card-component.module';
import {TimingCardComponentModule} from '@buscoextra/components/cards/timing-card/timing-card-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, BusinessPreviewDateOfferRoutingModule, ContractResumeCardComponentModule, TimingCardComponentModule],
  exports: [BusinessPreviewDateOfferPage],
  declarations: [BusinessPreviewDateOfferPage]
})
export class BusinessPreviewDateOfferPageModule {}
