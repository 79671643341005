import {Injectable} from '@angular/core';
import {BEAction} from '../models/action';
import {User} from '@buscoextra/core';

@Injectable()
export class BEUserActions {
  static readonly UPDATE_USER = 'UPDATE_USER';

  update(user: User): BEAction {
    return {type: BEUserActions.UPDATE_USER, payload: user};
  }
}
