import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {isValidSS} from '@buscoextra/core';

@Directive({
  selector: '[socialSecurityValidator]'
})
export class SocialSecurityValidator {
  constructor() {}

  @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  socialSecurity = '';
  @Output() socialSecurityNumberChange = new EventEmitter();
  @Input()
  get socialSecurityNumber() {
    return this.socialSecurity;
  }

  set socialSecurityNumber(val) {
    this.isValid.emit(isValidSS(val));
    this.socialSecurityNumberChange.emit(this.socialSecurity);
  }
}
