import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeExtrasListComponent} from './be-extras-list.component';
import {IonicModule} from '@ionic/angular';
import {BeExtraCardComponentModule} from '@buscoextra/components/cards/be-extra-card/be-extra-card-component.module';

@NgModule({
  imports: [CommonModule, BeExtraCardComponentModule, IonicModule],
  exports: [BeExtrasListComponent],
  declarations: [BeExtrasListComponent]
})
export class BeExtrasListComponentModule {}
