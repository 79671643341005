import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExtraSetupIdentityPage} from './extra-setup-identity.page';
import {ExtraSetupIdentityRoutingModule} from './extra-setup-identity.routing';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {UserServiceModule} from '@services';
import {BeLoadingComponentModule} from '@components';
import {RequirementServiceModule} from '@buscoextra/services/requirement/requirement.module';
import {ResourceServiceModule} from '@buscoextra/services/resource/resource-service.module';
import {ResourcePickerModule} from '~/components/resource-picker/resource-picker-component.module';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    BeLoadingComponentModule,
    RequirementServiceModule,
    IonicModule,
    UserServiceModule,
    ExtraSetupIdentityRoutingModule,
    ResourceServiceModule,
    ResourcePickerModule,
    BeInputValueAccessorModule
  ],
  exports: [ExtraSetupIdentityPage],
  declarations: [ExtraSetupIdentityPage]
})
export class ExtraSetupIdentityPageModule {}
