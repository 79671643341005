import {Requirement} from '@buscoextra/core';
import {Establishment} from '@buscoextra/core';

export const businessFinishRegister: Requirement[] = [
  {
    id: 'establishment',
    service: 'establishmentService',
    method: 'getBusinessEstablishments',
    title: 'Necesitas decir donde quieres trabajar',
    rule: (establishment: Establishment[]): boolean => {
      return establishment.length > 0;
    }
  }
];
