import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiService} from './api.service';
import {HeadersService} from './services/headers.service';
import {EncryptService} from './services/encrypt.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersService,
      multi: true
    },
    EncryptService
  ]
})
export class ApiServiceModule {}
