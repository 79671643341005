import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IsLogged} from '~/services/navigation/guards/isLogged.guard';
import {IsAuthorized} from './services/navigation/guards/isAuthorized.guard';
import {SystemStatus} from './services/navigation/guards/systemStatus.guard';
import {HasUserType} from './services/navigation';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
    canActivate: [SystemStatus]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance-page/maintenance-page.module').then(m => m.MaintenancePageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update-page/update-page.module').then(m => m.UpdatePageModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/init/welcome-page/welcome-page.module').then(m => m.WelcomePageModule),
    canActivate: [SystemStatus, IsLogged]
  },
  {
    path: 'on-boarding',
    loadChildren: () => import('./pages/init/on-boarding-page/on-boarding-page.module').then(m => m.OnBoardingPageModule),
    canActivate: [SystemStatus, IsLogged, HasUserType]
  },
  {
    path: 'sign-selection',
    loadChildren: () => import('./pages/init/sign-selection-page/sign-selection-page.module').then(m => m.SignSelectionPageModule),
    canActivate: [SystemStatus, IsLogged, HasUserType]
  },
  {
    path: 'sign',
    loadChildren: () => import('./pages/init/sign-page/sign-page.module').then(m => m.SignPageModule),
    canActivate: [SystemStatus, IsLogged, HasUserType]
  },
  {
    path: 'extras',
    loadChildren: () => import('./pages/home/shell-extra-page/shell-extra-page.module').then(m => m.ShellExtraPageModule),
    canActivate: [SystemStatus, IsAuthorized]
  },
  {
    path: 'business',
    loadChildren: () => import('./pages/home/shell-business-page/shell-business-page.module').then(m => m.ShellBusinessPageModule),
    canActivate: [SystemStatus, IsAuthorized]
  },
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
