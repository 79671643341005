import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractResumeCardComponent} from './contract-resume-card.component';
import {IonicModule} from '@ionic/angular';
import {DayToWorkPipeModule} from './pipes/day-to-work.pipe.module';

@NgModule({
  imports: [CommonModule, IonicModule, DayToWorkPipeModule],
  exports: [ContractResumeCardComponent],
  declarations: [ContractResumeCardComponent]
})
export class ContractResumeCardComponentModule {}
