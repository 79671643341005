export const environment = {
  production: true,
  addressName: 'PROD',
  urlBase: 'https://api.buscoextra.es',
  urlBaseToken: 'https://api.buscoextra.es/oauth/v2/token',
  clientID: '1_2k0lsbfc7484k0o08ksw0gwcc0skgw04o0wow488wk0kco48gw',
  clientSecret: '2jm02wb3upq8og44gocgk0k8sgg88so0sccg84gcwogk8c4c44',
  encryptionKey: '_44a_3wrU2eRbTORTu5-CemseGyt3hP0ucVc3M3ES4Q',
  gMapsApiKey: 'AIzaSyAYPSu40D7zp7mPNh04e_0vW2oxT-ql7l4',
  status: 'https://status.buscoextra.es',
  version: 700,
  authKeyAndroid: '437545253590-994699ccqn10j445av8lghjul6p1ff1a.apps.googleusercontent.com',
  authKeyiOS: '437545253590-d7aea7u3m49e0h2jlf6lab7jotodpot3.apps.googleusercontent.com',
  authWeb: '437545253590-994699ccqn10j445av8lghjul6p1ff1a.apps.googleusercontent.com'
};
