import {Injectable} from '@angular/core';

import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {CommunicationsService} from '@buscoextra/core/services';

import {StateService} from '~/services';

import {RequirementService} from '@buscoextra/services/requirement/requirement.service';

import {NavigationService} from '..';

import {Observable, from} from 'rxjs';

import {Business, RequirementState, Extra} from '@buscoextra/core';

import {map} from 'rxjs/operators';

@Injectable()
export class ExtraReadyToFindOffers implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(
    private communications: CommunicationsService,
    private stateService: StateService,
    private requirementService: RequirementService,
    private navigation: NavigationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.communications
      .getObservable(from(this.requirementService.isExtraReadyToFindOffer(this.stateService.user as Extra)), 'isReadyToFindOffers')
      .pipe(
        map(response => {
          if (response.status !== RequirementState.Success) {
            this.navigation.goto('/extras/setup');
            return false;
          } else {
            return true;
          }
        })
      );
  }
}
