import {UserForClient} from './user.mapper';

export const CpdForServer = clientCpd => {
  delete clientCpd.id;
  return clientCpd;
};

export const CpdForClient = serverCpd => {
  delete serverCpd.createdAt;

  if (serverCpd.extra) {
    serverCpd.extra = UserForClient(serverCpd.extra);
  }
  return serverCpd;
};
