import {NgModule} from '@angular/core';
import {DeviceService} from './device.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [DeviceService]
})
export class DeviceServiceModule {}
