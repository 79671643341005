import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private angularTranslation: TranslateService) {}

  setup() {
    this.angularTranslation.setDefaultLang('es');
    this.angularTranslation.use('es');
  }

  getDefaultLanguage() {
    return this.angularTranslation.getDefaultLang();
  }

  translate(textKey: string): Promise<string> {
    return this.angularTranslation.get(textKey).toPromise();
  }
}
