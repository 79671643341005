import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Param, ParamApi} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {map} from 'rxjs/operators';
import {ApiService} from '@buscoextra/core/services/api';

@Injectable({
  providedIn: 'root',
})
export class ParamService {
  constructor(private api: ApiService) {}

  getAndroidAppMinVersion(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByName('appMinVersionAndroid')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].value;
        } else {
          return undefined;
        }
      })
    );
  }
  getRecomendedMinScore(): Observable<number> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByName('score')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return +res[0].value;
        } else {
          return undefined;
        }
      })
    );
  }

  getIosAppMinVersion(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByName('appMinVersionIos')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].value;
        } else {
          return undefined;
        }
      })
    );
  }

  isChangePasswordActivated(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByName('isChangePasswordActive')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].value;
        } else {
          return undefined;
        }
      })
    );
  }

  getStatus() {
    return this.api.getStatus().pipe(
      map(res => {
        return true;
      })
    );
  }

  getWorkDocs(): Observable<Param> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByTag('resourceType'));
  }

  getSupportPhone(): Observable<number> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByTag('supportPhoneNumber')).pipe(
      map((param: any[]) => {
        if (param && param.length > 0) {
          return param[0].value;
        }
      })
    );
  }

  getWorkDoc(id): Observable<Param> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamById(id)).pipe(
      map(res => {
        return {...JSON.parse(res.value), ...{title: res.name}};
      })
    );
  }

  getExtraTermAndConditions(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByTag('extra-terms')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].longValue;
        }
      })
    );
  }
  getBusinessTermAndConditions(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByTag('business-terms')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].longValue;
        }
      })
    );
  }
  getPrivacy(): Observable<string> {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByTag('privacy')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].longValue;
        }
      })
    );
  }
}
