import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {isValidIBAN} from '@buscoextra/core';

@Directive({
  selector: '[ibanValidator]'
})
export class IbanValidator {
  constructor() {}

  @Output('isValid')
  isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  iban = '';
  @Output() ibanNumberChange: EventEmitter<string> = new EventEmitter();
  @Input()
  get ibanNumber() {
    return this.iban;
  }
  set ibanNumber(val) {
    this.iban = val;
    if (val) {
      this.isValid.emit(isValidIBAN(this.iban));
    }
    this.ibanNumberChange.emit(this.iban);
  }
}
