import {Session} from '@buscoextra/core';

export const SessionForClient = (OAuth: any) => {
  let session: Session = {
    accessToken: OAuth.access_token,
    createdAt: OAuth.createdAt,
    expiresIn: OAuth.expires_in,
    refreshToken: OAuth.refresh_token,
    scope: OAuth.scope,
    userId: OAuth.id,
    userType: OAuth.rol
  };

  return session;
};
