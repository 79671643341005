import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Requirement, RequirementResponse} from '@buscoextra/core';
import {User} from '@buscoextra/core';
import {UserService} from '../user/user.service';
import {extraApplyRequirement, extraFindOfferRequirement, userVerifiedRequirement} from './requiriments';
import {Business, Establishment, Extra, WorkTypeChosen} from '@buscoextra/core';
import {ExtraService} from '../user/extra/extra.service';
import {businessCreateOfferRequirements} from './requiriments/businessCreateOffer.requirement';
import {establishmentCreateOfferRequirements} from './requiriments/establishmentCreateOffer.requirement';
import {ExtraApplyOfferRequirementInputs} from './requiriments/extraApplyOffer.requirement';
import {WorktypeService} from '../worktype/worktype.service';
import {WORKTYPE_REQUIREMENTS} from './requiriments/worktypes';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {businessFinishRegister} from './requiriments/businessFinishRegister.requirements';
import {EstablishmentService} from '../establishment/establishment.service';
import {passwordRequirement} from '~/pages/init/sign-page/pages/signup-page/password.requirement';

@Injectable()
export class RequirementService extends AsyncPage {
  constructor(
    private userService: UserService,
    private extraService: ExtraService,
    private establishmentService: EstablishmentService,
    private worktypeService: WorktypeService,
    communication: CommunicationsService
  ) {
    super(communication);
  }

  async isUserIdentityVerified(user: User): Promise<RequirementResponse> {
    return this.checkRequirements(user, userVerifiedRequirement);
  }

  async isExtraReadyToApply(extra: Extra, contract: any): Promise<RequirementResponse> {
    const workTypesChosen: WorkTypeChosen[] = await this.send(this.worktypeService.getChoosenByExtraId(extra.id));
    return new Promise<RequirementResponse>(async resolve => {
      resolve(
        this.mergeRequirimentResponse([
          await this.checkRequirements(extra, extraApplyRequirement),
          await this.checkRequirements(
            new ExtraApplyOfferRequirementInputs(workTypesChosen, contract, extra),
            // TODO HERE, los requirements deben salir de los del workType no de forma estatica!
            WORKTYPE_REQUIREMENTS[contract.workType.id] || []
          )
        ])
      );
    });
  }

  isExtraReadyToFindOffer(extra: Extra): Promise<RequirementResponse> {
    return this.checkRequirements(extra, extraFindOfferRequirement);
  }

  didBusinessFisnishRegister(business: Business) {
    return this.checkRequirements(business, businessFinishRegister);
  }

  isBusinessReadyToCreateOffer(business: Business, establishment: Establishment): Promise<RequirementResponse> {
    return new Promise<RequirementResponse>(async resolve => {
      resolve(
        this.mergeRequirimentResponse([
          await this.checkRequirements(business, businessCreateOfferRequirements),
          await this.checkRequirements(establishment, establishmentCreateOfferRequirements)
        ])
      );
    });
  }

  async isBusinessReadyToHire(user: User): Promise<RequirementResponse> {
    return this.checkRequirements(user, userVerifiedRequirement);
  }

  private mergeRequirimentResponse(responses: RequirementResponse[] = []): RequirementResponse {
    const mergedResponse: RequirementResponse = new RequirementResponse();
    responses.map(response => {
      mergedResponse.mergeWithAnotherResponse(response);
    });
    return mergedResponse;
  }

  async isValidPassword(password: string): Promise<RequirementResponse> {
    return this.checkRequirements(password, passwordRequirement);
  }

  private async checkRequirements(input: any, requirements: Requirement[]): Promise<RequirementResponse> {
    return new Promise<RequirementResponse>(async resolve => {
      const response: RequirementResponse = new RequirementResponse();
      for (let i = 0; i < requirements.length; i++) {
        const requirement = requirements[i];
        if (!input || !requirement.service) {
          if (!(await requirement.rule(input))) {
            response.addFailedRequirements(requirement);
          }
        } else {
          let ruleResponse = await this.send(this[requirement.service][requirement.method](input) as Observable<any>);
          if (ruleResponse == undefined || !requirement.rule(ruleResponse)) {
            response.addFailedRequirements(requirement);
          }
        }
      }
      resolve(response);
    });
  }
}
