import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBackButtonComponent } from './nav-back-button.component';
import { IonicModule, IonRouterOutlet } from '@ionic/angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule],
  exports: [NavBackButtonComponent],
  declarations: [NavBackButtonComponent]
})
export class NavBackButtonComponentModule { }
