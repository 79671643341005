import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ElementRef, HostListener} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {BeLocation} from '@buscoextra/core';
import {GoogleMapsApiService} from '~/services/geolocation/lib/googleMapsApi/googleMapsApi.service';

declare var google;

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit {
  public addressInput: string;

  autocompleteItems;
  googleAutoCompleteService;
  detailService;
  updatingDataMap = false;
  saved = false;
  public locationChanged = false;
  alreadySelected = false;

  inputSelected = false;

  @Input() location: BeLocation = {} as BeLocation;
  @Input() title = 'Dirección';
  @Input() subTitle = 'Dirección del establecimiento...';
  @Output('onAddressChange') onAdrressChange: EventEmitter<BeLocation> = new EventEmitter();
  @Output('onFocus') onFocus: EventEmitter<void> = new EventEmitter();
  @ViewChild('serviceHelper', {static: true}) serviceElement;
  @Input() invalid = false;
  @Input() errorMessage = 'Campo no valido';

  constructor(private mapsAPILoader: MapsAPILoader, public ngZone: NgZone, private agmap: GoogleMapsApiService, private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.inputSelected) this.inputSelected = false;
    }
  }

  ngOnInit() {
    if (this.location && this.location.formattedAddress) {
      this.addressInput = this.location.formattedAddress;
      this.saved = true;
    }
    this.initAutocomplete();
  }

  UIonInputFocus(e) {
    this.onFocus.emit();
    this.inputSelected = true;
  }

  UIisPickerfound(): boolean {
    return this.addressInput && this.addressInput.length > 0 && this.autocompleteItems.length == 0 && !this.updatingDataMap && !this.saved;
  }

  initAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      this.googleAutoCompleteService = new google.maps.places.AutocompleteService();
      this.detailService = new google.maps.places.PlacesService(this.serviceElement.nativeElement);
    });
    this.autocompleteItems = [];
  }

  onAdressInputChange(input) {
    if (this.alreadySelected) {
      this.alreadySelected = false;
    } else {
      this.updateSearch();
    }
  }

  updateSearch() {
    this.updatingDataMap = true;
    if (!this.addressInput || this.addressInput == '') {
      this.autocompleteItems = [];
      return;
    }
    let that = this;
    let config = {
      types: ['geocode', 'establishment'], // other types available in the API: 'establishment', 'regions', and 'cities'
      input: this.addressInput,
      componentRestrictions: {}
    };
    this.googleAutoCompleteService.getPlacePredictions(config, (predictions, status) => {
      this.ngZone.run(() => {
        this.updatingDataMap = false;
      });
      that.autocompleteItems = [];
      if (status != 'ZERO_RESULTS') {
        predictions.forEach(function(prediction) {
          that.autocompleteItems.push(prediction);
        });
      }
    });
  }
  chooseItem(place) {
    this.getPlaceDetail(place.place_id);
  }

  getPlaceDetail(placeId) {
    this.detailService.getDetails({placeId: placeId}, (place, status) => {
      if (status == 'ZERO_RESULTS') {
        return;
      } else {
        this.location = this.agmap.getLocationFromgMapLocation(place);
        this.addressInput = this.location.formattedAddress;
        this.onAdrressChange.emit(this.location);
        this.autocompleteItems = [];
        this.alreadySelected = true;
        this.saved = true;
      }
    });
  }
}
