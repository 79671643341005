import {Requirement} from '@buscoextra/core';
import {exists, haveUppercaseChar, haveLowercaseChar} from '@buscoextra/services/requirement/tools/operations';

export const passwordRequirement: Requirement[] = [
  {
    id: 'mayusculasyminuscula',
    title: 'Letras mayúsculas y minúsculas',
    rule: (password: string): boolean => {
      return haveUppercaseChar(password) && haveLowercaseChar(password);
    }
  },
  {
    id: 'lotcharacters',
    title: '8 caracteres o más',
    rule: (password: string): boolean => {
      return password.length > 7;
    }
  }
  // {
  //   id: 'neednumber',
  //   title: 'Necesita un número',
  //   rule: (password: string): boolean => {
  //     return haveANumber(password);
  //   }
  // },
  // {
  //   id: 'needsimbol',
  //   title: 'Necesita un símbolo',
  //   rule: (password: string): boolean => {
  //     return /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(password);
  //   }
  // }
];
