import {Injectable} from '@angular/core';
import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {HeadersService} from '@buscoextra/core/services/api/services/headers.service';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {DeviceService} from '../device/device.service';
import {StateService} from '../state';

@Injectable()
export class FileManagerService {
  fileTransfer: FileTransferObject;
  constructor(
    private transfer: FileTransfer,
    private file: File,
    private fileOpener: FileOpener,
    private device: DeviceService,
    private state: StateService
  ) {
    this.fileTransfer = this.transfer.create();
  }

  download(url, name, extension) {
    return this.fileTransfer.download(url, this.file.dataDirectory + name + '.' + extension, true, {
      headers: {Authorization: 'Bearer ' + this.state.authToken},
    });
  }

  downloadFromBase64(b64, fileName) {
    let blob = this.b64toBlob(b64);
    let filePath = this.device.getOS() == 'Android' ? this.file.dataDirectory + fileName : this.file.cacheDirectory;

    console.log('downloadFromBase64', this.file.externalRootDirectory, this.file.cacheDirectory);
    this.file
      .writeFile(filePath, fileName, blob, {replace: true})
      .then(fileEntry => {
        this.fileOpener
          .open(fileEntry.toURL(), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          .then(() => console.log('File is opened'))
          .catch(err => console.error('Error openening file: ' + err));
      })
      .catch(err => {
        console.error('Error creating file: ', err);
        throw err;
      });
  }

  b64toBlob(b64Data, contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
