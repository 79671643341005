import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OtpModalComponent} from './otp-modal.component';
import {IonicModule} from '@ionic/angular';
import {SignatureServiceModule} from '@buscoextra/services/signature/signature.module';
import {FileManagerService} from '~/services';
import {ResourceServiceModule} from '@buscoextra/services/resource/resource-service.module';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LottieAnimationModule} from '~/services/lottie/lottie.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    SignatureServiceModule,
    ResourceServiceModule,
    FormsModule,
    BeInputValueAccessorModule,
    ReactiveFormsModule,
    LottieAnimationModule
  ],
  exports: [OtpModalComponent],
  declarations: [OtpModalComponent],
  providers: [FileManagerService]
})
export class OtpModalComponentModule {}
