import {NgModule} from '@angular/core';
import {RequirementService} from './requirement.service';
import {ResourceServiceModule} from '../resource/resource-service.module';
import {UserServiceModule} from '../user/user-service.module';
import {ExtraServiceModule} from '../user/extra/extra.module';

@NgModule({
  imports: [ResourceServiceModule, UserServiceModule, ExtraServiceModule],
  providers: [RequirementService]
})
export class RequirementServiceModule {}
