import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeLoadingComponent} from './be-loading.component';
import {LottieAnimationModule} from '~/services/lottie/lottie.module';

@NgModule({
  imports: [CommonModule, LottieAnimationModule],
  exports: [BeLoadingComponent],
  declarations: [BeLoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BeLoadingComponentModule {}
