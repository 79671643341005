import {Injectable} from '@angular/core';
import {Establishment} from '@buscoextra/core';
import {BEAction} from '../models/action';

@Injectable()
export class BEEstablishmentActions {
  static readonly UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT';

  update(establishment: Establishment): BEAction {
    return {
      type: BEEstablishmentActions.UPDATE_ESTABLISHMENT,
      payload: establishment
    };
  }
}
