import {NgModule} from '@angular/core';
import {UserService} from './user.service';
import {ResourceServiceModule} from '../resource/resource-service.module';
import {ApiServiceModule} from '@buscoextra/core/services/api';

@NgModule({
  imports: [ApiServiceModule, ResourceServiceModule],
  providers: [UserService]
})
export class UserServiceModule {}
