import {Injectable} from '@angular/core';
import {CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions} from '@ionic-native/camera-preview/ngx';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DeviceService} from '../device/device.service';
import {Camera, DestinationType, MediaType, PictureSourceType} from '@ionic-native/camera/ngx';

export enum CameraSourceType {
  CAMERA = PictureSourceType.CAMERA,
  GALERY = PictureSourceType.PHOTOLIBRARY
}

export enum CameraMediaType {
  PHOTO = MediaType.PICTURE,
  ALL = MediaType.ALLMEDIA
}

let cameraPreviewOpts: CameraPreviewOptions = {
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined,
  camera: 'back',
  tapPhoto: false,
  tapToFocus: true,
  previewDrag: false,
  toBack: false
};

let pictureOpts: CameraPreviewPictureOptions = {
  quality: 100
};

let CameraOptions = {
  destinationType: DestinationType.DATA_URL,
  sourceType: CameraSourceType.GALERY,
  allowEdit: false,
  mediaType: CameraMediaType.PHOTO
};

@Injectable()
export class CameraService {
  public cameraPreviewInstantiated = false;

  constructor(private cameraPreview: CameraPreview, private deviceService: DeviceService, private camera: Camera) {}

  startPreviewCamera(cameraWidth, cameraHeight, xPosition, yPosition) {
    if (cameraWidth) cameraPreviewOpts.width = cameraWidth;
    if (cameraHeight) cameraPreviewOpts.height = cameraHeight;
    if (xPosition) cameraPreviewOpts.x = xPosition;
    if (yPosition) cameraPreviewOpts.y = yPosition;

    if (this.deviceService.isCordova()) {
      return from(this.cameraPreview.startCamera(cameraPreviewOpts)).pipe(
        map(
          res => {
            this.cameraPreviewInstantiated = true;
            this.cameraPreview.show();
            return res;
          },
          catchError((err, caught) => {
            return throwError(err);
          })
        )
      );
    } else {
      return of(undefined);
    }
  }

  isCameraPreviewInstantiated() {
    return this.cameraPreviewInstantiated;
  }

  captureCameraPreviewPicture() {
    this.cameraPreview.hide();
    return this.cameraPreview.takePicture(pictureOpts);
  }

  showCameraPreview() {
    this.cameraPreview.show();
  }

  stopCameraPreview() {
    this.cameraPreview.stopCamera();
  }

  hideCameraPreview() {
    this.cameraPreview.hide();
  }

  cropImage(img, widthPct, heightPct, deviceAspectRatio = 0) {
    const imageAspectRatio = img.width / img.height;
    let aspectRatioIncrase = 1;
    if (deviceAspectRatio) {
      aspectRatioIncrase = Math.abs(imageAspectRatio - deviceAspectRatio);
      widthPct += aspectRatioIncrase;
      heightPct += aspectRatioIncrase;
    }

    const canvas: HTMLCanvasElement = document.createElement('canvas');

    const canvasWidth = img.width * widthPct;
    const canvasHeight = img.height * heightPct;
    const canvasStartX = (img.width - canvasWidth) / 2;
    const canvasStartY = (img.height - canvasHeight) / 2;

    canvas.setAttribute('width', canvasWidth + 'px');
    canvas.setAttribute('height', canvasHeight + 'px');

    const canvasCtx = canvas.getContext('2d');
    canvasCtx.drawImage(img, canvasStartX, canvasStartY, canvasWidth, canvasHeight, 0, 0, canvasWidth, canvasHeight);
    return canvas.toDataURL('image/jpeg');
  }

  pickFile(sourceType, mediaType): Observable<string> {
    let cameraOptions = {
      destinationType: this.camera.DestinationType.DATA_URL,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      allowEdit: false,
      mediaType: 0,
      targetWidth: 512,
      targetHeight: 512
    };

    return from(this.camera.getPicture(cameraOptions)).pipe(
      map(res => {
        return 'data:image/jpeg;base64,' + res;
      })
    );
  }
}
