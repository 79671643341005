import {NgModule} from '@angular/core';
import {ResourceService} from './resource.service';
import {ApiServiceModule} from '@buscoextra/core/services/api';

@NgModule({
  imports: [ApiServiceModule],
  exports: [],
  declarations: [],
  providers: [ResourceService]
})
export class ResourceServiceModule {}
