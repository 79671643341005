import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {Platform} from '@ionic/angular';
import {Mixpanel, MixpanelPeople} from '@ionic-native/mixpanel/ngx';
import {AEvent} from './models/event';
import {environment} from '~environment';
import {StateService} from '../state/state.service';
import {Facebook} from '@ionic-native/facebook/ngx';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private successFullyInit = false;
  private mixpanel;
  private segment;
  constructor(
    private platform: Platform,
    private mixpanelApi: Mixpanel,
    private mixpanelPeople: MixpanelPeople,
    private state: StateService,
    private fb: Facebook
  ) {}

  initializeServices() {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        from(this.mixpanelApi.init('f59845e4988ff0a1426cf29df2f60114')).subscribe(
          async res => {
            console.log('MIXPANEL - SUCCESS: MixPanel initialized succesfuly');
            this.successFullyInit = true;
            this.mixpanel = this.mixpanelApi;
            this.setDefaulParams();
          },
          error => {
            this.successFullyInit = false;
            console.log('ERROR: MixPanel not initialized', error);
          }
        );
      });
    } else {
      let that = this;
      if (!(window as any)['mixpanel']) {
        console.log('No hemos encontrado mixpanel en el window', window);
        return;
      }
      (window as any).mixpanel.init('f59845e4988ff0a1426cf29df2f60114', {
        loaded: function(mixpanel) {
          console.log('SUCCESS: MixPanel  initialized succesfuly');
          that.mixpanel = mixpanel;
          if (that.mixpanel) that.successFullyInit = true;
          that.setDefaulParams();
        }
      });
    }
  }

  getDistincID() {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.platform.is('cordova') ? this.mixpanel.distinctId() : this.mixpanel.get_distinct_id();
  }

  get isMixpanelInit() {
    return this.successFullyInit;
  }

  async aliasUser(userId: number): Promise<any> {
    return this.alias(userId.toString());
  }

  async setPushId(pushToken: string): Promise<any> {
    return this.setPushToken(pushToken);
  }

  async identifyUser(userId: number): Promise<any> {
    return this.indentify(userId.toString());
  }

  async setPeopleData(data: any): Promise<any> {
    return this.setPeople(data);
  }

  async startTimeTracking(eventTitle): Promise<any> {
    return this.timeEvent(eventTitle);
  }

  async setDefaulParams(): Promise<any> {
    return new Promise(async resolve => {
      let properties = {
        product: this.platform.is('cordova') ? 'App' : 'Web',
        environment: environment.addressName,
        productVersion: environment.version
      };
      await this.registerSuperProperties(properties);
      if (this.state.isUserLogged() && this.state.userId) await this.setUserDefaultProperties();
      resolve();
    });
  }

  async setUserDefaultProperties(): Promise<any> {
    return new Promise(async resolve => {
      let properties = {
        userId: this.state.userId,
        userType: this.state.userType
      };
      await this.registerSuperProperties(properties);
      resolve();
    });
  }

  async trackEvent(event: AEvent): Promise<any> {
    return new Promise(async resolve => {
      if (this.isMixpanelInit) {
        console.log('√ - MX: ', event.title);
        await this.track(event.title, event.content);
        await this.trackFb(event.facebookEvent);
      } else {
        console.log('X - MX: ', event.title);
      }
      resolve();
    });
  }

  private async getDistinctId() {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.mixpanel.distinctId();
  }

  private indentify(data) {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.mixpanel.identify(data, true);
  }

  private alias(data) {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.mixpanel.alias(data);
  }

  private async registerSuperProperties(data) {
    console.log('Voy a registar super propiedades', data, this.successFullyInit);
    if (!this.successFullyInit) return new Promise(r => r());
    return this.platform.is('cordova') ? await this.mixpanel.registerSuperProperties(data) : this.mixpanel.register(data);
  }

  private async setPeople(data) {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.platform.is('cordova') ? this.mixpanelPeople.set(data) : this.mixpanel.people.set(data);
  }

  private async setPushToken(pushToken) {
    if (!this.successFullyInit || !pushToken) return new Promise(r => r());
    return this.platform.is('cordova') ? this.mixpanelPeople.setPushId(pushToken) : this.mixpanel.people.set({token: pushToken});
  }

  private async timeEvent(data) {
    if (!this.successFullyInit) return new Promise(r => r());
    return this.platform.is('cordova') ? this.mixpanel.timeEvent(data) : this.mixpanel.time_event(data);
  }

  private async track(title, content) {
    if (!this.successFullyInit) return new Promise(r => r());
    return new Promise(async resolve => {
      await this.mixpanel.track(title, content);
      resolve();
    });
  }

  private async trackFb(event) {
    if (!this.successFullyInit || !event) return new Promise(r => r());
    return new Promise(async resolve => {
      await this.fb.logEvent(event);
      resolve();
    });
  }
}
