import {Requirement} from '@buscoextra/core';
import {Extra} from '@buscoextra/core';
import {exists} from '../tools/operations';

export const extraApplyRequirement: Requirement[] = [
  {
    id: 'iban',
    title: 'Necesita un IBAN',
    rule: (extra: Extra): boolean => {
      return exists(extra.iban);
    }
  },
  {
    id: 'verifiedProfile',
    title: 'Necesita verificar el perfil',
    rule: (extra: Extra): boolean => {
      return exists(extra.verifiedProfile) && extra.verifiedProfile === true;
    }
  },
  {
    id: 'verifiedEmail',
    title: 'Necesita verificar el email',
    rule: (extra: Extra): boolean => {
      return exists(extra.verifiedEmail) && extra.verifiedEmail === true;
    }
  },
  {
    id: 'finishStudies',
    title: 'Necesita decirme tus estudios',
    rule: (extra: Extra): boolean => {
      return exists(extra.finishedStudies);
    }
  },
  {
    id: 'civilStatus',
    title: 'Necesita decirme civilStatus',
    rule: (extra: Extra): boolean => {
      return exists(extra.civilStatus);
    }
  },
  {
    id: 'hasChildren',
    title: 'Necesita decirme hasChildren',
    rule: (extra: Extra): boolean => {
      return exists(extra.hasChildren);
    }
  },
  {
    id: 'socialSecurity',
    title: 'Necesita decirme socialSecurity',
    rule: (extra: Extra): boolean => {
      return exists(extra.socialSecurity);
    }
  }
];
