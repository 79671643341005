import {Component, Input, ViewChild} from '@angular/core';
import {DialogService, NavigationService, StateService} from '~/services';
import {IdCardCaptureComponent} from '~/components/id-card-capture/id-card-capture.component';
import {ModalController} from '@ionic/angular';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';

@Component({
  selector: 'id-card-page',
  templateUrl: './id-card.page.html',
  styleUrls: ['./id-card.page.scss']
})
export class IdCardPage extends AsyncPage {
  @ViewChild('idCaptureComponent', {static: false}) idCaptureComponent: IdCardCaptureComponent;
  @Input() title;
  @Input() subTitle = 'Recuerda que este documento es necesario para llevar a cabo el contrato. Tus datos están protegidos con nosotros.';
  idImage;
  @Input() tag: string;
  cameraPreviewStartY = 250;
  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private dialog: DialogService,
    private modalCtrl: ModalController
  ) {
    super(communications);
  }

  ionViewWillLeave() {
    this.idCaptureComponent.closeCamera();
  }

  async ngOnInit() {
    // this.idCard = await this.send(this.idCardService.getAllIdCard(),'idCard');
  }

  capture() {
    this.idCaptureComponent.captureImage();
  }

  imageCaptured(image) {
    this.idCaptureComponent.hideCamera();
    this.idImage = image;
  }

  retake() {
    this.idCaptureComponent.showCamera();
    this.idImage = undefined;
  }

  finish() {
    this.modalCtrl.dismiss(this.idImage);
  }

  dismiss() {
    this.modalCtrl.dismiss(undefined);
  }

  getLayerPicture() {
    if (this.tag) {
      if (this.tag.indexOf('dniBack') != -1) {
        return 'assets/id-card-capture/id-back-guide.png';
      } else {
        return 'assets/id-card-capture/id-front-guide.png';
      }
    }
    return;
  }
}
