import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IdCardPage} from './id-card.page';
import {IdCardRoutingModule} from './id-card.routing';
import {IonicModule} from '@ionic/angular';
import {IdCardCaptureModule} from '~/components/id-card-capture/id-card-capture-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, IdCardRoutingModule, IdCardCaptureModule],
  exports: [IdCardPage],
  declarations: [IdCardPage]
})
export class IdCardPageModule {}
