import {NgModule} from '@angular/core';
import player from 'lottie-web';
import {CommonModule} from '@angular/common';
import {LottieModule, LottieComponent} from 'ngx-lottie';

// Note we need a separate function as it's required
// by the AOT compiler
export function playerFactory() {
  return player;
}
@NgModule({
  imports: [CommonModule, LottieModule.forRoot({player: playerFactory})],
  declarations: [],
  exports: [LottieComponent],
  bootstrap: []
})
export class LottieAnimationModule {}
