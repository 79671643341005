import {Resource, WorkTypeChosen} from '@buscoextra/core';
import {Requirement} from '@buscoextra/core';

export const worktypeRepartidorMotoRequirement: Requirement[] = [
  {
    id: 'driverLicenseFront',
    service: 'extraService',
    method: 'getFrontDriveLicense',
    title: 'Parte trasera carnet de conducir',
    rule: (resource: Resource): boolean => {
      return resource as any;
    }
  },
  {
    id: 'driverLicenseBack',
    service: 'extraService',
    method: 'getBackDriveLicense',
    title: 'Parte trasera carnet de conducir',
    rule: (resource: Resource): boolean => {
      return resource as any;
    }
  },
  {
    id: 'worktypeValid',
    service: 'worktypeService',
    method: 'getAChoosenByRequirimentInput',
    title: 'Estamos validando el worktype',
    rule: (workTypeChosen: WorkTypeChosen): boolean => {
      return workTypeChosen.verified;
    }
  }
];
