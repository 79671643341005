import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {isValidNif} from '@buscoextra/core';

@Directive({
  selector: '[dniValidator]'
})
export class DniValidator {
  constructor() {}

  @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  dni = '';
  @Output() dniNumberChange = new EventEmitter();
  @Input()
  get dniNumber() {
    return this.dni;
  }
  set dniNumber(val) {
    this.dni = val;
    this.isValid.emit(isValidNif(val));
    this.dniNumberChange.emit(this.dni);
  }
}
