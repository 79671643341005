import {Business} from '@buscoextra/core';
import {deleteEmptyArrays} from './tools/mappers-tools';

export function BusinessForServer(client_business: Business) {
  let server_business: any = client_business;
  delete server_business['agreement'];
  delete server_business['establishmentType'];
  delete server_business.id;
  return deleteEmptyArrays(server_business);
}

export function BusinessForClient(server_business) {
  let client_business: Business = server_business;
  return client_business;
}
