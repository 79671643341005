import {Injectable} from '@angular/core';
import {Resource, WorkType, WorkTypeChosen, WorkTypeApi, ExtraApi, WorkTypeChosenApi, SkillApi} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Skill} from '@buscoextra/core';
import {WorkTypeRequirements} from '@buscoextra/core';
import {ResourceService} from '../resource/resource.service';
import {UserService} from '../user/user.service';
import {ExtraApplyOfferRequirementInputs} from '../requirement/requiriments/extraApplyOffer.requirement';
import {ApiService} from '@buscoextra/core/services/api';

@Injectable()
export class WorktypeService {
  constructor(private api: ApiService, private resource: ResourceService, private user: UserService) {}

  getAll(): Observable<WorkType[]> {
    return this.api.apiRequestV2(Entities.WorkType, WorkTypeApi.getPublicsWorkTypes());
  }

  getChoosenByExtraId(extraId: number): Observable<WorkTypeChosen[]> {
    return this.api.apiRequestV2(Entities.WorkTypeChosen, ExtraApi.getExtraWokrTypeChoosens(extraId));
  }

  getAChoosenByRequirimentInput(requirementInput: ExtraApplyOfferRequirementInputs): Observable<WorkTypeChosen> {
    return this.getChoosenByExtraId(requirementInput.extra.id).pipe(
      map(workTypesChosen => {
        return workTypesChosen.find(workTypeChosen => {
          return workTypeChosen.workType.id === requirementInput.offer.workType.id;
        });
      })
    );
  }

  getEnabledChoosenByExtraId(extraId: number): Observable<WorkTypeChosen[]> {
    return this.api.apiRequestV2(Entities.WorkTypeChosen, ExtraApi.getExtraEnabledWokrTypeChoosens(extraId));
  }

  getRequirementsActualValues(userId: number, requirements: WorkTypeRequirements[]): Observable<any[]> {
    let auxRequest = [
      ...requirements.map((req: WorkTypeRequirements) => {
        if (req.type == 'resource') {
          return this.resource.getUserResourceUrlByTag(userId, req.tag).pipe(
            map((res: Resource) => {
              if (res) return {value: res.url, id: res.id};
              return {value: undefined, id: undefined};
            })
          );
        } else if (req.type == 'boolean') {
          return this.user.getUserVariableValue(userId, req.tag).pipe(
            map(res => {
              return {value: res, id: undefined};
            })
          );
        } else {
          return of({value: undefined, id: undefined});
        }
      })
    ];
    return forkJoin(auxRequest);
  }

  getSkillsByWorkTypeId(workTypeId): Observable<Skill[]> {
    return this.api.apiRequestV2(Entities.Skill, SkillApi.getSkillsByWorkTypeId(workTypeId));
  }

  getAllSkills() {
    return this.api.apiRequestV2(Entities.Skill, SkillApi.getSkills());
  }

  getById(id) {
    return this.api.apiRequestV2(Entities.WorkTypeChosen, WorkTypeChosenApi.getWorkTypeChosenById(id));
  }

  workTypeChoosenIsReadyToVerify(id) {
    let data = {readyToVerify: true};
    return this.api.apiRequestV2(Entities.WorkTypeChosen, WorkTypeChosenApi.updateWorkTypeChosen({id: id, ...data} as any));
  }
  veifyWorkTypeChoosen(id) {
    let data = {verify: true};
    return this.api.apiRequestV2(Entities.WorkTypeChosen, WorkTypeChosenApi.updateWorkTypeChosen({id: id, ...data} as any));
  }
}
