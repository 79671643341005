import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {isValidCif} from '@buscoextra/core';

@Directive({
  selector: '[cifValidator]'
})
export class CifValidator {
  constructor() {}

  @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  cif = '';
  @Output() cifNumberChange: EventEmitter<string> = new EventEmitter();
  @Input()
  get cifNumber() {
    return this.cif;
  }
  set cifNumber(val) {
    this.cif = val;
    this.cifNumberChange.emit(this.cif);
    let aux = isValidCif(val);
    this.isValid.emit(aux);
  }
}
