import {NgModule} from '@angular/core';
import {GoogleMapsApiService} from './googleMapsApi.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  declarations: [],
  providers: [GoogleMapsApiService]
})
export class GoogleMapsApiServiceModule {}
