import {Injectable} from '@angular/core';
import {CustomError} from '../../models/custom-error';
import {DialogService} from '~/services';

@Injectable({
  providedIn: 'root'
})
export class LoginErrorsService {
  constructor(private dialog: DialogService) {}

  onGetError(error: CustomError) {
    if (error.httpError.status === 404) {
      this.dialog.toast('Usuario incorrecto');
    }
    if (error.httpError.status === 400) {
      this.dialog.toast('Contraseña incorrecta');
    }
    if (error.httpError.status === 409) {
      this.dialog.toast('Usuario incorrecto');
    }
  }
}
