import {Injectable, isDevMode} from '@angular/core';
import {LoginErrorsService} from './errors/login.errors.service';
import {EstablishmentErrorsService} from './errors/establishments.errors.service';
import {CustomError} from '../models/custom-error';
import {AuthService} from '../../auth/auth.service';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {CompanyErrorsService} from './errors/companies.errors.service';
import {SentryService} from './lib/sentry/sentry.service';
import {DialogService, NavigationService, StateService} from '~/services';
import {ApiService} from '../../api';
import {Entities} from '@buscoextra/core';
@Injectable()
export class ErrorsService {
  constructor(
    private login: LoginErrorsService,
    private offers: LoginErrorsService,
    private auth: AuthService,
    private dialog: DialogService,
    private api: ApiService,
    private navigation: NavigationService,
    private establishments: EstablishmentErrorsService,
    private companies: CompanyErrorsService,
    private sentry: SentryService,
    private state: StateService
  ) {
    this.sentry.initSentry();
    if (this.state.isUserLogged() && this.state.userExist()) this.sentry.setContextVariables({id: this.state.userId});
  }

  manageErrorsByKey(error: CustomError, key: string): Observable<HttpErrorResponse> {
    if (isDevMode()) {
      this.dialog.toast(`📟 ERROR ${error.httpError.status}:  ${error.request.url}`);
    }

    if (key == 'login' && [409, 404, 400].indexOf(error.httpError.status) !== -1) {
      return throwError(error.httpError);
    }

    if (key == 'newUser' && [409].indexOf(error.httpError.status) !== -1) {
      return throwError(error.httpError);
    }

    if ([500, 403, 400, 418, 423].indexOf(error.httpError.status) !== -1) {
      this.sentry.setContextVariables({id: this.state.userId, email: this.state.user.email});
      this.sentry.notifyError(error.request.url, error.request.body, error.httpError);
    }

    if (this[key]) this[key].onGetError(error);

    if (error.httpError.status == 0) {
      this.dialog.toast('📡 No tienes internet');
      return throwError(error.httpError);
    }

    if (error.httpError.status === 401) {
      return this.auth.refreshSession().pipe(
        switchMap(() => {
          return this.api.apiRequest(error.entityName, error.request);
        }),
        catchError((err: CustomError, cuaght: Observable<any>) => {
          return this.manageErrorsByKey(err, key);
        })
      );
    } else if (error.httpError.status === 498) {
      this.dialog.toast('Tu sesión caducó');
      this.state.finishSession();
      this.navigation.jumpTo('');

      return throwError(error.httpError);
    } else {
      return throwError(error.httpError);
    }
  }
}
