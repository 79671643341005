import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { EstablishmentService, WorktypeService, UserService } from '@services';
import { LocationService } from '@buscoextra/services/location/location.service';
import { UserType, EstablishmentType, WorkType, Lead, BeLocation, UserSignupRequest } from '@buscoextra/core';
import { MustMatch } from '../../sign-page/pages/signup-page/must-validator.validator';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { FormPage } from '@buscoextra/core/services/communications/wrappers/form-page.wrapper';
import { CommunicationsService } from '@buscoextra/core/services';
import { ErrorsService } from '@buscoextra/core/services/communications/services/errors.service';

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss']
})
export class LeadModalComponent extends FormPage {

  public form: FormGroup;
  public lead: Lead;

  chargeTypes = [
    'Director Ejecutivo',
    'Director de Hotel',
    'Director de RRHH',
    'Director de Operaciones',
    'Director de Ventas',
    'Director de Compras',
    'Maitre',
    'Otro'
  ];
  establishmentTypes: EstablishmentType[];
  teamSizes = ['1-10', '11-25', '26-50', '50+'];
  worktypes: WorkType[];

  constructor(
    communications: CommunicationsService,
    private formBuilder: FormBuilder,
    private establishmentService: EstablishmentService,
    private worktypeService: WorktypeService,
    private locationService: LocationService,
    private navParams: NavParams,
    private userService: UserService,
    private modalCtrl: ModalController,
    public toastController: ToastController
  ) { super(communications); }

  ngOnInit() {
    this.getEstablishmentTypes();
    this.getWorktypes();
    this.createForm();
  }

  async getWorktypes() {
    this.worktypeService.getAll().subscribe(res => {
      this.worktypes = res;
    });
  }
  async getEstablishmentTypes() {
    let otros = {
      id: -1,
      name: "Otro"
    }
    this.establishmentService.getAvailableEstablishmentTypes().subscribe(res => {
      this.establishmentTypes = res.filter(el => el.id === 1 || el.id === 6 || el.id === 11);
      this.establishmentTypes.push(otros);

    });
  }
  async onChangeLocation(location: BeLocation) {
    this.form.controls.province.setValue(location.province);
    this.form.controls.city.setValue(location.province);
    this.form.get('province').markAsDirty();
    this.form.get('city').markAsDirty();
  }

  async sendLead() {
    this.form.value.workTypeRequiredIds = [this.form.value.workTypeRequiredIds];
    let postLead = await this.send(this.userService.createLead(this.form.value), 'lead');

    if (postLead !== null) this.modalCtrl.dismiss();
    else this.presentToast();
  }


  createForm() {
    this.form = this.formBuilder.group({
      name: new FormControl(this.navParams.get('name'), {
        validators: [Validators.required]
      }),
      email: new FormControl(this.navParams.get('email'), {
        validators: [Validators.required, Validators.email]
      }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)]
      }),
      charge: new FormControl(undefined, {
        validators: [Validators.required]
      }),
      establishmentType: new FormControl(undefined, {
        validators: [Validators.required, Validators.minLength(1)]
      }),
      teamSize: new FormControl(undefined, {
        validators: [Validators.required]
      }),
      workTypeRequiredIds: new FormControl(undefined, {
        validators: [Validators.required]
      }),
      province: new FormControl('', {
        validators: [Validators.required]
      }),
      city: new FormControl('', {
        validators: [Validators.required]
      }),
      origin: new FormControl('app', {
        validators: [Validators.required]
      }),
      firstTimeETT: new FormControl(false, {
        validators: [Validators.required]
      })
    });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Ha habido un error en el registro de los datos!',
      duration: 2000
    });
    toast.present();
  }
}
