import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BeApiRequest, Extra, UserType, WorkType} from '@buscoextra/core';
import {Skill} from '@buscoextra/core';
import {EXTRA_CARD_SETTINGS} from './be-extra-card.setting';
import {NavigationService, StateService} from '~/services';
import {ApiService} from '@buscoextra/core/services/api';

@Component({
  selector: 'be-extra-card',
  templateUrl: './be-extra-card.component.html',
  styleUrls: ['./be-extra-card.component.scss'],
})
export class BeExtraCardComponent implements OnInit {
  @Input() src: Extra = {} as Extra;

  @Input() primaryBtn: string;
  @Input() secondBtn: string;
  @Input() isFavourite: boolean = false;
  @Input() enableFavorite = false;
  @Input() distance;
  @Input() email;
  @Input() disableCardStyle = false;
  @Input() extendedInfo = false;
  @Output('onSecondBtn') secondEmitter: EventEmitter<Extra> = new EventEmitter();
  @Output('onPrimaryBtn') primaryEmitter: EventEmitter<Extra> = new EventEmitter();
  @Output('onFavoriteBtn') favoriteEmitter: EventEmitter<Extra> = new EventEmitter();
  @Output('onViewDetail') viewDetail: EventEmitter<Extra> = new EventEmitter();

  UIisImageZoomed = false;
  UIhiddenSkills: number;
  UImaxSkillsLenght = EXTRA_CARD_SETTINGS.maxSkillsLenght;

  favorites = [];

  constructor(private navigation: NavigationService, private state: StateService, private api: ApiService) {}

  ngOnInit() {
    this.getExtraFavorites();

    if (this.extendedInfo) {
      this.UImaxSkillsLenght = 99999;
    } else {
      if (this.src.skills) this.UIhiddenSkills = this.src.skills.length - EXTRA_CARD_SETTINGS.maxSkillsLenght;
    }
  }

  getExtraFavorites() {
    let beApiRequest: BeApiRequest;
    beApiRequest = {
      url: `/api/cpd_favorites/${this.src?.id}/extras`,
      type: 'GET',
    };

    this.api.apiRequestV2('cdp_favorites', beApiRequest).subscribe(response => {
      this.favorites = response.cpds;
    });
  }

  addFavorite(establishment: string) {
    let beApiRequest: BeApiRequest = {
      url: `/api/favorites/`,
      type: 'POST',
      body: {
        extra_id: this.src?.id,
        establishment_id: establishment,
      },
    };

    this.api.apiRequestV2('favorites', beApiRequest).subscribe(response => {
      this.getExtraFavorites();
    });
  }

  removeFavorite(favorite_id) {
    let beApiRequest: BeApiRequest = {
      url: `/api/favorites/${favorite_id}`,
      type: 'DELETE',
    };

    this.api.apiRequestV2('favorites', beApiRequest).subscribe(response => {
      this.getExtraFavorites();
    });
  }

  onExtraDetail(extraID) {
    if (this.state.userType == UserType.Business) this.navigation.goto('business/my-extras/detail/' + extraID);
    else this.navigation.goto('extras/my-profile/my-account');
  }

  UIonSecondaryBtnPressed(event: Event, extra: Extra) {
    event.stopPropagation();
    this.secondEmitter.emit(extra);
  }

  UIonPrimaryBtnPressed(event: Event, extra: Extra) {
    event.stopPropagation();
    this.primaryEmitter.emit(extra);
  }

  UIonExtraProfile(event: Event, cardHtml: any, extraProfileImage: HTMLElement) {
    // if (!this.extendedInfo) return;
    event.stopImmediatePropagation();
    if (!this.profileResource) return;
    if (!this.UIisImageZoomed) {
      extraProfileImage.style.width = `${cardHtml.el.offsetWidth}px`;
      extraProfileImage.style.height = `${cardHtml.el.offsetHeight}px`;
    } else {
      extraProfileImage.style.width = `50px`;
      extraProfileImage.style.height = `50px`;
    }
    this.UIisImageZoomed = !this.UIisImageZoomed;
  }

  get profileResource() {
    if (this.src && this.src.profileResource && this.src.profileResource.url) {
      return this.src.profileResource.url;
    }
    return 'assets/default-user.svg';
  }

  get languages() {
    if (this.src && this.src.languages && this.src.languages.length > 0)
      return this.src.languages
        .map(language => {
          return language.name;
        })
        .toString();
    return undefined;
  }

  UIOnDetailPressed(src) {
    this.viewDetail.emit(src);
  }
}
