import {Injectable} from '@angular/core';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Observable, of, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeviceService} from '../device/device.service';
import {GoogleMapsApiService} from './lib/googleMapsApi/googleMapsApi.service';
import {BeLocation} from '@buscoextra/core';
import {calcDistance, Point} from '@buscoextra/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor(private geolocation: Geolocation, private deviceService: DeviceService, private gMapsApi: GoogleMapsApiService) {}

  getDeviceLocation(): Observable<any> {
    if (this.deviceService.isCordova()) {
      return from(this.geolocation.getCurrentPosition()).pipe(
        map((position: any) => {
          return {lat: position.coords.latitude, lng: position.coords.longitude};
        })
      );
    } else {
      if (navigator.geolocation) {
        return from(
          new Promise(resolve => {
            navigator.geolocation.getCurrentPosition(resolve);
          })
        ).pipe(
          map((position: any) => {
            return {lat: position.coords.latitude, lng: position.coords.longitude};
          })
        );
      } else {
        return of(undefined);
      }
    }
  }

  getLocationFromLatLng(lat, lng): Observable<any> {
    return this.gMapsApi.getLocationFromLatLng(lat, lng);
  }

  getStaticMapImg(location: BeLocation): string {
    return this.gMapsApi.getStaticMapImage(location);
  }

  getCoordenadesKmDistance(point: Point, point2: Point) {
    return calcDistance(point, point2);
  }
}
