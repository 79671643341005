import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CpdStatus, Extra, Offer, Service} from '@buscoextra/core';
import {CpdService} from '@buscoextra/services/cpd/cpd.service';
import {calculateBusinessPricePerHourFromOffer} from '@buscoextra/core';
import {GetStatus} from '@buscoextra/components/other/contract-status/contract-status.setting';
import {OfferStatus} from '@buscoextra/core';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {StateService, DialogService} from '~/services';
import {PopoverController} from '@ionic/angular';
import {POP_OVER_MENU_ACTIONS} from './be-business-offer-card.setting';

@Component({
  selector: 'business-offer-card',
  templateUrl: './be-business-offer-card.component.html',
  styleUrls: ['./be-business-offer-card.component.scss'],
})
export class BeBusinessOfferCardComponent extends AsyncPage {
  @Input() src: Offer;
  @Output('onDetail') detailOfferEmitter: EventEmitter<Offer> = new EventEmitter();
  @Output('onExtras') extrasOfferEmiter: EventEmitter<Offer> = new EventEmitter();
  @Output('onEdit') editOfferEmitter: EventEmitter<Offer> = new EventEmitter();
  @Output('onRemove') removeOfferEmitter: EventEmitter<Offer> = new EventEmitter();
  @Output('onArchive') archiveEmitter: EventEmitter<Offer> = new EventEmitter();

  extrasOffer: Extra[] = [];
  extrasApplied = [];

  constructor(
    communication: CommunicationsService,
    private cpdService: CpdService,
    private state: StateService,
    private dialog: DialogService,
    public popoverController: PopoverController
  ) {
    super(communication);
  }

  async UIonOfferMenuBtnPressed(ev: Event) {
    let popOverResponse = await this.dialog.presentPopOverMenu(POP_OVER_MENU_ACTIONS, ev);
    if (!popOverResponse.data) return;
    this[popOverResponse.data.methodName]();
  }

  UIonShowDetailBtnPressed(offer: Offer = this.src) {
    if (this.extrasApplied) offer.nUsersApplied = this.extrasApplied.length;
    this.detailOfferEmitter.emit(offer);
  }

  UIonViewExtrasBtnPressed(offer: Offer = this.src) {
    this.extrasOfferEmiter.emit(offer);
  }

  UIonEditOFferBtnPressed(offer: Offer = this.src) {
    this.editOfferEmitter.emit(offer);
  }

  UIonRemoveOfferBtnPressed(offer: Offer = this.src) {
    this.removeOfferEmitter.emit(offer);
  }

  UIonStackedExtrasComponentPressed(offer: Offer = this.src) {
    this.detailOfferEmitter.emit(offer);
  }

  async ngOnInit() {
    this.extrasOffer = (await this.send(this.cpdService.getExtrasProfileResources(this.src?.id), 'gettingCpdExtras')) || [];
    this.extrasApplied = await this.send(this.cpdService.getOfferCpdsByStatus(this.src?.id, '')); //mandamos null como estado (segundo parametro) para recuperar todos los cpds independientemente de su estado
  }

  getOfferService() {
    return this.src.offerPriceTime && (this.src.offerPriceTime.service as Service);
  }

  get nWorkersWantet(): number {
    if (this.src) return this.src.nWorkersWanted;
  }

  get worktype(): string {
    return this.src.nWorkersWanted == 1 ? this.src.workType.name : this.src.workType.namePlural;
  }

  get extraSalary(): string {
    if (!this.getOfferService()) return;
    if (this.src.retailPriceForcedValue) {
      return this.src.retailPriceForcedValue;
    }
    switch (+this.getOfferService().timingFormatType) {
      case 0:
        return Math.round(this.src.priceBeforeTaxes * 100) / 100 + '€';
      case 1:
        return calculateBusinessPricePerHourFromOffer(this.src) + '€/h';
      case 2:
        return calculateBusinessPricePerHourFromOffer(this.src) + '€';
    }
  }

  get status() {
    return GetStatus(this.src.status, this.state.userType, true);
  }

  canFindExtras() {
    return (
      this.src.status != OfferStatus.COMPLETED &&
      this.src.status != OfferStatus.FINISHED &&
      this.src.status != OfferStatus.SUCCEED &&
      this.src.status != OfferStatus.PENDING
    );
  }

  canArchive() {
    return this.src.status != OfferStatus.SUCCEED && this.src.status != OfferStatus.PENDING;
  }

  UIOnOfferArchive(src: Offer) {
    this.archiveEmitter.emit(src);
  }
}
