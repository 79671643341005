import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../navigation.service';
import {StateService} from '~/services/state';
import {UserType} from '@buscoextra/core';

@Injectable()
export class IsLogged implements CanActivate {
  constructor(private router: Router, private navigationService: NavigationService, private stateService: StateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.stateService.authToken && this.stateService.user && this.stateService.userId) {
      if (this.stateService.userType == UserType.Business) {
        return this.router.parseUrl('business');
      } else {
        return this.router.parseUrl('extras');
      }
    } else {
      return true;
    }
  }
}
