import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BeBusinessOfferCardComponent } from './be-business-offer-card.component';
import { BeStackedExtrasCardComponentModule } from '../be-stacked-extras-card/be-stacked-extras-card-component.module';
import { CpdServiceModule } from '@buscoextra/services/cpd/cpd.module';
import { FormsModule } from '@angular/forms';
import { TimingCardComponentModule } from '../timing-card/timing-card-component.module';
import { ContractResumeCardComponentModule } from '../contract-resume-card/contract-resume-card-component.module';
import { OfferCardMenuComponentModule } from './components/offer-card-menu/offer-card-menu.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    OfferCardMenuComponentModule,
    FormsModule,
    CpdServiceModule,
    BeStackedExtrasCardComponentModule,
    TimingCardComponentModule,
    ContractResumeCardComponentModule
  ],
  exports: [BeBusinessOfferCardComponent],
  declarations: [BeBusinessOfferCardComponent]
})
export class BeBusinessOfferCardComponentModule { }
