import {NgModule} from '@angular/core';
import {NotificationService} from './notification.service';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';

@NgModule({
  imports: [
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: 'BEXJWfnquvSwcU6rDmEbRtCzzU5Tcjp5cmBchWv2i0G3I_03Spqr-X63ym_H0LU5w6JL6NzfQAvL9Gj9wGUCYEg',
      projectId: 'busco-extra-ed2ab',
      messagingSenderId: '437545253590'
    })
  ],
  exports: [],
  declarations: [],
  providers: [NotificationService]
})
export class NotificationServiceModule {
  constructor(notiService: NotificationService) {
    notiService.setup();
  }
}
