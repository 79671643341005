import {Component, Input, OnInit} from '@angular/core';

type Position = 'full' | 'relative' | 'floating';

@Component({
  selector: 'be-loading',
  templateUrl: './be-loading.component.html',
  styleUrls: ['./be-loading.component.scss']
})
export class BeLoadingComponent implements OnInit {
  @Input() title: string;
  @Input() position: Position = 'relative';

  constructor() {}

  ngOnInit() {}
}
