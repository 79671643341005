import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AgmMap, LatLngBounds } from '@agm/core';
import { DistanceByScale } from './models/agm';
import { MapArea } from './models/area';
import { Subscription } from 'rxjs';

@Component({
  selector: 'be-area-picker',
  templateUrl: './area-picker.component.html',
  styleUrls: ['./area-picker.component.scss']
})
export class AreaPickerComponent implements OnInit, OnDestroy {
  @ViewChild('agmMap', { static: true }) agmMap: AgmMap;
  @Input() lat: number = 40.4381311;
  @Input() lng: number = -3.8196198;
  zoom = 12;
  @Output() areaChange: EventEmitter<MapArea> = new EventEmitter();
  public area: MapArea = {} as MapArea;

  private mapSubcriber: Subscription;
  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.lat) this.lat = 40.4381311;
    if (!this.lng) this.lng = -3.8196198;
    this.initMap();
  }

  initMap() {
    this.mapSubcriber = this.agmMap.mapReady.subscribe(
      (map: AgmMap) => {
        map.zoom = this.zoom;
        this.calculateDistanceByZoom(this.zoom);
      },
      error => {
        console.log('cant Init Map', error);
      }
    );

    this.agmMap.boundsChange.subscribe(res => {
      this.onMapBoundsCange(res);
    });
    this.agmMap.zoomChange.subscribe(res => {
      this.onMapZoomChange(res);
    });
  }

  onMapBoundsCange(res: LatLngBounds) {
    this.area.latitude = res.getCenter().lat();
    this.area.longitude = res.getCenter().lng();
    this.emitAreaChange();
  }

  onMapZoomChange(zoom: number) {
    this.calculateDistanceByZoom(zoom);
    this.emitAreaChange();
  }

  calculateDistanceByZoom(zoom: number) {
    this.area.radius = Math.round(DistanceByScale['scale' + zoom] / (20100 * 2));
  }

  emitAreaChange() {
    this.areaChange.emit(this.area);
  }

  ngOnDestroy() {
    this.mapSubcriber.unsubscribe();
  }
}
