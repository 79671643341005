import {Injectable} from '@angular/core';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {NavigationService} from '../navigation/navigation.service';
import {DeviceService} from '../device/device.service';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {
  constructor(protected deeplinks: Deeplinks, private navigationService: NavigationService, private deviceService: DeviceService) {}

  initDeepLinkListener() {
    if (this.deviceService.isCordova()) {
      this.deeplinks
        .route({
          '/extras/offers-finder/:id': 'offer-detail',
          '/my-offers/detail/:offerId/extra/:extraID/cpd/:cpdId': 'extra-detail',
          '/business/my-offers/detail/:offerId/in-progress': 'in-progress-extras',
          '/business/my-offers/detail/:offerId/hired': 'hired-extras',
          '/business/new-offer': 'new-offer',
          '/extras/offers-finder/:offerId': 'offer-detail',
          '/business/my-offers': 'my-offers',
          '/extras/my-profile/work-docs': 'extra-work-docs',
          '/extras/my-profile/work-profile/my-cv': 'extra-cv',
          '/extras/my-profile/my-account': 'extra-account',
          '/business/my-profile/my-establishments/:establishmentID/documents':'establishment-documents',
          '/business/my-profile/my-establishments/new':'new-establishment'
        })
        .subscribe(
          match => {
            switch (match.$route) {
              case 'offer-detail':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'extra-detail':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'in-progress-extras':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'hired-extras':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'new-offer':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'offer-detail':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'my-offers':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'extra-work-docs':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'extra-cv':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'extra-account':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'establishment-documents':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
              case 'new-establishment':
                this.navigationService.goto((match.$link.path as string).substr(1, match.$link.path.length - 1));
                break;
            }
          },
          nomatch => {
            // nomatch.$link - the full link data
            console.error("Got a deeplink that didn't match", nomatch);
          }
        );
    }
  }
}
