import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExtraCpdConfirmationComponent} from './extra-cpd-confirmation.component';
import {IonicModule} from '@ionic/angular';
import {ContractResumeCardComponentModule} from '@buscoextra/components/cards/contract-resume-card/contract-resume-card-component.module';
import {TimingCardComponentModule} from '@buscoextra/components/cards/timing-card/timing-card-component.module';
import {BeHeaderComponentModule} from '../ion-form-header/ion-form-header-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, ContractResumeCardComponentModule, TimingCardComponentModule, BeHeaderComponentModule],
  exports: [ExtraCpdConfirmationComponent],
  declarations: [ExtraCpdConfirmationComponent]
})
export class ExtraCpdConfirmationComponentModule {}
