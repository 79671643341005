import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewEstablishmentPage} from './new-establishment.page';
import {NewEstablishmentRoutingModule} from './new-establishment.routing';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EstablishmentServiceModule} from '@services';
import {BeLoadingComponentModule} from '@components';
import {BusinessServiceModule} from '@buscoextra/services/user/business/business.module';
import {ResourcePickerModule} from '~/components/resource-picker/resource-picker-component.module';
import {CompanyServiceModule} from '@buscoextra/services/company/company.module';
import {ContactUsComponentModule} from '~/components/contact-us/contact-us-component.module';
import {BeHeaderComponentModule} from '~/components/ion-form-header/ion-form-header-component.module';
import {LocationPickerComponentModule} from '~/components/be-components/location-picker/location-picker-component.module';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    ContactUsComponentModule,
    ResourcePickerModule,
    IonicModule,
    BeHeaderComponentModule,
    BusinessServiceModule,
    CompanyServiceModule,
    LocationPickerComponentModule,
    BeLoadingComponentModule,
    EstablishmentServiceModule,
    NewEstablishmentRoutingModule,
    ReactiveFormsModule,
    BeInputValueAccessorModule,
    TranslateModule
  ],
  declarations: [NewEstablishmentPage]
})
export class NewEstablishmentPageModule {}
