import {Component, Input} from '@angular/core';
import {DialogService, NavigationService, StateService} from '~/services';
import {MapArea} from '~/components/area-picker/models/area';
import {GeolocationService} from '~/services/geolocation/geolocation.service';
import {ModalController} from '@ionic/angular';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {BeLocation} from '@buscoextra/core';
import {ModalsService} from '~/services/modals/modals.service';
import {LocationModalPage} from '~/pages/location-modal-page/location-modal.page';
import {LocationService} from '@buscoextra/services/location/location.service';
import {AnalyticsService} from '~/services/analytics/analytics.service';

@Component({
  selector: 'work-area-page',
  templateUrl: './work-area.page.html',
  styleUrls: ['./work-area.page.scss']
})
export class WorkAreaPage extends AsyncPage {
  public area: MapArea;
  public lat;
  public lng;

  public maxDistance = 57;
  @Input() isModal = false;
  @Input() location: BeLocation;

  constructor(
    comunication: CommunicationsService,
    private ionicModal: ModalController,
    private geolocation: GeolocationService,
    private dialogService: DialogService,
    private modalCtl: ModalController,
    private locationService: LocationService,
    private analytics: AnalyticsService,
    private navigation: NavigationService,
    private stateService: StateService
  ) {
    super(comunication);
  }

  async ngOnInit() {
    if (this.location && this.location.latitude && this.location.longitude) {
      this.lat = this.location.latitude;
      this.lng = this.location.longitude;
      return;
    }
    const position = await this.send(this.geolocation.getDeviceLocation());
    if (position && position.lat && position.lng) {
      this.lat = position.lat;
      this.lng = position.lng;
    }
  }

  onAddressChange(ev) {
    if (ev && ev.latitude && ev.longitude) {
      this.lat = ev.latitude;
      this.lng = ev.longitude;
    }
  }

  onAreaPickerChange(area: MapArea) {
    this.area = area;
  }

  dismiss() {
    this.modalCtl.dismiss();
  }

  async UIonSaveLocationBtnPressed() {
    if (this.area.radius > this.maxDistance) {
      this.dialogService.toast(`Has elegido un area demasiado grande, elige una zona más pequeña`);
    } else {
      /*if (!(await this.locationIsContained(this.area.latitude, this.area.longitude, this.area.radius))) {
        this.showModal();
      } else {
        this.ionicModal.dismiss({location: await this.getLocationFromArea(), workingRadius: this.area.radius});
      }*/
      this.ionicModal.dismiss({location: await this.getLocationFromArea(), workingRadius: this.area.radius});
    }
  }

  async getLocationFromArea() {
    return await this.send(this.geolocation.getLocationFromLatLng(this.area.latitude, this.area.longitude), 'Location');
  }

  locationIsContained(lat, lng, rad): any {
    return this.send(this.locationService.getLocation(lat, lng, rad), 'isContained');
  }

  async showModal() {
    const modal = await this.modalCtl.create({
      component: LocationModalPage,
      cssClass: 'geolocation-modal'
    });
    modal.onDidDismiss().then(res => {
      if (res.data === 'signOut') {
        this.stateService.finishSession();
        this.navigation.jumpTo('');
      }
    });
    return await modal.present();
  }
}
