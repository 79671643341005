import {Component, Input} from '@angular/core';
import {STACK_EXTRAS_CARD_SETTINGS} from './be-stacked-extras-card.setting';
import {Extra} from '@buscoextra/core';

@Component({
  selector: 'be-stacked-extras-card',
  templateUrl: './be-stacked-extras-card.component.html',
  styleUrls: ['./be-stacked-extras-card.component.scss']
})
export class BeStackedExtrasCardComponent {
  @Input() extras: Extra[] = [];
  maxExtraCound: number = STACK_EXTRAS_CARD_SETTINGS.maxExtraCound;

  constructor() {}

  get extrasNoVisiblesCount(): number {
    return this.extras.length - this.maxExtraCound;
  }
}
