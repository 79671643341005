import {Injectable} from '@angular/core';
import {HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {StateService} from '~/services/state';
import {Observable} from 'rxjs';
import {environment} from '~environment';

@Injectable({
  providedIn: 'root',
})
export class HeadersService implements HttpInterceptor {
  constructor(private state: StateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders();
    let request = req;

    if (request && request.url && request.url.indexOf('buscoextra.es') == -1 && request.url.indexOf(environment.urlBase) == -1) {
      return next.handle(request);
    }
    const isBloblType = request.url.indexOf('/resources/content/') != -1;

    headers = this.getRequiredHeaders(headers);
    request = req.clone({
      ...(isBloblType && {responseType: 'blob'}),
      headers: headers,
    });

    return next.handle(request);
  }

  private getRequiredHeaders(headers) {
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    const token: string = this.state.authToken;
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    if (window.localStorage.getItem('impersonate')) {
      headers = headers.append('WWW-Authenticate', '_switch_user:::' + JSON.parse(window.localStorage.getItem('impersonate')));
    }
    headers = headers.append('X-version', 'v2');

    return headers;
  }
}
