import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkResumeComponent} from './work-resume.component';
import {IonicModule} from '@ionic/angular';
import {StarsComponentModule} from '../stars/stars-component.module';

@NgModule({
  imports: [CommonModule, IonicModule, StarsComponentModule],
  exports: [WorkResumeComponent],
  declarations: [WorkResumeComponent]
})
export class WorkResumeComponentModule {}
