import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Establishment} from '@buscoextra/core';

@Component({
  selector: 'be-establishments-list',
  templateUrl: './be-establishments-list.component.html',
  styleUrls: ['./be-establishments-list.component.css']
})
export class BeEstablishmentsListComponent implements OnInit {
  @Input() establishments: Establishment[] = [];
  @Output('onEstablishmentDelete') deleteEtablishmentEmitter: EventEmitter<Establishment> = new EventEmitter();
  @Output('onEstablishmentEdit') editEtablishmentEmitter: EventEmitter<Establishment> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onEditEstablishment(establishment: Establishment) {
    this.editEtablishmentEmitter.emit(establishment);
  }

  onRemoveEstablishment(establishment: Establishment) {
    this.deleteEtablishmentEmitter.emit(establishment);
  }
}
