import { NgModule } from "@angular/core";

import { BeInputValueAccessor } from "./be-input-accessor";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [BeInputValueAccessor],
  declarations: [BeInputValueAccessor],
  providers: []
})
export class BeInputValueAccessorModule {}
