import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './contact-us.component';
import { IonicModule } from '@ionic/angular';
import { UserServiceModule, ParamServiceModule } from '@services';
import { BeLoadingComponentModule } from '@components';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, UserServiceModule, ParamServiceModule, BeLoadingComponentModule],
  exports: [ContactUsComponent],
  declarations: [ContactUsComponent]
})
export class ContactUsComponentModule { }
