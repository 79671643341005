import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EncryptService} from './services/encrypt.service';
import {forClient} from './controllers/api-mappers.controller';
import {Entities} from '@buscoextra/core';
import {CustomError} from '../communications/models/custom-error';
import {BeApiRequest} from '@buscoextra/core';
import {environment} from '~environment';
export interface HttpRequest {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  body?: any;
  args?: any;
}

@Injectable()
export class ApiService {
  constructor(private angularHttp: HttpClient, private encryptService: EncryptService) {}

  public apiRequestV2(entityName, data: BeApiRequest, from = 'undefined'): Observable<any> {
    let request;
    const url = environment.urlBase + data.url;
    const body = this.doIneedToSkipEncrypt(url) ? data.body : this.encryptMapper(data.body);
    request = this.apiRequest(entityName, {method: data.type.toLowerCase(), url: url, body: body});
    request = this.addEncryptationMapperToRequest(request, data.url);
    request = this.addCutomMapperToRequest(request, data.map);
    return request;
  }

  public getStatus() {
    const entityName = 'status';
    return this.apiRequest(entityName, {method: 'get', url: environment.status});
  }

  private doIneedToSkipDecrypt(url: string): boolean {
    return url.indexOf('resources/content') !== -1 || url.indexOf('params') !== -1;
  }
  private doIneedToSkipEncrypt(url: string): boolean {
    return url.indexOf('params') !== -1;
  }
  private deCryptMapper(res) {
    return this.encryptService.decript(res);
  }
  private encryptMapper(data) {
    return this.encryptService.encript(data);
  }
  private addEncryptationMapperToRequest(request: Observable<any>, url: string): Observable<any> {
    if (!this.doIneedToSkipDecrypt(url)) {
      return request.pipe(map(res => this.deCryptMapper(res)));
    }
    return request;
  }
  private addCutomMapperToRequest(request: Observable<any>, mapper: any): Observable<any> {
    if (mapper) {
      return request.pipe(map(mapper));
    }
    return request;
  }

  apiRequest(entityName: string, request?: any, skipEncrypt: boolean = false): Observable<any> {
    // if(entityName === "status") return of(true); //! TO FORCE STATUS TO BE ALWAYS TRUE

    return this.httpRequest({
      url: request.url,
      body: request.body,
      method: request.method,
    }).pipe(
      map(res => {
        let c = forClient(entityName, entityName == Entities.Param ? this.paramsProcess(res) : res);
        return c;
      }),
      catchError((err: HttpErrorResponse, cuaght: Observable<any>) => {
        return throwError({
          httpError: err,
          request: request,
          entityName: entityName,
        } as CustomError);
      })
    );
  }

  //! ------------------- PARAMS , LEGACY-------------------
  private paramsProcess(params) {
    if (params && params['params']) {
      return params['params'];
    }

    if (
      params &&
      Object.keys(params).find(key => {
        return key == '0';
      }) &&
      environment.addressName === 'PROD'
    ) {
      return this.encryptService.decript(
        [params[Object.keys(params)[0]], params[Object.keys(params)[1]], params[Object.keys(params)[2]]],
        512
      );
    } else {
      return params;
    }
  }
  //! -----------------------------------------------------

  private httpRequest(httpRequest: HttpRequest): Observable<any> {
    if (httpRequest.method === 'get') {
      return this.angularHttp.get(httpRequest.url);
    }

    if (httpRequest.method === 'post') {
      return this.angularHttp[httpRequest.method](httpRequest.url, httpRequest.body);
    }
    if (httpRequest.method === 'put') {
      return this.angularHttp[httpRequest.method](httpRequest.url, httpRequest.body);
    }

    if (httpRequest.method === 'delete') {
      return this.angularHttp[httpRequest.method](httpRequest.url, httpRequest.body);
    }

    if (httpRequest.method === 'patch') {
      return this.angularHttp[httpRequest.method](httpRequest.url, httpRequest.body);
    }
  }
}
