import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExtraWorktypesSettingPage} from './extra-worktypes-setting.page';
import {ExtraWorktypesSettingRoutingModule} from './extra-worktypes-setting.routing';
import {IonicModule} from '@ionic/angular';
import {BeLoadingComponentModule, BeWorktypeCardComponentModule} from '@components';
import {WorktypeServiceModule} from '@services';
import {ExtraServiceModule} from '@buscoextra/services/user/extra/extra.module';
import {BeHeaderComponentModule} from '~/components/ion-form-header/ion-form-header-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    BeWorktypeCardComponentModule,
    BeLoadingComponentModule,
    ExtraServiceModule,
    WorktypeServiceModule,
    ExtraWorktypesSettingRoutingModule,
    BeHeaderComponentModule
  ],
  exports: [ExtraWorktypesSettingPage],
  declarations: [ExtraWorktypesSettingPage]
})
export class ExtraWorktypesSettingPageModule {}
