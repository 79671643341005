import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {StateService} from '../state/state.service';

@Injectable()
export class NavigationService {
  constructor(private angularRouter: Router, private angularLocation: Location, private state: StateService) {}

  goback(defaultUrl?: string) {
    this.angularLocation.back();
  }

  goto(url: string) {
    this.angularRouter.navigateByUrl(url);
  }
  gotoRouterOutlet(outletName: string, url) {
    let route = {
      outlets: {}
    };
    route.outlets[outletName] = [url];
    this.angularRouter.navigate([route], {skipLocationChange: true});
  }

  gotoExternalRoute(url: string) {
    window.open(url, '_system');
  }

  gotoHardLoad(url: string){
    window.open(url, '_self');
  }

  reloadPage() {
    location.reload();
  }

  avanceto(page: string) {
    this.angularRouter.navigate([page]);
  }

  getParam(angularActiveRoute: ActivatedRoute, paramName: string): Promise<any> {
    return new Promise(resolve => {
      resolve(angularActiveRoute.snapshot.params[paramName]);
    });
  }

  jumpTo(url: string) {
    this.angularRouter.navigate([url], {replaceUrl: true});
  }

  goToHomePage() {
    if (this.state.isABusinessSession()) {
      this.jumpTo('business');
    } else if (this.state.isAnExtraSession()) {
      this.jumpTo('extras');
    } else {
      this.jumpTo('');
    }
  }
  goToSkipLocation(url) {
    this.angularRouter.navigate([url], {skipLocationChange: true});
  }

  getActualRoute() {
    return this.angularRouter.url;
  }
  getLastRouteElement() {
    return this.getActualRoute().split('/')[this.getActualRoute().split('/').length - 1];
  }

  isActualRouteIn(route: string) {
    return this.angularRouter.url.indexOf(route) != -1;
  }
}
