import {NgModule} from '@angular/core';
import {BusinessService} from './business.service';
import {CpdServiceModule} from '@buscoextra/services/cpd/cpd.module';
import {EstablishmentServiceModule} from '@buscoextra/services/establishment/establishment.module';
import {OfferServiceModule} from '@buscoextra/services/offer/offer-service.module';
import {ResourceServiceModule} from '@buscoextra/services/resource/resource-service.module';

@NgModule({
  imports: [CpdServiceModule, EstablishmentServiceModule, OfferServiceModule, ResourceServiceModule],
  providers: [BusinessService]
})
export class BusinessServiceModule {}
