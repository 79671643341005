import {Component, Input} from '@angular/core';
import {NavigationService, DialogService, StateService} from '~/services';
import {Offer, Service, Cpd} from '@buscoextra/core';
import {NavParams} from '@ionic/angular';
import {ModalsService} from '~/services/modals/modals.service';

@Component({
  selector: 'business-preview-date-offer-page',
  templateUrl: './business-preview-date-offer.page.html',
  styleUrls: ['./business-preview-date-offer.page.scss'],
})
export class BusinessPreviewDateOfferPage {
  public contract: any;
  public schedule: any;
  public uniform: any;
  public description: any;
  public service: any;
  public timingFormat: any;
  public priceTime: any;
  public nUsersApplied: number;

  constructor(
    private navigation: NavigationService,
    private navParams: NavParams,
    private state: StateService,
    private dialog: DialogService,
    private modal: ModalsService
  ) {}

  ngOnInit() {
    this.contract = this.navParams.data as any;
    if (!this.contract['cpdPriceTime']) {
      this.schedule = (this.contract.offerPriceTime.timingFormat as any).schedule;
      this.timingFormat = this.contract.offerPriceTime.timingFormat as any;
      this.service = this.contract.offerPriceTime.service as Service;
      this.priceTime = this.contract.offerPriceTime;
      this.uniform = this.contract.offerInfo.uniform;
      this.description = this.contract.offerInfo.description;
      this.nUsersApplied = this.contract.nUsersApplied;
    } else {
      this.schedule = (this.contract.cpdPriceTime.timingFormat as any).schedule;
      this.timingFormat = this.contract.cpdPriceTime.timingFormat as any;
      this.priceTime = this.contract.cpdPriceTime;
      this.service = this.contract.cpdPriceTime.service as Service;
      this.description = this.contract.offer.offerInfo.description;
      this.uniform = this.contract.offer.offerInfo.uniform;
      this.nUsersApplied = this.contract.nUsersApplied;
    }
  }

  closeModal() {
    this.modal.dismiss();
  }

  editOffer() {
    this.modal.dismiss();
    this.navigation.goto(`/business/edit-offer/${this.contract.id}`);
  }

  formatMonth(month): string {
    let months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    return months[month - 1];
  }
}
