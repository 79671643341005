import {Requirement} from '@buscoextra/core';

export const extraFindOfferRequirement: Requirement[] = [
  {
    id: 'workarea',
    service: 'extraService',
    method: 'isLocationSettedByExtraId',
    title: 'Necesitas decir donde quieres trabajar',
    rule: (isLocation: boolean): boolean => {
      return !!isLocation;
    }
  },
  {
    id: 'worktypes',
    service: 'extraService',
    method: 'isWorktypesSettedByExtraId',
    title: 'Necesitamos saber los perfiles que te interesan',
    rule: (isWorkyupeSetted: boolean): boolean => {
      return !!isWorkyupeSetted;
    }
  }
];
