import {Mark} from '@buscoextra/core';
import * as _ from 'lodash';

export const PsReviewForServer = clientPsReview => {
  delete clientPsReview.id;
  if (clientPsReview.establishmentType && clientPsReview.establishmentType instanceof Object) {
    clientPsReview.establishmentType = clientPsReview.establishmentType.id;
  }
  if (clientPsReview.workType && clientPsReview.workType instanceof Object) {
    clientPsReview.workType = clientPsReview.workType.id;
  }
  if (clientPsReview.from && clientPsReview.from instanceof Object) {
    clientPsReview.from = clientPsReview.from.id;
  }
  if (clientPsReview.to && clientPsReview.to instanceof Object) {
    clientPsReview.to = clientPsReview.to.id;
  }

  if (clientPsReview.marks && clientPsReview.marks instanceof Array) {
    clientPsReview.marks = clientPsReview.marks.map(mark => {
      return {attribute: mark.attribute, rate: mark.rate};
    });
  }

  return clientPsReview;
};

export const PsReviewForClient = serverPsReview => {
  delete serverPsReview.createdAt;
  if (serverPsReview.marks && serverPsReview.marks instanceof Array) {
    serverPsReview.marks = serverPsReview.marks.map(r => {
      return {
        attribute: r.attribute.id,
        name: r.attribute.name,
        rate: r.rate,
      } as Mark;
    });
  }

  return serverPsReview;
};
