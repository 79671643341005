import {NgModule} from '@angular/core';
import {DniValidator} from './dni-validator.directive';
import {IbanValidator} from './iban-validator.directive';
import {SocialSecurityValidator} from './social-security-validator.directive';
import {CCCValidator} from './ccc-validator.directive';
import {CifValidator} from './cif-validator.directive';
import {facebookEmailValidator} from './forms/facbook-email-form.validator';

@NgModule({
  imports: [],
  exports: [DniValidator, IbanValidator, SocialSecurityValidator, CCCValidator, CifValidator],
  declarations: [DniValidator, IbanValidator, SocialSecurityValidator, CCCValidator, CifValidator],
  providers: []
})
export class AppValidatorsModule {}
