import {Requirement} from '@buscoextra/core';
import {Extra, Offer, WorkTypeChosen} from '@buscoextra/core';

export class ExtraApplyOfferRequirementInputs {
  workTypesChosen: WorkTypeChosen[];
  offer: Offer;
  extra: Extra;
  constructor(private _workTypesChosen: WorkTypeChosen[], private _offer: Offer, private _extra: Extra) {
    this.workTypesChosen = this._workTypesChosen;
    this.extra = this._extra;
    this.offer = this._offer;
  }
}

export const extraApplyOfferRequirement: Requirement[] = [
  {
    id: 'worktypeRequirement',
    title: 'Requerimientos para este tipo de trabajo',
    rule: (input: ExtraApplyOfferRequirementInputs): boolean => {
      return !input.workTypesChosen.some((workTypeChosen: WorkTypeChosen) => {
        return workTypeChosen.enabled && !workTypeChosen.verified;
      });
    }
  }
];
