import {ActionSheetButton} from '@ionic/core';

export const POP_OVER_MENU_ACTIONS: any[] = [
  {
    text: 'Editar',
    role: 'cancel',
    icon: 'home',
    cssClass: 'string',
    methodName: 'UIonEditOFferBtnPressed'
  },
  {
    text: 'Eliminar',
    role: 'cancel',
    icon: 'home',
    cssClass: 'string',
    methodName: 'UIonRemoveOfferBtnPressed'
  }
];
