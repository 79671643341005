import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import {ResourcePickerComponent} from './resource-picker.component';
import {CameraServiceModule} from '~/services/camera/camera-service.module';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, CameraServiceModule],
  exports: [ResourcePickerComponent],
  declarations: [ResourcePickerComponent],
  providers: []
})
export class ResourcePickerModule {}
