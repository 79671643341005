import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReviewStatus} from './reviews.page';
import {ApiService} from '@buscoextra/core/services/api/api.service';
import {BeApiRequest, Entities, ParamApi} from '@buscoextra/core';
import {StateService} from '~/services';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  constructor(private api: ApiService, private state: StateService) {}

  isOmitButtonActivated() {
    return this.api.apiRequestV2(Entities.Param, ParamApi.getParamByName('reviewsOmitButtonActivated')).pipe(
      map(res => {
        if (res && res.length > 0) {
          return res[0].value;
        } else {
          return undefined;
        }
      })
    );
  }

  arePendingReviews(userId?): Observable<any> {
    return this.checkPendingReviews(userId);
  }

  checkPendingReviews(userId?): Observable<any> {
    let beApiRequest: BeApiRequest;
    let user = userId ? userId : this.state.userId;
    beApiRequest = {
      url: '/api/check_cpd_extra_rate/' + user + '/clients',
      type: 'GET',
    };

    return this.api.apiRequestV2('reviews', beApiRequest);
  }

  getReviewData(userId?): Observable<any> {
    let beApiRequest: BeApiRequest;
    beApiRequest = {
      url: '/api/cpd_extra_rate/' + this.state.userId + '/clients',
      type: 'GET',
    };

    return this.api.apiRequestV2('reviews', beApiRequest);
  }

  setExtraRate(extras: ReviewStatus[]) {
    let beApiRequest: BeApiRequest;
    let body = [];

    extras.forEach(e => {
      let extraRate;
      if (e.value == 'positive') extraRate = 1;
      if (e.value == 'negative') extraRate = -1;

      body.push({cpdId: e.id.split('_')[2], extraRate: extraRate, extraRateOption: e.feedback});
    });

    beApiRequest = {
      url: '/api/cpd_extra_rate',
      type: 'POST',
      body: {cpds: body},
    };

    return this.api.apiRequestV2('reviews', beApiRequest);
  }
}

export interface ReviewData {
  establishments: ReviewEstablishment[];
}

export interface ReviewEstablishment {
  id: number;
  name: string;
  orders: ReviewOrder[];
}

export interface ReviewOrder {
  id: number;
  date: Date;
  profile: string;
  extras: ReviewExtra[];
}

export interface ReviewExtra {
  id: number;
  profilePicture: string;
  fullName: string;
  reviewStatus: -1 | 1;
  favorite: boolean;
  feedback: ReviewFeedback;
}

export interface ReviewFeedback {
  unpunctual: boolean;
  unprofessional: boolean;
  poorHygiene: boolean;
}
