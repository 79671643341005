import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RateComponent} from './rate.component';
import {StarsComponentModule} from '../stars/stars-component.module';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule, StarsComponentModule],
  exports: [RateComponent],
  declarations: [RateComponent]
})
export class RateComponentModule {}
