import {Injectable} from '@angular/core';
import {DNIBackScrapper, Resource, ResourceApi, ExtraApi, BusinessApi} from '@buscoextra/core';
import {Observable, of} from 'rxjs';
import {CustomEntities, Entities} from '@buscoextra/core';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '@buscoextra/core/services/api';
import {saveAs} from 'file-saver';

export enum ResourceTags {
  PROFILE = 'profile',
  ESTABLISHMENT_HEADER = 'establishmentImages',
  DNI_FRONT = 'dniFront',
  DNI_BACK = 'dniBack',
  DRIVER_LICENSE_FRONT = 'driverLicenseFront',
  DRIVER_LICENSE_BACK = 'driverLicenseBack',
  PRL = 'prl',
  OTP = 'SignatureOtp',
  PASSPORT = 'passport',
}

@Injectable()
export class ResourceService {
  constructor(private api: ApiService, public sanitizer: DomSanitizer) {}

  getResourceById(resourceId) {
    return this.api.apiRequestV2(Entities.Resource, ResourceApi.getResourceById(resourceId));
  }

  getUserResourceUrlByTag(userId: number, resourceTag: string): Observable<Resource> {
    return this.api.apiRequestV2(Entities.Resource, ExtraApi.getExtraResourcesByTag(userId, resourceTag)).pipe(
      mergeMap((res: Resource[]) => {
        if (res && res.length > 0) {
          return this.getSecureResource(res[0].urlIdentifier).pipe(
            map(
              secureImg => {
                const resource = {} as Resource;
                resource.url = secureImg;
                resource.id = res[0].id;
                resource.tag = resourceTag;
                return resource;
              },
              catchError((err, caught) => {
                return of(undefined);
              })
            )
          );
        } else {
          return of(undefined);
        }
      })
    );
  }
  getBusinessResourceUrlByid(userId, resourceId): Observable<Resource> {
    return this.api.apiRequestV2(Entities.Resource, BusinessApi.getBusinessResourcesById(userId, resourceId)).pipe(
      mergeMap((res: Resource[]) => {
        if (res && res.length > 0) {
          return this.getSecureResource(res[0].urlIdentifier).pipe(
            map(
              secureImg => {
                const resource = {} as Resource;
                resource.url = secureImg;
                resource.id = res[0].id;
                resource.tag = res[0].tag;
                resource.name = res[0].name;
                resource.alias = res[0].alias;
                return resource;
              },
              catchError((err, caught) => {
                return of(undefined);
              })
            )
          );
        } else {
          return of(undefined);
        }
      })
    );
  }
  getBusinessResourceUrlByTag(userId: number, resourceTag: string): Observable<Resource> {
    return this.api.apiRequestV2(Entities.Resource, BusinessApi.getBusinessResourcesByTag(userId, resourceTag)).pipe(
      mergeMap((res: Resource[]) => {
        if (res && res.length > 0) {
          return this.getSecureResource(res[0].url).pipe(
            map(
              secureImg => {
                const resource = {} as Resource;
                resource.url = secureImg;
                resource.id = res[0].id;
                resource.tag = res[0].tag;
                resource.name = res[0].name;
                resource.alias = res[0].alias;
                return resource;
              },
              catchError((err, caught) => {
                return of(undefined);
              })
            )
          );
        } else {
          return of(undefined);
        }
      })
    );
  }
  getCompanyResourcesUrlByTag(userId: number, companyId: number, resourceTag: string): Observable<Resource> {
    return this.api.apiRequestV2(
      Entities.Resource,
      BusinessApi.getBusinessResourcesByEntityAndEntityIdAndTag(userId, resourceTag, 'company', companyId)
    );
  }
  getEstablishmentResourcesUrlByTag(userId: number, establishmentId: number, resourceTag: string): Observable<Resource[]> {
    return this.api.apiRequestV2(
      Entities.Resource,
      BusinessApi.getBusinessResourcesByEntityAndEntityIdAndTag(userId, resourceTag, 'establishment', establishmentId)
    );
  }
  getEstablishmentPrLDocument(userId: number, establishmentId: number): Observable<Resource[]> {
    return this.api.apiRequestV2(
      Entities.Resource,
      BusinessApi.getBusinessResourcesByEntityAndEntityIdAndTag(userId, ResourceTags.PRL, 'establishment', establishmentId)
    );
  }

  getSecureResource(resourceUrl: string) {
    return this.api.apiRequestV2(Entities.Resource, ResourceApi.getResource({urlIdentifier: resourceUrl} as any)).pipe(
      map(blob => {
        const urlCreator = window.URL;
        const sanitizedImage = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
        return sanitizedImage;
      })
    );
  }

  uploadProfilePicture(base64Resource: string, ownerId: number, resourceId?: number): Observable<boolean> {
    const data = {
      tag: ResourceTags.PROFILE,
      file: base64Resource,
      entity: 'User',
      isPublic: true,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  uploadUserResourceByTag(base64Resource: string, ownerId: number, tag: string, resourceId?: number): Observable<boolean> {
    const data = {
      tag: tag,
      file: base64Resource,
      entity: 'User',
      isPublic: true,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  uploadPrlFile(base64Resource: string, ownerId: number, establishmentId: number) {
    const data = {
      tag: ResourceTags.PRL,
      alias: 'PRL - ' + new Date().getTime(),
      file: base64Resource,
      entity: 'Establishment',
      isPublic: false,
      owner: ownerId,
      entityId: establishmentId,
    };

    return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
      map(() => {
        return true;
      })
    );
  }

  uploadEstablishmentHeaderImage(
    base64Resource: string,
    ownerId: number,
    establishmentId: number,
    resourceId?: number
  ): Observable<boolean> {
    const data = {
      tag: ResourceTags.ESTABLISHMENT_HEADER,
      file: base64Resource,
      entity: 'Establishment',
      isPublic: true,
      owner: ownerId,
      entityId: establishmentId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any));
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any));
    }
  }

  uploadDniFront(base64Resource: string, ownerId: number, resourceId?: number): Observable<boolean> {
    const data = {
      tag: ResourceTags.DNI_FRONT,
      file: base64Resource,
      entity: 'User',
      isPublic: false,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  uploadDniBack(base64Resource: string, ownerId: number, resourceId?: number): Observable<boolean> {
    const data = {
      tag: ResourceTags.DNI_BACK,
      file: base64Resource,
      entity: 'User',
      isPublic: false,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  uploadDriverLicenseFront(base64Resource: string, ownerId: number, resourceId?: number): Observable<boolean> {
    const data = {
      tag: ResourceTags.DRIVER_LICENSE_FRONT,
      file: base64Resource,
      entity: 'User',
      isPublic: false,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }
  uploadDriverLicenseBack(base64Resource: string, ownerId: number, resourceId?: number): Observable<boolean> {
    const data = {
      tag: ResourceTags.DRIVER_LICENSE_BACK,
      file: base64Resource,
      entity: 'User',
      isPublic: false,
      owner: ownerId,
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  uploadOtherResource(
    base64Resource: string,
    ownerId: number,
    tag: string,
    entity: string,
    entityId: number,
    resourceId?: number
  ): Observable<boolean> {
    const data = {
      tag: tag,
      file: base64Resource,
      entity: entity,
      isPublic: false,
      owner: ownerId,
    } as any;

    if (entityId) data.entityId = entityId;

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any)).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any)).pipe(
        map(() => {
          return true;
        })
      );
    }
  }

  processDNIBack(base64Dni): Observable<DNIBackScrapper> {
    return this.api.apiRequestV2(Entities.Resource, ResourceApi.scrapDni(base64Dni));
  }

  downloadResource(resource: Resource) {
    return this.api.apiRequestV2(Entities.Resource, ResourceApi.getResource(resource)).pipe(
      map(res => {
        saveAs(res, (resource.alias || resource.name) + '.' + resource.extension);
      })
    );
  }
}
