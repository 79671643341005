import {ErrorHandler, Injectable, isDevMode} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {environment} from '~environment';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class SentryService {
  constructor() {}

  initSentry() {
    Sentry.init({
      dsn: 'https://a7edb45839b0431a9f21ef6045a48369@sentry.io/1214193',
      environment: environment.addressName,
      release: environment.version + '',
      ignoreErrors: [
        "Cannot read property 'picture' of undefined",
        'No Image Selected',
        'Uncaught (in promise): No preview',
        'Uncaught (in promise): removeView was not found',
        'JSON Parse error: Unexpected EOF',
        'Uncaught (in promise): cordova_not_available',
        'has no access to assets'
      ]
    });
  }

  setContextVariables(context: any) {
    Sentry.setUser(context);
  }
  setExtraContextVariables(context: any) {
    Sentry.setExtras({extras: context as any});
  }

  notifyError(url: string, dataSended: any, err: HttpErrorResponse) {
    let sentryError: any = err;
    sentryError.error = 'HTTP Error -  ' + err.status + ': ' + err.message;
    this.setExtraContextVariables({
      datasend: dataSended || 'No data sended',
      url: url,
      errorStatus: err.status,
      message: err.message
    });
    Sentry.captureException(sentryError.error);
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (err instanceof HttpErrorResponse) {
      console.log('Error: ', err);
    } else {
      console.log('Error: ', err);
      if (!isDevMode()) Sentry.captureException(err);
    }
  }
}
