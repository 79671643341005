import {Component} from '@angular/core';
import {Cpd} from '@buscoextra/core';
import {Mark} from '@buscoextra/core';
import {ReviewService} from '@buscoextra/services/review/review.service';
import {PsReview} from '@buscoextra/core';
import {ModalController} from '@ionic/angular';
import {AsyncPage, CommunicationsService} from '@buscoextra/core/services';
import {DialogService, StateService} from '~/services';

@Component({
  selector: 'rate-extra-page',
  templateUrl: './rate-extra.page.html',
  styleUrls: ['./rate-extra.page.scss'],
})
export class RateExtraPage extends AsyncPage {
  public cpd: Cpd;
  public psReview: PsReview;

  constructor(
    communications: CommunicationsService,
    private state: StateService,
    private dialog: DialogService,
    private reviewService: ReviewService,
    private modalCtrl: ModalController
  ) {
    super(communications);
  }

  async ngOnInit() {
    this.psReview = await this.send(this.reviewService.getExtraPSReview(this.state.userId, this.cpd), 'psReview');

    if (!this.psReview) {
      this.psReview = {
        from: this.state.userId,
        to: this.cpd.extra.id,
        businessExperience: 0,
        establishmentType: this.cpd.establishment.establishmentType.id,
        marks: [],
        workType: this.cpd.workType.id,
      } as any;
      this.psReview.marks = await this.send(this.reviewService.getExtraMarks(), 'marks');
    }
  }

  onSatisfactionChange(marks) {
    this.psReview.businessExperience = marks[0].rate;
  }

  async reviewExtra() {
    if (
      !this.psReview.businessExperience ||
      this.psReview.businessExperience == 0 ||
      this.psReview.marks.some((mark: Mark) => {
        return mark.rate == 0;
      })
    ) {
      this.dialog.toast('Es necesario valorar todos los campos.');
      return;
    }
    if (await this.send(this.reviewService.reviewExtra({...this.psReview}, this.cpd.id), 'ratingsExtra')) {
      this.dialog.toast('Valoración enviada con éxito');
    }
    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
