import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RateBusinessPage} from './rate-business.page';
import {RateBusinessRoutingModule} from './rate-business.routing';
import {IonicModule} from '@ionic/angular';
import {ReviewServiceModule} from '@buscoextra/services/review/review.module';
import {RateComponentModule} from '@buscoextra/components/other/rate/rate-component.module';
import {BeCpdCardComponentModule} from '~/components/be-components/be-cpd-card/be-cpd-card-component.module';
import {BeLoadingComponentModule} from '@components';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    RateBusinessRoutingModule,
    BeLoadingComponentModule,
    RateComponentModule,
    ReviewServiceModule,
    BeCpdCardComponentModule
  ],
  declarations: [RateBusinessPage]
})
export class RateBusinessPageModule {}
