import {Injectable} from '@angular/core';
import {CustomError} from '../../models/custom-error';
import {DialogService} from '~/services';

@Injectable({
  providedIn: 'root'
})
export class CompanyErrorsService {
  constructor(private dialog: DialogService) {}

  onGetError(error: CustomError) {
    if (error.httpError.status === 418) {
      this.dialog.toast('¡No puedes borrar una cuenta de facturación que estes usando en un establecimiento!');
    }
  }
}
