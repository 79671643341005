import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkAreaPage} from './work-area.page';
import {WorkAreaRoutingModule} from './work-area.routing';
import {IonicModule} from '@ionic/angular';
import {GeolocationServiceModule} from '~/services/geolocation/geolocation-service.module';
import {AreaPickerComponentModule} from '~/components/area-picker/area-picker-component.module';
import {NavBackButtonComponentModule} from '~/components/nav-back-button/nav-back-button-component.module';
import {LocationService} from '@buscoextra/services/location/location.service';
import {LocationPickerComponentModule} from '~/components/be-components/location-picker/location-picker-component.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    NavBackButtonComponentModule,
    WorkAreaRoutingModule,
    AreaPickerComponentModule,
    GeolocationServiceModule,
    LocationPickerComponentModule
  ],
  exports: [WorkAreaPage],
  declarations: [WorkAreaPage],
  providers: [LocationService]
})
export class WorkAreaPageModule {}
