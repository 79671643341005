import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {DialogService} from '~/services';

@Injectable({
  providedIn: 'root'
})
export class ExtraErrorsService {
  constructor(private dialog: DialogService) {}

  onGetError(error: HttpErrorResponse) {
    if (error.status === 404) {
      this.dialog.toast('Esta offer no existe');
    }
    if (error.status === 400) {
      this.dialog.toast('Offer inexistente');
    }
  }
}
