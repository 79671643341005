import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractStatusComponent} from './contract-status.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [ContractStatusComponent],
  declarations: [ContractStatusComponent]
})
export class ContractStatusComponentModule {}
