import {AsyncPage} from './async-page.wrapper';
import {CommunicationsService} from '../communications.service';

export class FormPage extends AsyncPage {
  constructor(communication: CommunicationsService) {
    super(communication);
  }

  private _formValue: any = {};
  private _formValueBackup: any = {};
  private _inputsEditted = {};

  isUpdating: boolean = false;

  get formValue(): any {
    return this._formValue;
  }

  set formValue(formValue: any) {
    this._formValue = formValue;
    this._formValueBackup = { ...this._formValue };
  }

  isFormEditted(): boolean {
    const inputsKeys = Object.keys(this._inputsEditted);
    if (!inputsKeys.length) return false;
    return inputsKeys.some(key => {
      return this._inputsEditted[key];
    });
  }

  onInputChange(key: string, value?: any) {
    //TODO Falta la validacion
    if (value) this.formValue[key] = value;
    this._inputsEditted[key] = this.formValue[key] !== this._formValueBackup[key];
  }

  isInputEdited(key: string): boolean {
    return this._inputsEditted[key];
  }
}
