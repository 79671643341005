export enum DistanceByScale {
  scale20 = 1128.49722,
  scale19 = 2256.99444,
  scale18 = 4513.98888,
  scale17 = 9027.977761,
  scale16 = 18055.95552,
  scale15 = 36111.91104,
  scale14 = 72223.82209,
  scale13 = 144447.6442,
  scale12 = 288895.2884,
  scale11 = 577790.5767,
  scale10 = 1155581.153,
  scale9 = 2311162.307,
  scale8 = 4622324.614,
  scale7 = 9244649.227,
  scale6 = 18489298.45,
  scale5 = 36978596.91,
  scale4 = 73957193.82,
  scale3 = 147914387.6,
  scale2 = 295828775.3,
  scale1 = 591657550.5
}

export const InitZoom = 9;
