import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopOverMenuComponent} from './pop-over-menu.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PopOverMenuComponent],
  exports: [PopOverMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PopOverMenuModule {}
