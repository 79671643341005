import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeStackedExtrasCardComponent} from './be-stacked-extras-card.component';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [BeStackedExtrasCardComponent],
  declarations: [BeStackedExtrasCardComponent]
})
export class BeStackedExtrasCardComponentModule {}
