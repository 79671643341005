import {NgModule} from '@angular/core';
import {AnalyticsService} from './analytics.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [AnalyticsService]
})
export class AnalyticsServiceModule {
  constructor(analyticService: AnalyticsService) {
  }
}
