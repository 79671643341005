import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Mark} from '@buscoextra/core';

@Component({
  selector: 'rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent {
  @Input() marks: Mark[];
  @Input() size: number;
  @Output('onChange') rateEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onValueChange() {
    this.rateEmitter.emit(this.marks);
  }
}
