import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CameraService} from '~/services/camera/camera.service';
import {Device} from '@ionic-native/device/ngx';

@Component({
  selector: 'app-id-card-capture',
  templateUrl: './id-card-capture.component.html',
  styleUrls: ['./id-card-capture.component.scss']
})
export class IdCardCaptureComponent implements OnInit {
  @Input() cameraPreviewStartX = 10;
  @Input() cameraPreviewStartY;
  @Input() cameraPreviewWidth = window.innerWidth - 20;
  @Input() showCaptureButton = true;

  platformTopShift;

  @Input() cameraPreviewHeight;

  private screenAspectRatio = window.innerWidth / window.innerHeight;
  private cameraPreviewWidthPct;
  private cameraPreviewHeightPct;
  @Input() layerPictureUrl;
  @Output('onImageCapture') onImageCapture: EventEmitter<string> = new EventEmitter();

  image;

  constructor(private cameraService: CameraService, device: Device) {
    this.cameraPreviewHeight =
      device.model && device.model.indexOf('iPhone10') != -1 ? (window.innerWidth - 20) / 1.75 : (window.innerWidth - 20) / 1.58;
    this.cameraPreviewStartY = device.platform == 'Android' ? 57 : 64;
    this.platformTopShift = device.platform == 'Android' ? 57 : 64;
    this.cameraPreviewHeightPct = this.cameraPreviewHeight / window.innerHeight;
    this.cameraPreviewWidthPct = this.cameraPreviewWidth / window.innerWidth;
  }

  ngOnInit() {
    this.cameraService
      .startPreviewCamera(this.cameraPreviewWidth, this.cameraPreviewHeight, this.cameraPreviewStartX, this.cameraPreviewStartY)
      .subscribe(
        res => {
          console.log('Camara inciada concretamente', res);
        },
        err => {
          console.log('No se ha podido inicar la camara', err);
        }
      );
  }

  closeCamera() {
    this.cameraService.stopCameraPreview();
  }

  hideCamera() {
    this.cameraService.hideCameraPreview();
  }

  showCamera() {
    this.cameraService.showCameraPreview();
  }

  async captureImage() {
    let imageData = await this.cameraService.captureCameraPreviewPicture();
    if (imageData) {
      let img = new Image();
      let that = this;
      img.src = 'data:image/jpeg;base64,' + imageData;
      img.onload = function() {
        that.image = that.cameraService.cropImage(img, that.cameraPreviewWidthPct, that.cameraPreviewHeightPct, that.screenAspectRatio);

        that.onImageCapture.emit(that.image);
      };
    }
  }
}
