import {Injectable} from '@angular/core';

import {BEAction} from '../models/action';
import {Session} from '@buscoextra/core';

@Injectable()
export class BESessionActions {
  static readonly UPDATE_SESSION = 'UPDATE_SESSION';
  static readonly FINISH_SESSION = 'FINISH_SESSION';

  update(session: Session): BEAction {
    return {type: BESessionActions.UPDATE_SESSION, payload: session};
  }

  finishSession(session: Session): BEAction {
    return {type: BESessionActions.FINISH_SESSION, payload: session};
  }
}
