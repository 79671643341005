import { Injectable } from '@angular/core';
import { Company } from '@buscoextra/core';
import { Entities } from '@buscoextra/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { EstablishmentService } from '../establishment/establishment.service';
import { ApiService } from '@buscoextra/core/services/api';
import { CompanyApi, EstablishmentApi } from '@buscoextra/core';
import { CompanyForServer } from '@buscoextra/core/services/api/mappers/company.mapper';

@Injectable()
export class CompanyService {
  constructor(private api: ApiService, private establishment: EstablishmentService) { }

  getAllByBusinessId(businessId: number): Observable<Company[]> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.getCompaniesByBusinessId(businessId));
  }

  delete(companyId: number): Observable<any> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.deleteCompany(companyId));
  }

  getById(companyId: number): Observable<Company> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.getCompanyById(companyId));
  }

  create(company: Company): Observable<Company> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.createCompany(CompanyForServer({ ...company })));
  }

  update(company: Company): Observable<Company> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.updateCompany({ id: company.id, ...CompanyForServer(company) }));
  }

  saveCIF(companyId: number, cif: string): Observable<Company> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.updateCompany({ id: companyId, CIF: cif } as any));
  }
  saveIBAN(companyId: number, iban: string): Observable<any> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.updateCompany({ id: companyId, iban: iban } as any));
  }

  createCompanyAndSaveToEstablishhment(companyData: Company, establishhmentId: number): Observable<Company> {
    return this.api.apiRequestV2(Entities.Company, CompanyApi.createCompany(companyData)).pipe(
      mergeMap((company: Company) => {
        return this.api
          .apiRequestV2(Entities.Establishment, EstablishmentApi.updateEstablishment({ id: establishhmentId, company: company } as any))
          .pipe(
            map(() => {
              return company;
            })
          );
      })
    );
  }
}
