import {UserForClient, UserForServer} from './user.mapper';
import {OfferForClient, OfferForServer} from './offer.mapper';
import {ParamForClient, ParamForServer} from './param.mapper';
import {SessionForClient} from './session.mapper';
import {CpdForClient, CpdForServer} from './cpd.mapper';
import {WorkTypeChosenForClient, WorkTypeChosenForServer} from './workTypeChosen.mapper';
import {BusinessForClient, BusinessForServer} from './business.mapper';
import {CompanyForClient, CompanyForServer} from './company.mapper';
import {EstablishmentForClient, EstablishmentForServer} from './establishment.mapper';
import {WorkResumeClient, WorkResumeForServer} from './workResume.mapper';
import {ExtraForClient, ExtraForServer} from './extra.mapper';
import {PsReviewForClient, PsReviewForServer} from './psReview.mapper';
import {CvItemsForServer} from './cvItems.mapper';

export const mappers = {
  client: {
    offers: OfferForClient,
    users: UserForClient,
    businesses: BusinessForClient,
    params: ParamForClient,
    session: SessionForClient,
    extras: ExtraForClient,
    cpds: CpdForClient,
    work_types_chosen: WorkTypeChosenForClient,
    companies: CompanyForClient,
    establishments: EstablishmentForClient,
    work_resumes: WorkResumeClient,
    ps_reviews: PsReviewForClient
  },
  server: {
    offers: OfferForServer,
    users: UserForServer,
    businesses: BusinessForServer,
    extras: ExtraForServer,
    params: ParamForServer,
    cv_items: CvItemsForServer,
    cpds: CpdForServer,
    work_types_chosen: WorkTypeChosenForServer,
    companies: CompanyForServer,
    establishments: EstablishmentForServer,
    work_resumes: WorkResumeForServer,
    ps_reviews: PsReviewForServer
  }
};
