import {NgModule} from '@angular/core';
import {ModalsService} from './modals.service';
import {WorkAreaPageModule} from '~/pages/home/shell-extra-page/pages/my-profile-page/pages/work-area-page/work-area-page.module';
import {CalendarPickerPageModule} from '~/pages/home/shell-business-page/pages/new-offer-page/calendar-picker-page/calendar-picker-page.module';
import {ExtraWorktypesSettingPageModule} from '~/pages/home/shell-extra-page/pages/my-profile-page/pages/extra-work-profile-page/pages/extra-worktypes-setting-page/extra-worktypes-setting-page.module';
import {IdCardPageModule} from '~/pages/home/shell-extra-page/pages/my-profile-page/pages/extra-work-profile-page/pages/work-types-requirements-page/pages/work-types-requirements-detail-page/pages/id-card-page/id-card-page.module';
import {ExtraSetupIdentityPageModule} from '~/pages/home/shell-extra-page/pages/extra-setup-identity-page/extra-setup-identity-page.module';
import {RateExtraPageModule} from '~/pages/home/rate-extra-page/rate-extra-page.module';
import {RateBusinessPageModule} from '~/pages/home/rate-business-page/rate-business-page.module';
import {AppRatingPageModule} from '~/pages/home/app-rating-page/app-rating-page.module';
import {ExtraApplySetupPageModule} from '~/pages/home/shell-extra-page/pages/extra-apply-page/pages/extra-apply-setup-page/extra-apply-setup-page.module';
import {MyAccountPageModule} from '~/pages/home/shell-business-page/pages/my-profile-page/pages/my-account-page/my-account-page.module';
import {ResigningCpdPageModule} from '~/pages/resigning-cpd-page/resigning-cpd-page.module';
import {ExtraCpdConfirmationComponentModule} from '~/components/extra-cpd-confirmation/extra-cpd-confirmation-component.module';
import {OtpModalComponentModule} from '~/components/otp-modal/otp-modal-component.module';
import {LocationModalPageModule} from '~/pages/location-modal-page/location-modal-page.module';
import {NewEstablishmentPageModule} from '~/pages/home/shell-business-page/pages/my-profile-page/pages/my-establishments-page/pages/establishment-page/pages/new-establishment-page/new-establishment-page.module';
import {BusinessPreviewDateOfferPageModule} from '~/pages/home/shell-business-page/pages/my-offers-page/pages/business-preview-date-offer-page/business-preview-date-offer-page.module';
import {LeadModalComponentModule} from '~/pages/init/sign-selection-page/lead-modal/lead-modal-component.module';
import {EstablishmentContactModalComponentModule} from '~/pages/home/shell-extra-page/pages/components/establishment-contact-modal/establishment-contact-modal-component.module';

const ENTRY_PAGE_MODULES = [
  WorkAreaPageModule,
  NewEstablishmentPageModule,
  ExtraApplySetupPageModule,
  ExtraSetupIdentityPageModule,
  RateExtraPageModule,
  RateBusinessPageModule,
  ResigningCpdPageModule,
  AppRatingPageModule,
  CalendarPickerPageModule,
  ExtraWorktypesSettingPageModule,
  IdCardPageModule,
  MyAccountPageModule,
  ExtraCpdConfirmationComponentModule,
  OtpModalComponentModule,
  LocationModalPageModule,
  BusinessPreviewDateOfferPageModule,
  EstablishmentContactModalComponentModule,
  LeadModalComponentModule
];

@NgModule({
  imports: [...ENTRY_PAGE_MODULES],
  providers: [ModalsService]
})
export class ModalsServiceModule {}
