import {Schedule} from '@buscoextra/core';

export const OfferForServer = clientOffer => {
  if (clientOffer.offerPriceTime && clientOffer.offerPriceTime.service) {
    clientOffer.offerPriceTime.service = clientOffer.offerPriceTime.service.id;
  }

  if (clientOffer.offerPriceTime && clientOffer.offerPriceTime.agreementPrice) {
    clientOffer.offerPriceTime.agreementPrice = clientOffer.offerPriceTime.agreementPrice.id;
  }

  if (clientOffer.establishment && clientOffer.establishment) {
    clientOffer.establishment = clientOffer.establishment.id;
  }

  delete clientOffer.id;
  return clientOffer;
};

export const OfferForClient = serverOffer => {
  if (serverOffer.offerPriceTime && serverOffer.offerPriceTime.timingFormat) {
    if (serverOffer.offerPriceTime.timingFormat.startDate)
      serverOffer.offerPriceTime.timingFormat.startDate = new Date(serverOffer.offerPriceTime.timingFormat.startDate);
    if (serverOffer.offerPriceTime.timingFormat.finishDate)
      serverOffer.offerPriceTime.timingFormat.finishDate = new Date(serverOffer.offerPriceTime.timingFormat.finishDate);
    if (serverOffer.offerPriceTime.timingFormat.schedule) {
      serverOffer.offerPriceTime.timingFormat.schedule.map((sch: Schedule) => {
        return (sch.date = new Date(sch.date));
      });
    }
  }

  delete serverOffer.createdAt;
  return serverOffer;
};
