export const WorkTypeChosenForServer = clientParam => {
  delete clientParam.id;
  delete clientParam.verified;
  if (clientParam.workType && clientParam.workType.id) clientParam.workType = clientParam.workType.id;
  if (clientParam.owner && clientParam.owner.id) clientParam.owner = clientParam.owner.id;
  return clientParam;
};

export const WorkTypeChosenForClient = serverParam => {
  delete serverParam.createdAt;
  return serverParam;
};
