import {NgModule} from '@angular/core';
import {IsLogged} from './isLogged.guard';
import {IsAuthorized} from './isAuthorized.guard';
import {SystemStatus} from './systemStatus.guard';
import {BusinessHasEstablishments} from './businessHasEstablishments.guard';
import {HasUserType} from './hasUserType.guard';
import {BusinessServiceModule} from '@buscoextra/services/user/business/business.module';
import {SignatureServiceModule} from '@buscoextra/services/signature/signature.module';
import {ResourceServiceModule} from '@buscoextra/services/resource/resource-service.module';
import {CpdServiceModule} from '@buscoextra/services/cpd/cpd.module';
import {BusinessReadyToCreateOffer} from './businessReadyToCreateOffer.guard';
import {RouterModule} from '@angular/router';
import {ExtraReadyToFindOffers} from './extraReadyToFindOffers.guard';

@NgModule({
  imports: [RouterModule, BusinessServiceModule, SignatureServiceModule, ResourceServiceModule, CpdServiceModule],
  providers: [
    IsAuthorized,
    IsLogged,
    SystemStatus,
    BusinessHasEstablishments,
    HasUserType,
    BusinessReadyToCreateOffer,
    ExtraReadyToFindOffers
  ]
})
export class GuardsServiceModule {}
