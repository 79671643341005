import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Device} from '@ionic-native/device/ngx';
import {getBrowser, getBrowserVersion} from '@buscoextra/core';
import {Observable, of} from 'rxjs';
import {environment} from '~environment';
const LG_SIZE = 992;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(private platform: Platform, private device: Device) {}

  getAppVersion(): Observable<number> {
    return of(environment.version);
  }
  getOS(): string {
    // return this.isCordova() ? this.device.platform : getBrowser();
    return this.isCordova() ? this.device.platform : 'web';
  }

  getOSVersion(): string {
    return this.isCordova() ? this.device.version : getBrowserVersion();
  }

  isCordova(): boolean {
    return this.platform.is('cordova');
  }

  isDesktopResolution() {
    return window.innerWidth > LG_SIZE;
  }
}
