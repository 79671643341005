import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dayToWork'
})
export class DayToWorkPipe implements PipeTransform {
  transform(value: number): string {
    let days: number = Math.ceil(value);
    return `${days} ${days > 1 ? 'dias' : 'dia'}`;
  }
}
