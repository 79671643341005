import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyAccountPage} from './my-account.page';
import {MyAccountRoutingModule} from './my-account.routing';
import {IonicModule} from '@ionic/angular';
import {UserServiceModule} from '@services';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BeLoadingComponentModule} from '@components';
import {ContactUsComponentModule} from '~/components/contact-us/contact-us-component.module';
import {AppValidatorsModule} from '~/validators/app-validators.module';
import {BeHeaderComponentModule} from '~/components/ion-form-header/ion-form-header-component.module';
import {BeInputValueAccessorModule} from '@buscoextra/core/value-accessor/be-input-accessor.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    BeLoadingComponentModule,
    AppValidatorsModule,
    ContactUsComponentModule,
    BeHeaderComponentModule,
    IonicModule,
    UserServiceModule,
    MyAccountRoutingModule,
    ReactiveFormsModule,
    BeInputValueAccessorModule
  ],
  declarations: [MyAccountPage]
})
export class MyAccountPageModule {
  constructor() {}
}
