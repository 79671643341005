import {NgModule} from '@angular/core';
import {OfferService} from './offer.service';
import {CpdServiceModule} from '../cpd/cpd.module';
import {ApiServiceModule} from '@buscoextra/core/services/api';

@NgModule({
  imports: [ApiServiceModule, CpdServiceModule],
  providers: [OfferService]
})
export class OfferServiceModule {}
