import {Component, Input} from '@angular/core';
import {DialogService, NavigationService, StateService} from '~/services';
import {ModalController} from '@ionic/angular';
import {WorkType, WorkTypeChosen} from '@buscoextra/core';
import {WorktypeService} from '@services';
import {ExtraService} from '@buscoextra/services/user/extra/extra.service';
import {CommunicationsService} from '@buscoextra/core/services';
import {FormPage} from '@buscoextra/core/services/communications/wrappers/form-page.wrapper';
import * as _ from 'lodash';
@Component({
  selector: 'extra-worktypes-setting-page',
  templateUrl: './extra-worktypes-setting.page.html',
  styleUrls: ['./extra-worktypes-setting.page.scss'],
})
export class ExtraWorktypesSettingPage extends FormPage {
  worktypes: WorkType[];
  worktypesChosen: WorkTypeChosen[];
  worktypesChosenBkup: WorkTypeChosen[];
  formEditted = false;
  @Input() isModal = false;
  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private extraService: ExtraService,
    private worktypeService: WorktypeService,
    private ionicModal: ModalController,
    private dialog: DialogService
  ) {
    super(communications);
  }

  async ionViewWillEnter() {
    this.worktypes = (await this.send(this.worktypeService.getAll(), 'gettingAvailableWorktypes')) || [];
    this.worktypesChosen = (await this.send(this.worktypeService.getChoosenByExtraId(this.state.userId), 'gettingWorktypeChoosen')) || [];
    this.worktypesChosenBkup = _.cloneDeep(this.worktypesChosen);
  }

  isRequisitesVisible(): boolean {
    if (!this.worktypesChosenBkup) return false;

    let workTypeBikeDelivery = this.worktypesChosenBkup.find(el => el.workType.id === 3);
    return workTypeBikeDelivery?.enabled && !workTypeBikeDelivery?.readyToVerify;
  }

  goToRequisites() {
    this.navigation.goto('extras/my-profile/work-profile/requirements/detail/' + this.worktypesChosen.find(el => el.workType.id === 3).id);
  }

  async UIonAcceptBtnPressed() {
    if (this.worktypesChosen && this.worktypesChosen.length > 0) {
      let result = await this.send(
        this.extraService.saveExtraWorktypes(this.state.userId, [
          ...this.worktypesChosen.map(wtC => {
            if (wtC.workType.autoVerify && wtC.enabled) {
              wtC.readyToVerify = true;
            }
            const data = {...wtC};
            data.workType = data.workType.id as any;
            if (data.owner && data.owner.id) data.owner = data.owner.id as any;
            return data;
          }),
        ]),
        'savingWorktypes'
      );

      if (result) {
        this.dialog.toast('Guardado con éxito');
        this.worktypesChosenBkup = _.cloneDeep(this.worktypesChosen);
        let workTypeBikeDelivery = this.worktypesChosen.find(i => i.workType.id == 3);
        if (this.isModal) {
          this.ionicModal.dismiss(true);
        } else {
          if (workTypeBikeDelivery?.enabled && !workTypeBikeDelivery.readyToVerify) {
            this.dialog
              .alert(
                'Validación requerida',
                'Para el perfil profesional de repartidor de moto necesitamos verificar tu carnet de conducir.',
                '¿Deseas añadirlo ahora?',
                [
                  {text: 'Cancelar', role: 'cancel'},
                  {text: 'Aceptar', role: 'accept'},
                ]
              )
              .then(res => {
                if (res.role == 'accept') {
                  this.navigation.goto('extras/my-profile/work-profile/requirements/detail/' + workTypeBikeDelivery.id);
                }
              });
          }
        }
      }
    }
  }

  dismiss() {
    this.ionicModal.dismiss();
  }

  UIisWorktypeChoosenAndEnabled(worktype: WorkType): boolean {
    return this.worktypesChosen.some(worktypechoosen => {
      return worktypechoosen.enabled === true && worktypechoosen.workType.id === worktype.id;
    });
  }

  private isWorktypeChoosen(worktype: WorkType): boolean {
    return this.worktypesChosen.some(worktypechoosen => {
      return worktypechoosen.workType.id === worktype.id;
    });
  }

  UIonWorktypeCardPressed(worktype: WorkType) {
    this.onInputChange(worktype.name, true);
    if (this.UIisWorktypeChoosenAndEnabled(worktype)) {
      this.worktypesChosen.filter(worktypechoosen => {
        return worktypechoosen.workType.id === worktype.id;
      })[0].enabled = false;
    } else {
      if (this.isWorktypeChoosen(worktype)) {
        this.worktypesChosen.filter(worktypechoosen => {
          return worktypechoosen.workType.id === worktype.id;
        })[0].enabled = true;
      } else {
        this.worktypesChosen.push({
          owner: this.state.userId as any,
          workType: worktype,
          enabled: true,
        });
      }
    }
  }
}
