import {Injectable} from '@angular/core';
import {
  Agreement,
  Company,
  Establishment,
  EstablishmentType,
  Business,
  BusinessApi,
  EstablishmentApi,
  AgreementApi,
  ResourceApi,
  ContactApi
} from '@buscoextra/core';
import {Entities} from '@buscoextra/core';
import {Observable} from 'rxjs';
import {ResourceService, ResourceTags} from '../resource/resource.service';
import {ApiService} from '@buscoextra/core/services/api';
import {StateService} from '~/services';
import {map} from 'rxjs/operators';

@Injectable()
export class EstablishmentService {
  constructor(private api: ApiService, private resource: ResourceService, private state: StateService) {}

  getBusinessEstablishmentsByBusinessId(businessId: number): Observable<Establishment[]> {
    return this.api.apiRequestV2(Entities.Establishment, BusinessApi.getBusinessEstablishments(businessId));
  }
  getBusinessEstablishments(business: Business): Observable<Establishment[]> {
    return this.api.apiRequestV2(Entities.Establishment, BusinessApi.getBusinessEstablishments(business.id));
  }

  getAvailableEstablishmentTypes(): Observable<EstablishmentType[]> {
    return this.api.apiRequestV2(Entities.EstablishmentType, EstablishmentApi.getEstablishmentTypes());
  }

  getAvailableAgreements(): Observable<Agreement[]> {
    return this.api.apiRequestV2(Entities.Agreement, AgreementApi.getPublicAgreements(0, 100));
  }

  create(establishment: Establishment): Observable<Establishment> {
    return this.api.apiRequestV2(Entities.Establishment, EstablishmentApi.createEstablishment(establishment));
  }

  delete(establishmentId: number): Observable<any> {
    return this.api.apiRequestV2(
      Entities.Establishment,
      EstablishmentApi.updateEstablishment({id: establishmentId, softDelete: true} as any)
    );
  }

  getById(establishmentId: number): Observable<Establishment> {
    return this.api.apiRequestV2(Entities.Establishment, EstablishmentApi.getEstablishmentById(establishmentId));
  }

  update(establishment: Establishment): Observable<Establishment> {
    return this.api.apiRequestV2(Entities.Establishment, EstablishmentApi.updateEstablishment(establishment));
  }

  saveCCC(establishmentId: number, ccc: string): Observable<Establishment> {
    return this.api.apiRequestV2(Entities.Establishment, EstablishmentApi.updateEstablishment({id: establishmentId, CCC: ccc} as any));
  }

  addCompanyToEstablishment(establishmentId: number, company: Company): Observable<Establishment> {
    return this.api.apiRequestV2(
      Entities.Establishment,
      EstablishmentApi.updateEstablishment({id: establishmentId, company: company} as any)
    );
  }

  uploadEstablishmentImage(base64Img, userId, establishmentId, resourceId?: number) {
    const data = {
      tag: ResourceTags.ESTABLISHMENT_HEADER,
      file: base64Img,
      entity: 'Establishment',
      isPublic: true,
      owner: userId,
      entityId: establishmentId
    };

    if (resourceId !== undefined) {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.updateResource({id: resourceId, ...data} as any));
    } else {
      return this.api.apiRequestV2(Entities.Resource, ResourceApi.createResource(data as any));
    }
  }

  createContact(contact) {
    return this.api.apiRequestV2(Entities.Contact, ContactApi.createContact(contact));
  }
  updateContact(contact) {
    return this.api.apiRequestV2(Entities.Contact, ContactApi.updateContact(contact));
  }

  getEstablishmentContact(id?) {
    return this.api
      .apiRequestV2(
        Entities.Contact,
        ContactApi.getContactByEntityAndEntityId(
          'establishment',
          id ? id : this.state.currentEstablishment.id,
          0,
          24,
          '&type=extraEstablishmentContact'
        )
      )
      .pipe(
        map(res => {
          if (res && res.length > 0) return res[0];
          return {};
        })
      );
  }
  getEstablishmentCpdSignature(id?) {
    return this.api
      .apiRequestV2(
        Entities.Contact,
        ContactApi.getContactByEntityAndEntityId('establishment', id ? id : this.state.currentEstablishment.id, 0, 24, '&type=cpdSignatory')
      )
      .pipe(
        map(res => {
          if (res && res.length > 0) return res[0];
          return {};
        })
      );
  }
}
