import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {CameraMediaType, CameraService, CameraSourceType} from '~/services/camera/camera.service';
import {DeviceService} from '~/services/device/device.service';
import {img2base64} from '@buscoextra/core';
import {DialogService} from '~/services';
import {ModalsService} from '~/services/modals/modals.service';
import {IdCardPage} from '~/pages/home/shell-extra-page/pages/my-profile-page/pages/extra-work-profile-page/pages/work-types-requirements-page/pages/work-types-requirements-detail-page/pages/id-card-page/id-card.page';
const MAX_FILE_SIZE = 5000000;
@Component({
  selector: 'app-resource-picker',
  templateUrl: './resource-picker.component.html',
  styleUrls: ['./resource-picker.component.scss'],
})
export class ResourcePickerComponent implements OnInit {
  @Output('onChange') onResourcePickerChange: EventEmitter<string> = new EventEmitter();
  @Input() sourceType = CameraSourceType.GALERY;
  @Input() fileType = CameraMediaType.PHOTO;
  @Input() buttonTitle = '';
  @Input() fill = 'text';
  @Input() size = 'large';
  @Input() color = 'primary';
  @Input() acceptPdf = false;
  @Input('srcImage') srcImage = '';

  @ViewChild('filePicker', {static: true}) filePicker: ElementRef;

  showActionButtons: boolean = false;
  isCordova: boolean = false;
  resultImg;

  constructor(
    private camera: CameraService,
    private device: DeviceService,
    private dialogService: DialogService,
    private modal: ModalsService
  ) {}

  ngOnInit() {
    this.isCordova = this.device.isCordova();
    if (this.srcImage) this.resultImg = this.srcImage;
  }

  async pickFile() {
    this.showActionButtons = !this.showActionButtons;
  }

  async btnCamera() {
    this.showActionButtons = false;

    let result = await this.modal.launchPage(IdCardPage);
    if (result) {
      this.resultImg = result;
      this.onResourcePickerChange.emit(this.resultImg);
    }
  }

  btnFolder() {
    this.showActionButtons = false;
    this.pickWeb();
  }

  pickWeb() {
    this.filePicker.nativeElement.click();
  }

  async webFilePicked() {
    if (this.filePicker.nativeElement.files[0].size > MAX_FILE_SIZE) {
      this.dialogService.alert('Archivo no válido', 'El archivo es demasiado grande. No puede ser mayor de 5MB');
      return;
    }
    this.resultImg = await img2base64(this.filePicker.nativeElement.files[0]);
    this.onResourcePickerChange.emit(this.resultImg);
  }

  getFileType() {
    return `image/png, image/jpeg ${this.acceptPdf ? ',application/pdf' : ''}`;
  }

  pickNative() {
    switch (this.sourceType) {
      case CameraSourceType.GALERY:
        this.camera.pickFile(CameraSourceType.GALERY, CameraMediaType.ALL).subscribe(res => {
          this.resultImg = res;
          this.onResourcePickerChange.emit(res);
        });

        break;
      case CameraSourceType.CAMERA:
        this.camera.pickFile(CameraSourceType.CAMERA, CameraMediaType.PHOTO).subscribe(res => {
          this.resultImg = res;
          this.onResourcePickerChange.emit(res);
        });

        break;
      default:
        this.camera.pickFile(CameraSourceType.GALERY, CameraMediaType.ALL).subscribe(res => {
          this.resultImg = res;
          this.onResourcePickerChange.emit(res);
        });

        break;
    }
  }
  async removeImage() {
    let response = await this.dialogService.confirm(
      'Atención',
      'Se va a eliminar el documento. Si es un documento necesario para las contrataciones podrías perder el acceso a algunas ofertas.',
      '¿Deseas continuar?',
      'Eliminar',
      'Mantener'
    );
    if (response) {
      this.resultImg = null;
      this.onResourcePickerChange.emit(null);
    }
  }
}
