import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AnalyticsService} from '~/services/analytics/analytics.service';
import {StateService} from '~/services';
import {UserType} from '@buscoextra/core';

@Component({
  selector: 'location-modal-page',
  templateUrl: './location-modal.page.html',
  styleUrls: ['./location-modal.page.scss']
})
export class LocationModalPage {
  constructor(private modalCtl: ModalController, private analyticsService: AnalyticsService, private state: StateService) {}

  isExtra = false;
  tryOther = false;
  ionViewDidEnter() {
    this.isExtra = this.state.userType == UserType.Extra;
  }

  ionViewWillLeave() {
    if (!this.tryOther) {
    }
  }

  closeModal(tryOther?) {
    if (tryOther) {
      this.tryOther = true;
    }
    this.modalCtl.dismiss();
  }

  signOut() {
    this.modalCtl.dismiss('signOut');
  }
}
