import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Establishment} from '@buscoextra/core';
import {DialogService} from '~/services';

@Component({
  selector: 'be-establishment-card',
  templateUrl: './be-establishment-card.component.html',
  styleUrls: ['./be-establishment-card.component.css']
})
export class BeEstablishmentCardComponent implements OnInit {
  @Input() src: Establishment;
  @Input() editable = true;
  @Output('onDelete') deleteEtablishmentEmitter: EventEmitter<Establishment> = new EventEmitter();
  @Output('onEdit') editEtablishmentEmitter: EventEmitter<Establishment> = new EventEmitter();

  constructor(private dialog: DialogService) {}

  ngOnInit() {}

  async UIonEditBtnPressed(establishment: Establishment) {
    this.editEtablishmentEmitter.emit(establishment);
  }

  async UIonDeleteBtnPressed(establishment: Establishment) {
    if (await this.dialog.confirm(`¿Borrar establecimiento ${establishment.name}?`, 'Esta acción no puede deshacerse')) {
      this.deleteEtablishmentEmitter.emit(establishment);
    }
  }

  get establishmentImg() {
    if (this.src.images && this.src.images.length > 0) {
      return this.src.images[0].url;
    } else {
      return 'assets/site_img.png';
    }
  }
}
