import {Component, KeyValueDiffers, ViewChild} from '@angular/core';
import {DialogService, NavigationService, StateService} from '~/services';
import {ModalController} from '@ionic/angular';
import {Agreement, Company, Establishment, EstablishmentType, BeLocation, Resource} from '@buscoextra/core';
import {EstablishmentService} from '@services';
import {ActivatedRoute} from '@angular/router';
import {BusinessService} from '@buscoextra/services/user/business/business.service';
import {CompanyService} from '@buscoextra/services/company/company.service';
import {CommunicationsService, AsyncPage} from '@buscoextra/core/services';
import {LocationModalPage} from '~/pages/location-modal-page/location-modal.page';
import {LocationService} from '@buscoextra/services/location/location.service';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {validatorNif, validatorSS, validatorIban, validatorCCC} from '~/validators/validators.validator';
import {ResourceService, ResourceTags} from '@buscoextra/services/resource/resource.service';
import {ModalsService} from '~/services/modals/modals.service';
import {EstablishmentForServer} from '@buscoextra/core/services/api/mappers/establishment.mapper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'new-establishment-page',
  templateUrl: './new-establishment.page.html',
  styleUrls: ['./new-establishment.page.scss']
})
export class NewEstablishmentPage extends AsyncPage {
  isBusinessTheEstablishmentContact = true;
  // isPoliciesAccepted = false;
  agreements: Agreement[] = [];
  myCompanies: Company[] = [];
  establishmentTypes: EstablishmentType[] = [];
  public form: FormGroup;

  UIimageEstablishmentProfile: string;

  public shortForm: boolean;
  constructor(
    communications: CommunicationsService,
    private navigation: NavigationService,
    private state: StateService,
    private ionicModal: ModalController,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private businessService: BusinessService,
    private establishmentService: EstablishmentService,
    private dialog: DialogService,
    private modal: ModalsService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private translate: TranslateService
  ) {
    super(communications);
  }

  establishmentID;
  myEstablishment;
  myImg: Resource;
  imageChanged = false;
  urlImg = 'assets/theme/images/establishment-img.png';
  newEstablishment = true;
  tabs = {
    establishment: true,
    contact: false,
    docs: false
  };
  chargeTypes = [
    'Técnico de Selección',
    'Director de RRHH',
    'Director de Operaciones',
    'Gerente',
    'Director Financiero',
    'Director Ejecutivo',
    'Director Hotel',
    'Otro'
  ];

  showHeader = false;

  async ionViewWillEnter() {
    this.showHeader = this.navigation.isActualRouteIn('my-establishments/new');
    this.establishmentID = this.activatedRoute.snapshot.parent.parent.params['establishmentId'];
    if (this.establishmentID) {
      this.newEstablishment = false;
      this.myEstablishment = await this.send(this.establishmentService.getById(this.establishmentID), 'gettingEditableEstabishennt');
    }
    this.establishmentTypes = await this.send(this.establishmentService.getAvailableEstablishmentTypes(), 'gettinEstablishmentTypes');
    this.agreements = await this.send(this.establishmentService.getAvailableAgreements(), 'gettingAgreements');
    this.myCompanies = await this.send(this.companyService.getAllByBusinessId(this.state.userId), 'gettingMyCompanies');
    this.createForm(this.myEstablishment || ({} as Establishment));
    this.initImgEstablishment();
  }

  ngOnInit() {}
  private getDefaultImage(): string {
    // const randomIndex: number = Math.floor(Math.random() * 5) + 1;
    // return `assets/theme/images/establishment-default-${randomIndex}.jpg`;
    return 'assets/theme/images/establishment-default-1.jpg';
  }

  onGetEstablishmentImage(imageBase64: string) {
    // this.formValue.images = [{ url: imageBase64 }];
    // this.UIimageEstablishmentProfile = this.getImageProfile();
  }

  async UIconCreateEstablishment(establishment: Establishment) {
    if (!(await this.locationIsContained(establishment.location.latitude, establishment.location.longitude))) {
      this.showModal();
    } else {
      this.myEstablishment ? this.ACTIONeditEstablishment(establishment) : this.ACTIONcreateEstablishment();
    }
  }

  async UIonDeleteEstablishment(establishment: Establishment) {
    if (await this.dialog.confirm('¿Estás seguro?', undefined, '¿Seguro que deseas borrar este establecimiento?')) {
      const deleteResult = await this.send(this.establishmentService.delete(this.myEstablishment.id), 'deletingEstablishment');
      if (deleteResult !== null) {
        this.dialog.toast(`Establecimiento ${establishment.name} borrado`);
        return this.leavePage(establishment);
      } else if (this.errors['deletingEstablishment'].status == 418) {
        this.dialog.toast(`No se puede borrar el establecimeinto ${establishment.name}, dado que tiene ofertas asociadas`);
      }
    }
  }

  locationIsContained(lat: number, lng: number): any {
    return this.send(this.locationService.getLocation(lat, lng));
  }

  async showModal() {
    await this.modal.launchPage(LocationModalPage, {}, 'geolocation-modal');
  }

  async UIonCloseModalBtnPressed() {
    if (!this.form.pristine) {
      if (await this.dialog.confirm('¿Descartar edición?', 'Perderás los cambios')) {
        this.ionicModal.dismiss();
      }
    } else {
      this.ionicModal.dismiss();
    }
  }

  onChangeLocation(location: BeLocation) {
    this.form.controls.location.setValue(location);
  }

  get currentUserName(): string {
    if (!this.state.user) return '';
    return this.state.user.name;
  }

  private async ACTIONcreateEstablishment() {
    const newEstablishment = await this.send(
      this.establishmentService.create(EstablishmentForServer(this.form.value)),
      'creatingEstablishment'
    );

    this.myEstablishment = newEstablishment;
    this.uploadImg();
    if (!this.state.hasCurrentEstablishment) this.state.currentEstablishment = newEstablishment;
    this.dialog.toast('Has creado el establecimiento ');

    this.leavePage(newEstablishment);
  }

  private async ACTIONeditEstablishment(establishment: Establishment) {
    const newEstablishment = await this.send(
      this.establishmentService.update({id: this.establishmentID, ...EstablishmentForServer(this.form.value)}),
      'editingEstablishment'
    );

    if (newEstablishment != null) {
      this.myEstablishment = newEstablishment;
      this.uploadImg();
      this.dialog.toast('Has actualizado el establecimiento');
      //  return this.leavePage(newEstablishment);
    }
  }

  private leavePage(establishment: Establishment) {
    this.navigation.goback();
  }

  UIonCreateNewCompany() {
    this.navigation.goToSkipLocation('business/my-profile/my-companies/new');
  }

  setSelected(ev, arr, formKey, key?) {
    if (key) this.form.controls[formKey].setValue(arr[ev.detail][key]);
    else this.form.controls[formKey].setValue(arr[ev.detail]);

    this.unPristineForm();
  }

  unPristineForm() {
    if (!this.form) return;
    this.form.markAsDirty();
  }

  createForm(establishment: Establishment) {
    this.form = this.formBuilder.group({
      name: new FormControl(establishment.name, {
        validators: [Validators.required, Validators.minLength(1)]
      }),
      location: new FormControl(establishment.location, {
        validators: [Validators.required, Validators.minLength(1)]
      }),
      establishmentType: new FormControl(establishment.establishmentType && establishment.establishmentType.id, {
        validators: []
      }),
      CCC: new FormControl(establishment.CCC, {
        validators: [, validatorCCC]
      }),
      agreement: new FormControl(establishment.agreement && establishment.agreement.id, {
        validators: []
      }),
      company: new FormControl(establishment.company && establishment.company.id, {
        validators: []
      })
    });
  }

  async initImgEstablishment() {
    if (this.myEstablishment && this.myEstablishment.id) {
      const imageArray: Resource[] = await this.send(
        this.resourceService.getEstablishmentResourcesUrlByTag(
          this.state.userId,
          this.myEstablishment.id,
          ResourceTags.ESTABLISHMENT_HEADER
        )
      );

      if (imageArray.length > 0) {
        this.myImg = imageArray[0];
        this.urlImg = this.myImg.url;
      }
    }
  }

  async onImgResource(file) {
    this.urlImg = file;
    this.imageChanged = true;
  }

  async uploadImg() {
    if (!this.imageChanged) return;

    let img = await this.send(
      this.resourceService.uploadEstablishmentHeaderImage(
        this.urlImg,
        this.state.userId,
        this.myEstablishment.id,
        this.myImg && this.myImg.id
      ),
      'uploadingImg'
    );

    if (!img) return;
    this.urlImg = img.url;
    this.myImg = img;
  }

  companyChange(eve) {
    if (eve.detail == -1) {
      this.form.controls.company.setValue(undefined);
      this.createCompany();
    }
  }

  createCompany() {
    this.navigation.jumpTo('business/my-profile/my-companies/new');
  }

  getTranslation(key: string){
    return this.translate.instant(key);
  }
}
