import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRatingPage} from './app-rating.page';
import {AppRatingRoutingModule} from './app-rating.routing';
import {IonicModule} from '@ionic/angular';
import {ContactUsComponentModule} from '~/components/contact-us/contact-us-component.module';
import {UserServiceModule} from '@services';
import {BeLoadingComponentModule} from '@components';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, UserServiceModule, BeLoadingComponentModule, ContactUsComponentModule, AppRatingRoutingModule],
  declarations: [AppRatingPage]
})
export class AppRatingPageModule {}
