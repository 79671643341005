import {NgModule} from '@angular/core';
import {NavigationService} from './navigation.service';
import {GuardsServiceModule} from './guards/guards-service.module';
import {IonicModule} from '@ionic/angular';

@NgModule({
  providers: [NavigationService],
  imports: [IonicModule, GuardsServiceModule]
})
export class NavigationServiceModule {}
