import {NgModule} from '@angular/core';
import {CameraService} from './camera.service';
import {CameraPreview} from '@ionic-native/camera-preview/ngx';
import {Camera} from '@ionic-native/camera/ngx';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [Camera, CameraService, CameraPreview]
})
export class CameraServiceModule {}
