import {Component, Input} from '@angular/core';
import {Schedule} from '@buscoextra/core';

@Component({
  selector: 'be-schedule-card',
  templateUrl: './be-schedule-card.component.html',
  styleUrls: ['./be-schedule-card.component.scss']
})
export class BeScheduleCardComponent {
  @Input() src: Schedule;
  @Input() extend: boolean = false;
  @Input() horizontal: boolean = false;
  public daysWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  public daysWeekExtended = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  public months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];

  isTimesShowed = false;

  constructor() {}

  ngOnInit() {
    if (this.extend) this.isTimesShowed = true;
  }

  getNextDay(date) {
    let newDate = new Date();
    newDate.setDate(date.getDate() + 1);
    return newDate.getDate() + ' ' + this.months[newDate.getMonth()].substring(0, 3);
  }

  getDate() {
    return new Date(this.src.date);
  }

  UIonScheduleClick(event: any) {
    event.stopPropagation();
    if (this.extend) return;
    this.isTimesShowed = !this.isTimesShowed;
    event.target.parentNode.style.zIndex = 14;
  }

  UIonBlurSchedule(event: any) {
    event.stopPropagation();
    if (this.extend) return;
    this.isTimesShowed = false;
    event.target.parentNode.style.zIndex = 10;
  }

  UIonEnterSchedule(event: any) {
    event.stopPropagation();
    if (this.extend) return;
    event.target.parentNode.style.zIndex = 12;
  }
}
