import {Component, Input, OnInit} from '@angular/core';
import {ShortTermTiming} from '@buscoextra/core';
import {LongTermTiming} from '@buscoextra/core';
import {UnlimitedTiming} from '@buscoextra/core';
import {TimingFormatType} from '@buscoextra/core';

@Component({
  selector: 'timing-card',
  templateUrl: './timing-card.component.html',
  styleUrls: ['./timing-card.component.scss']
})
export class TimingCardComponent {
  @Input() timing: ShortTermTiming;
  @Input() timingType: TimingFormatType;
  @Input() horizontal: boolean = false;

  constructor() {
    //TIMING_CARD.exampleValue
  }

  ngOnInit() {}

  getSchedule() {
    return (this.timing as ShortTermTiming).schedule;
  }
}
